import React, {useEffect, useState} from 'react'
import Header from 'components/Headers/Header';
import {Row, Col, Container, Spinner, Button, Card, CardBody, CardHeader, Table} from 'reactstrap'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {Link} from 'react-router-dom'
import axios from 'axios'

function Operator() {

    const [loading, setLoading] = useState(true);
    const [farmernameoptions, setfarmernameoptions] = useState([]);
    const [operatorsoptions, setOperatorsoptions] = useState([]);

    const token = useSelector((state) => state.userLogin.userInfo.token);

    ////////////
    const { t, i18n } = useTranslation(); 

    useEffect(() => {
        const storedLanguage = localStorage.getItem('selectedLanguageAFS');
        if (storedLanguage) {
        i18n.changeLanguage(storedLanguage); 
        }
    }, [i18n]);
    ////////////

    useEffect(() => {

        const fetchData = async () => {
  
          try {
    
            const config = {
              headers: {
                  'Content-type': 'application/json',
                  Authorization: `Bearer ${token}`
              }
            }
  
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/farmavailable/`, config);
            
            const extractedNames = response.data.map(item => item.NameFarmer);
            setfarmernameoptions(extractedNames);         
            
            setLoading(false)
  
          } catch (error) {
            console.error('Errore durante la richiesta GET:', error);
          }
          
        };
    
        fetchData();
    }, [token]);

    useEffect(() => {

        const fetchData = async () => {
  
          try {
    
            const config = {
              headers: {
                  'Content-type': 'application/json',
                  Authorization: `Bearer ${token}`
              }
            }
  
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/company-management/operator/`, config);
    
            setOperatorsoptions(response.data);         
  
          } catch (error) {
            console.error('Errore durante la richiesta GET:', error);
          }
          
        };
    
        fetchData();
    }, [token]);

    return (
        <div>
            <Header/>
            {loading ? (
                <>
                    <Container className="mt--15" fluid>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                                <Spinner>
                                    {t('Loading')}
                                </Spinner>
                            </Col>
                        </Row>
                    </Container>
                </>
            ):(
                <>
                    <Container className="mt--15" fluid>
                        <Row>
                            {farmernameoptions && farmernameoptions.length >0 ? (
                                <>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-right mt-5">
                                        <Link to="/create-operator">
                                            <Button color="success">
                                                {t('Create Operator')} <i className='fa fa-plus'></i>
                                            </Button>
                                        </Link>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                                        <Card className="shadow">
                                            <CardHeader className="border-0">
                                                <h3 className="mb-0">{t('Operators Registred')}</h3>
                                            </CardHeader>
                                            <CardBody>
                                                {operatorsoptions && operatorsoptions.length>0 ? (
                                                    <>
                                                        <Table
                                                            className="align-items-center table-flush"
                                                            responsive
                                                            >
                                                            <thead>
                                                            <tr>
                                                                <th>{t('Farm')}</th>
                                                                <th>{t('Name')}</th>
                                                                <th>{t('Last Name')}</th>
                                                                <th>{t('Email')}</th>
                                                                <th>{t('Phone')}</th>  
                                                                <th>{t('Edit')}</th>
                                                                <th>{t('Delete')}</th>                  
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {operatorsoptions.map((farmer, index) => (
                                                                <tr key={index}>
                                                                    <td>{farmer.FarmerName}</td>
                                                                    <td>{farmer.Name}</td>
                                                                    <td>{farmer.Cognome}</td>
                                                                    <td>{farmer.Email}</td>
                                                                    <td>{farmer.Phone}</td> 
                                                                <td>
                                                                    <Link to={`/update-operator/${farmer.id}`}>
                                                                        <Button color="warning">{t('Edit')} <i className="fas fa-edit"></i></Button>
                                                                    </Link>
                                                                </td>
                                                                <td>
                                                                    <Link to={`/delete-operator/${farmer.id}`}>
                                                                        <Button color="danger">
                                                                            {t('Delete')} <i className="fas fa-trash"></i>
                                                                        </Button>
                                                                    </Link>
                                                                </td>
                                                                </tr>
                                                            ))}
                                                            </tbody>
                                                        </Table>
                                                    </>
                                                ):(
                                                    <>
                                                        <h3 className="mb-0">{t('No Operators are registred')}</h3>
                                                    </>
                                                )}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </>
                            ):(
                                <>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                                        <h3>{t('No Farmer exist for your account')}</h3>
                                        <h3>{t('If you want to register an operator you should create first a farm')}</h3>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">                                                
                                        <Link to="/create-farm">
                                            <Button color="success">
                                                {t('Create Farm')}
                                            </Button>
                                        </Link>
                                    </Col>                        
                                </>
                            )}
                        </Row>
                    </Container>
                </>
            )}
        </div>
    )
}

export default Operator
