import React from 'react'
import Header from 'components/Headers/Header';

import MutiStepForm from './MutiStepForm'


function CropModel() {
  return (
    <div>
      <Header/>
      <br></br>
      <MutiStepForm/>
    </div>
  )
}

export default CropModel
