import React, { useEffect, useState } from 'react';
import {Form} from 'react-bootstrap'
import { Container, Row, Col, Card, CardHeader, CardFooter, Button, Spinner, CardBody, Alert } from 'reactstrap';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Header from 'components/Headers/Header';

export default function NutrientBalance() {

    const [cropoption, setcropoption] = useState([])
    
    const [nutrient, setnutrient] = useState('')

    const [cropselected, setcropselected] = useState('')
    const [yeild, setyield] = useState('')
    const [fertilizer, setfertilizer] = useState('')

    const [error, seterror] = useState('')
    const [message, setmessage] = useState('')
    const [loading, setloading] = useState(true)

    const [results, setresults] = useState('')
    
    const token = useSelector((state) => state.userLogin.userInfo.token);

    ////////////
    const { t, i18n } = useTranslation();  // Destructure i18n from useTranslation

    useEffect(() => {
        const storedLanguage = localStorage.getItem('selectedLanguageAFS');
        if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);  // Use i18n to change language
        }
    }, [i18n]);
    ////////////

    const functionchange = async (e) => {

        const selectedNutrient = e.target.value;
        setnutrient(selectedNutrient)
    
        try {
          const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`
            }
          }
      
          const cropavailable = await axios.get(`${process.env.REACT_APP_BACKEND_API}/nutrient-balance/availablecrop?nutrient=${selectedNutrient}`, config);
          
          setcropoption(cropavailable.data.crop)

        } catch(error) {
          console.log(error)
        }
    }

    const Submit = async () => {

        setloading(false);

        if (yeild==='') {
            setmessage(t('Please the expected yield (q/ha)'))
            seterror(true)
            setTimeout(function() {
                seterror(false)
            }, 5000);
            setloading(true);
            return;
        } 

        if (fertilizer==='') {
        setmessage(t('Please the fertilizer title (%)'))
        seterror(true)
        setTimeout(function() {
            seterror(false)
        }, 5000);
        setloading(true);
        return;
        }        
            
          
        try {

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
    
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/nutrient-balance?nutrient=${nutrient}&crop=${cropselected}&expectedyield=${yeild}&titlefertilizer=${fertilizer}`, config);
        
        setresults(response.data)

        } catch(error) {
        console.log(error)
        } finally {
        setloading(true);
        }

          
    };

    return (
        <div>
        <Header/>
        <Container>
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                    <Card>
                        <CardHeader>
                            <h3>{t('Setting of the Calculation')}</h3>
                        </CardHeader>
                        <CardBody>
                            <Form>
                                <Form.Label>{t('Select the Nutrient')}</Form.Label>
                                <Form.Control 
                                    as="select" 
                                    onChange={functionchange}
                                    value={nutrient}>
                                    <option value={''}>

                                    </option>
                                    <option value={'nitrogen'}>
                                        {t('Nitrogen')}
                                    </option>
                                    <option value={'phosphorus'}>
                                        {t('Phosphorus')}
                                    </option>
                                    <option value={'potassium'}>
                                        {t('Potassium')}
                                    </option>
                                </Form.Control>
                                <br></br>
                                {cropoption &&  cropoption.crop !== '' ? (
                                    <>
                                        <Form.Label>{t('Select the Crop')}</Form.Label>
                                        <Form.Control 
                                            as="select" 
                                            onChange={(e) => setcropselected(e.target.value)}
                                            value={cropselected}>
                                            {cropoption.map((option, index) => (
                                                <option key={index} value={option}>
                                                    {t(`translation:${option}`)}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </>
                                ):(
                                    <>
                                    </>
                                )}
                                <br></br>
                                <Form.Label>{t('Set the expected yield (q/ha)')}</Form.Label>
                                <Form.Control 
                                    type="numeric" 
                                    onChange={(e) => setyield(e.target.value)}
                                    value={yeild}
                                    min={0.001}
                                    max={1000000}/>
                                <br></br>
                                <Form.Label>{t('Title of fertilizer - range 0-100 %')}</Form.Label>
                                <Form.Control 
                                    type="numeric" 
                                    onChange={(e) => setfertilizer(e.target.value)}
                                    value={fertilizer}
                                    min={1}
                                    max={100}/>
                            </Form>
                            <br></br>
                            {error && <Alert color="danger" fade={false}>{message}</Alert>}
                            {results === '' ? (
                                <>
                                </>
                            ) : (
                                <>  
                                    <hr></hr>
                                    <h5>{t('The amount of fertilizer that you have provide to the crop for the entire crop growth cycle is:')}</h5>
                                    <h3>{results.data} kg/ha</h3> <i class="fa-solid fa-sack-xmark"></i>
                                </>
                            )}
                        </CardBody>
                        <CardFooter>
                            {loading ? (
                                <>
                                    <Button color="success" onClick={Submit}> {t('Calculate')}</Button>
                                </>
                            ):(
                                <>
                                    <Button color="success" disabled>
                                        <Spinner size="sm">
                                            Loading...
                                        </Spinner>
                                        <span>
                                        {' '}Loading
                                        </span>
                                    </Button>
                                </>
                            )}          
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        </Container>
        </div>
    )
}
