import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { Button, Card, CardHeader, CardBody, Spinner } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux'; 

const CheckoutForm = ({ hectares }) => {
    const [loading, setLoading] = useState(false);
    const { t, i18n } = useTranslation();
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();

    const tokenuser = useSelector((state) => state.userLogin.userInfo.token);

    const handleSubmit = async (event) => {

        event.preventDefault();

        setLoading(true);

        if (!stripe || !elements) return;

        const cardElement = elements.getElement(CardElement);

        const { token, error } = await stripe.createToken(cardElement);

        if (error) {
            
            console.error('Stripe error:', error);
            setLoading(false);

        } else {
            
            try {

                const payload = {
                    amount: 83200,
                    currency: 'eur',
                    hectares: hectares
                };

                console.log(payload)
                
                const config = {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${tokenuser}`
                    }
                };

                console.log(config)

                const response = await axios.post(`${process.env.REACT_APP_BACKEND_API}/payment/`, payload, config);

                navigate("/payment-success/");

            } catch (error) {

                console.error('Payment error:', error);
                setLoading(false);

            }
        }
    };

    return (
        <>  
            <Card>
                <CardHeader>
                    <h3>{t('Upgrade your account to PRO')}</h3>
                </CardHeader>
                <CardBody>
                    <CardElement />
                    <br></br>
                    <Button color='success' onClick={handleSubmit} disabled={!stripe || loading}>
                        {loading ? (
                            <>
                                <Spinner size="sm" /> {t('Loading')}
                            </>
                        ) : (
                            t('Upgrade your account to PRO')
                        )}
                    </Button>
                </CardBody>
            </Card>
        </>
    );
};

export default CheckoutForm;
