import React, {useEffect, useState} from 'react'
import Header from 'components/Headers/Header';
import {Row, Col, Container, Spinner, Button, Card, CardBody, CardHeader, Alert, CardFooter} from 'reactstrap'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams, useNavigate, Link  } from 'react-router-dom';
import axios from 'axios'
import {Form} from 'react-bootstrap'

function UpdateSeed() {

  const [loading, setLoading] = useState(true)
  const [loadingsubmit, setLoadingsubmit] = useState(true)
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const [name, setName] = useState('')
  const [type, setType] = useState('')
  const [organic, setOrganic] = useState(false)

  const [daycycle, setDaycycle] = useState('')
  const [faoclass, setFaoclass] = useState('')

  const [productor, setProductor] = useState('')

  const [crop, setCrop] = useState('')
  const [description, setDescription] = useState('')

  const { fieldId } = useParams()
  const navigate = useNavigate()

  const [unitdose, setUnitdose] = useState('')

  const token = useSelector((state) => state.userLogin.userInfo.token);

  ////////////
  const { t, i18n } = useTranslation(); 

  useEffect(() => {
      const storedLanguage = localStorage.getItem('selectedLanguageAFS');
      if (storedLanguage) {
      i18n.changeLanguage(storedLanguage); 
      }
  }, [i18n]);

  useEffect(() => {

    const fetchData = async () => {

      try {

        const config = {
          headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${token}`
          }
        }

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/company-management/seed/${fieldId}`, config);
        
        setName(response.data.Name)
        setType(response.data.Type)
        setOrganic(response.data.OrganicApproved)
        setDaycycle(response.data.GiorniCicloVegetativo)
        setFaoclass(response.data.ClasFAO)
        setUnitdose(response.data.UnitDose)
        setProductor(response.data.Productor)
        setCrop(response.data.Crop)
        setDescription(response.data.Description)
        
      } catch (error) {
        console.error('Errore durante la richiesta GET:', error);
      } finally {
        setLoading(false)
      }
      
    };

    fetchData();
  }, [token, fieldId]);

  const submit = async () => {

    setLoadingsubmit(false);
       
    if (unitdose==='') {
      setMessage(t('Please select the unit doses'))
      setError(true)
      setTimeout(function() {
        setError(false)
      }, 5000);
      setLoadingsubmit(true);
      return;
    }

    if (name==='') {
      setMessage(t('Please set the name of the seed'))
      setError(true)
      setTimeout(function() {
        setError(false)
      }, 5000);
      setLoadingsubmit(true);
      return;
    }

    if (type==='') {
      setMessage(t('Please select the type of the seed'))
      setError(true)
      setTimeout(function() {
        setError(false)
      }, 5000);
      setLoadingsubmit(true);
      return;
    }
    
    if (productor==='') {
      setMessage(t('Please set the productor name'))
      setError(true)
      setTimeout(function() {
          setError(false)
      }, 5000);
      setLoadingsubmit(true);
      return;
    }
    
    if (crop==='') {
      setMessage(t('Please set the crop name'))
      setError(true)
      setTimeout(function() {
          setError(false)
      }, 5000);
      setLoadingsubmit(true);
      return;
    }

    try {

        const payload = {
          Name: name,
          MacroType: "Semente",
          Type: type,
          OrganicApproved: organic,
          GiorniCicloVegetativo: daycycle,
          ClasFAO: faoclass,
          Productor: productor,
          Crop: crop,
          Description: description,
          UnitDose: unitdose
        };
    
        const config = {
          headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${token}`
          }
        }   
            
        axios.put(`${process.env.REACT_APP_BACKEND_API}/company-management/update-seed/${fieldId}/`, payload, config)

    } catch (error) {
      console.log(error)
      setMessage(t('An error occured while creating the field. If the problem persiste please send us an email automaticfarmsolution@gmail.com'))
      setError(true)
      setTimeout(function() {
          setError(false)
      }, 5000);
      setLoadingsubmit(true);
      return;

    } finally {
      setLoadingsubmit(true);
      navigate('/seed/');
    }
    
    };

  return (
    <div>
      <Header/>
      {loading ? (
        <>
            <Container className="mt--15" fluid>
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                <Spinner>
                    {t('Loading')}
                </Spinner>
                </Col>
            </Row>
            </Container>
        </>
      ):( 
        <>
          <Container className="mt--15" fluid>
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                    <Card>
                        <CardHeader>
                            <h3>{t('Edit Seed')}</h3>
                        </CardHeader>
                            <CardBody>
                                <Form.Label>{t('Commercial Product Name:')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t('Commercial Product Name:')}
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}>
                                </Form.Control>
                                <br></br>
                                <Form.Label>{t('Product Type:')}</Form.Label>
                                <Form.Control
                                    as="select"
                                    placeholder={t('Product Type:')}
                                    value={type}
                                    onChange={(e) => setType(e.target.value)}>
                                    <option value={""}>

                                    </option>
                                    <option value={"semente"}>
                                        {t('Seed')}
                                    </option>
                                    <option value={"piantine"}>
                                        {t('seedlings')}
                                    </option>
                                </Form.Control>
                                <br></br>
                                <Form.Label>{t('Unit Dose:')}</Form.Label>
                                <Form.Control
                                    as="select"
                                    placeholder={t('Unit Dose:')}
                                    value={unitdose}
                                    onChange={(e) => setUnitdose(e.target.value)}>
                                    <option value={""}>

                                    </option>
                                    <option value={"kg_ha"}>
                                        {t('Kg / ha')}
                                    </option>
                                    <option value={"libre_acro"}>
                                        {t('Pounds per acre')}
                                    </option>
                                </Form.Control>
                                <br></br>
                                <Form.Label>{t('Can be used in organic farming production?')}</Form.Label>
                                <Form.Check
                                    type={"checkbox"}
                                    value={organic}
                                    onClick={(e) => {
                                        setOrganic(e.target.checked);
                                    }}/>
                                <br></br>
                                <Form.Label>{t('Day Growth Cycle')}</Form.Label>
                                <Form.Control
                                    type="numeric"
                                    placeholder={t('Day Growth Cycle')}
                                    value={daycycle}
                                    onChange={(e) => setDaycycle(e.target.value)}>
                                </Form.Control>  
                                <br></br>
                                <Form.Label>{t('FAO Class')}</Form.Label>
                                <Form.Control
                                    type="numeric"
                                    placeholder={t('FAO Class')}
                                    value={faoclass}
                                    onChange={(e) => setFaoclass(e.target.value)}>
                                </Form.Control>
                                <br></br>
                                <Form.Label>{t('Productor')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t('Productor')}
                                    value={productor}
                                    onChange={(e) => setProductor(e.target.value)}>
                                </Form.Control>                                
                                <br></br>
                                <Form.Label>{t('Crop')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t('Crop')}
                                    value={crop}
                                    onChange={(e) => setCrop(e.target.value)}>
                                </Form.Control>
                                <br></br>
                                <Form.Label>{t('Description')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t('Description')}
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}>
                                </Form.Control>
                                {error &&
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                                        <Alert color="danger" fade={false}>{message}</Alert>
                                    </Col>
                                }
                            </CardBody>
                        <CardFooter>
                          <Row>
                            {loadingsubmit ? (
                                <>
                                    <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-2">
                                    <Button color="success" onClick={submit}>
                                        {t('Edit Seed')} <i className="fas fa-plus"></i>
                                    </Button>
                                    </Col>
                                </>
                            ) : (
                                <>
                                    <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-2">
                                    <Button color="success" disabled>
                                        <Spinner size="sm">
                                        {t('Editing the Seed...')}
                                        </Spinner>
                                        <span>
                                        {' '}{t('Editing the Seed...')}
                                        </span>
                                    </Button>
                                    </Col>
                                </>
                            )}
                            <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-2">
                                <Link to="/seed/">
                                    <Button color="dark">
                                        {t('Go back')} <i className="fas fa-rotate-left"></i>
                                    </Button>
                                </Link>
                            </Col>
                          </Row>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        </Container>
        </>
      )}
    </div>
  )
}

export default UpdateSeed
