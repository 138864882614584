import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link  } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import Header from 'components/Headers/Header';
import { Form, Col, Row } from 'react-bootstrap';
import {Container, Alert, Button, Spinner, Card, CardBody, CardHeader, CardFooter} from 'reactstrap'
import axios from 'axios'

function UpdateRevenue() {

  const [loading, setLoading] = useState(true)
  const [loadingsubmit, setLoadingsubmit] = useState(true)
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const [type, setType]= useState('');

  const [farm, setFarm] = useState('')
  const [farmoptions, setFarmoptions] = useState([])

  const [totalrevenue, setTotalrevenue]=useState('')
  
  const [date, setDate]=useState('')

  const { fieldId } = useParams()
  const navigate = useNavigate()

  const token = useSelector((state) => state.userLogin.userInfo.token);

    // i18n
  const { t, i18n } = useTranslation(); 

    useEffect(() => {

    const fetchData = async () => {

      try {

        const config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        };

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/company-management/other-revenue/${fieldId}/`, config);
        
        setType(response.data.Type)
        setFarm(response.data.FarmerName)
        setTotalrevenue(response.data.TotalRevenue)
        setDate(response.data.Date)
        
      } catch (error) {

        console.error('Error during GET request:', error);

      } 
    };

    fetchData();
  }, [token]);

  useEffect(() => {

    const fetchData = async () => {

      try {

        const config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        };

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/farmavailable/`, config);

        const extractedNames = response.data.map(item => item.NameFarmer);

        setFarmoptions(extractedNames);
        
      } catch (error) {

        console.error('Error during GET request:', error);

      } finally {
        setLoading(false)
      }
    };

    fetchData();
  }, [token]);

  const getFarmerId = async (farm) => {
    try {
        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`
            }
          }   
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/farm/${farm}`, config);
        return response.data;
    } catch (error) {
        console.error("Error fetching farmer ID:", error);
        throw error;
    }
  };

  useEffect(() => {
      const storedLanguage = localStorage.getItem('selectedLanguageAFS');
      if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
      }
  }, [i18n]);

  const submit = async () => {

    setLoadingsubmit(false);
       
    if (farm==='') {
      setMessage(t('Please select the farm'));
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 5000);
      setLoadingsubmit(true);
      return;
    }

    if (totalrevenue==='') {
      setMessage(t('Please set the total revenue'));
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 5000);
      setLoadingsubmit(true);
      return;
    }
    
    if (type==='') {
      setMessage(t('Please set the type of the revenue'));
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 5000);
      setLoadingsubmit(true);
      return;
    }

    if (date==='') {
      setMessage(t('Please insert the date of the revenue'));
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 5000);
      setLoadingsubmit(true);
      return;
    }
    
    try {
        
        const farmerId = await getFarmerId(farm)    
      
        const payload = {
          Type: type,
          Farm: farmerId,
          TotalRevenue: totalrevenue,
          Date:date
        };
    
        const config = {
          headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${token}`
          }
        }   
            
        const response= await axios.put(`${process.env.REACT_APP_BACKEND_API}/company-management/update-other-revenue/${fieldId}/`, payload, config)

        console.log(response)

    } catch (error) {
      console.log(error)
      setMessage(t('An error occured while creating the field. If the problem persiste please send us an email automaticfarmsolution@gmail.com'))
      setError(true)
      setTimeout(function() {
          setError(false)
      }, 5000);
      setLoadingsubmit(true);
      return;

    } finally {
      setLoadingsubmit(true);
      navigate('/revenue/');
    }
    
  };

  return (
    <div>
        <Header/>
        {loading ? (
          <>
            <Container className="mt--15" fluid>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                        <Spinner>
                            {t('Loading')}
                        </Spinner>
                    </Col>
                </Row>
            </Container>
          </>
        ):(
          <Container className="mt--15" fluid>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                <Card>
                  <CardHeader>
                    <h3>{t('Edit a Revenue')}</h3>
                  </CardHeader>
                  <CardBody>
                    <Form.Label>{t('Select the type of revenue:')}</Form.Label>
                    <Form.Control
                      as="select"
                      placeholder={t('Select the type of revenue:')}
                      value={type}
                      onChange={(e) => setType(e.target.value)}>
                      <option value={""}></option>
                      <option value={"pac"}>{t('Direct Payments')}</option>
                      <option value={"indinizzi"}>{t('Agricultural compensation')}</option>
                    </Form.Control>
                    <br></br>
                    <Form.Label>{t('Farmer Company Name:')}</Form.Label>
                    <Form.Control
                      as="select"
                      placeholder={t('Farmer Company Name:')}
                      value={farm}
                      onChange={(e) => setFarm(e.target.value)}>
                      <option value={""}></option>
                      {farmoptions.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </Form.Control>
                    <br></br>
                    <Form.Label>{t('Total Revenue')}</Form.Label>
                    <Form.Control
                      type="numeric"
                      placeholder={t('Total Revenue')}
                      value={totalrevenue}
                      onChange={(e) => setTotalrevenue(e.target.value)}>
                    </Form.Control>
                    <br></br>
                    <Form.Label>{t('Date')}</Form.Label>
                    <Form.Control
                        type="date"
                        placeholder={t('Date')}
                        value={date}
                        onChange={(e) => setDate(e.target.value)}>
                    </Form.Control>
                    {error &&
                      <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                          <Alert color="danger" fade={false}>{message}</Alert>
                      </Col>
                    }
                  </CardBody>
                  <CardFooter>
                    <Row>
                      {loadingsubmit ? (
                        <>
                          <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-2">
                            <Button color="success" onClick={submit}>
                              {t('Edit Revenue')} <i className="fas fa-plus"></i>
                            </Button>
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-2">
                            <Button color="success" disabled>
                              <Spinner size="sm">
                                {t('Editing the Revenue...')}
                              </Spinner>
                              <span>
                                {' '}{t('Editing the Revenue...')}
                              </span>
                            </Button>
                          </Col>
                        </>
                      )}
                      <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-2">
                          <Link to="/revenue/">
                              <Button color="dark">
                                  {t('Go back')} <i className="fas fa-rotate-left"></i>
                              </Button>
                          </Link>
                      </Col>
                    </Row>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
    </div>
  )
}

export default UpdateRevenue
