import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate, Link  } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import Header from 'components/Headers/Header';
import { Form, Col, Row } from 'react-bootstrap';
import {Container, Alert, Button, Spinner, Card, CardBody, CardHeader, CardFooter} from 'reactstrap'
import { MapContainer, TileLayer, LayersControl, GeoJSON, FeatureGroup, Marker, Popup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import { setDefaults, fromAddress } from "react-geocode";
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerIconShadow from 'leaflet/dist/images/marker-shadow.png';
import L from 'leaflet';
import axios from 'axios'
import 'leaflet/dist/leaflet.css';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png',
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png',
});

function UpdateWarehouse() {
  
  const [isLoading, setIsLoading] = useState(true)
  const [loadingsubmit, setLoadingsubmitting] = useState(true)
  const [name, setName] = useState('');
  const [farmer, setFarmer] = useState('');
  const [farmeroptions, setFarmeroptions] = useState([]);
  const [lat, setLat] = useState('')
  const [long, setLong] = useState('')
  const [mapCenter, setMapCenter] = useState([45.1657, 10.4515]);
  const [mapZoom, setMapZoom] = useState(17);
  const [markerPosition, setMarkerPosition] = useState(null);
  const [city, setCity] = useState('');
  const [mapLayers, setMapLayers] = useState([]);
  const [isDrawing, setIsDrawing] = useState(true);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const { fieldId } = useParams()
  const navigate = useNavigate()

  const token = useSelector((state) => state.userLogin.userInfo.token);

  // i18n
  const { t, i18n } = useTranslation(); 

  useEffect(() => {
      const storedLanguage = localStorage.getItem('selectedLanguageAFS');
      if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
      }
  }, [i18n]);

  const mapRef = useRef(null);

  setDefaults({
      key: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
      language: "en",
      region: "es",
  });

  useEffect(() => {
      const fetchData = async () => {
          try {
              const response = await fromAddress(city);
              const { lat, lng } = response.results[0].geometry.location;

              setMarkerPosition([lat, lng]);
              setMapCenter([lat, lng]);
              setMapZoom(15);

              mapRef.current.flyTo([lat, lng], 15, {
                  duration: 5
              });
          } catch (error) {
              console.error('Error during geocoding request:', error);
          }
      };

      fetchData();
  }, [city]);

  const customMarkerIcon = new L.Icon({
      iconUrl: markerIcon,
      shadowUrl: markerIconShadow,
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41],
  });

  const redIcon = new L.Icon({
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  useEffect(() => {

    const fetchData = async () => {
        
      try {
            
        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        };

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/company-management/warehouse/${fieldId}/`, config);

        setName(response.data.Name);
        
        setFarmer(response.data.FarmerName)

        setLat(response.data.Latitudine)

        setLong(response.data.Longitudine)

      } catch (error) {
        console.error('Error during GET request:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token]);


  useEffect(() => {

    const fetchData = async () => {
        
      try {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            };

            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/farmavailable/`, config);
            
            console.log(response)
            setFarmeroptions(response.data);
            
        } catch (error) {
            console.error('Error during GET request:', error);
        }
    };

    fetchData();
  }, [token]);

  const _onCreate = (e) => {
    if (!isDrawing) {
        return;
    }

    const { layerType, layer } = e;
    if (layerType === 'marker') {
        const { lat, lng } = layer.getLatLng();
        setMapLayers([{ id: layer._leaflet_id, latlng: [lng, lat] }]);
        setIsDrawing(false);
    }
  };

  const _onEdited = (e) => {
      const { layers: { _layers } } = e;
      const editedLayers = Object.values(_layers).map(({ _leaflet_id, editing }) => {
          
          const { lat, lng } = editing._marker._latlng;
          return { id: _leaflet_id, latlng: [lng, lat] };
      });
      setMapLayers(editedLayers);
  };

  const _onDeleted = (e) => {
      const { layers: { _layers } } = e;
      const remainingLayers = mapLayers.filter(layer => !_layers[layer.id]);
      setMapLayers(remainingLayers);
      setIsDrawing(true);
  };

  const getFarmerId = async (farmer) => {
    try {
        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`
            }
          }   
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/farm/${farmer}`, config);
        return response.data;
    } catch (error) {
        console.error("Error fetching farmer ID:", error);
        throw error;
    }
  };

  const Submit = async() => {

    setLoadingsubmitting(false);

    if (mapLayers.length===0) {
        setMessage(t('Please report the position of the warehouse'))
        setError(true)
        setTimeout(function() {
          setError(false)
        }, 5000);
        setLoadingsubmitting(true);
        return;
    } 

    if (name==='') {
        setMessage(t('Please insert the name of the warehouse'))
        setError(true)
        setTimeout(function() {
          setError(false)
        }, 5000);
        setLoadingsubmitting(true);
        return;
    }
    
    
    if (farmer==='') {
        setMessage(t('Please insert the farm to relate to the warehouse'))
        setError(true)
        setTimeout(function() {
          setError(false)
        }, 5000);
        setLoadingsubmitting(true);
        return;
    }

    try {
        
        const Latitudine = mapLayers[0].latlng[0]
        const Longitudine = mapLayers[0].latlng[1]
        const farmerId = await getFarmerId(farmer)
      
        const payload = {
            Name: name,
            Farm: farmerId,
            Latitudine: Latitudine,
            Longitudine:  Longitudine,
        };

    
        const config = {
          headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${token}`
          }
        }   
            
        axios.put(`${process.env.REACT_APP_BACKEND_API}/company-management/update-warehouse/${fieldId}/`, payload, config)

    } catch (error) {
        console.log(error)
        setMessage(t('An error occured while creating the field. If the problem persiste please send us an email automaticfarmsolution@gmail.com'))
        setError(true)
        setTimeout(function() {
            setError(false)
        }, 5000);
        setLoadingsubmitting(true);
        return;

    } finally {
        setLoadingsubmitting(true);
        navigate('/warehouse/');
    }
    
  };

  return (
    <div>
      <Header/>
      {isLoading ? (
        <>
          <Container className="mt--15" fluid>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                <Spinner>
                  {t('Loading')}
                </Spinner>
              </Col>
            </Row>
          </Container>
        </>
        ):(
          <>
            <Container className="mt--15" fluid>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                  <Card>
                    <CardHeader>
                      <h3>{t('Edit Warehouse')}</h3>
                    </CardHeader>
                    <CardBody>
                      <Form.Label>{t('Warehouse Name:')}</Form.Label>
                      <Form.Control 
                        type="text" 
                        placeholder={t('Warehouse Name:')} 
                        value={name} 
                        onChange={(e) => setName(e.target.value)}/>
                      <br></br>
                      <Form.Label>{t('Farmer Company Name:')}</Form.Label>
                      <Form.Control
                        as="select"
                        placeholder={t('Farmer Company Name:')}
                        value={farmer} 
                        onChange={(e) => setFarmer(e.target.value)}>
                        {farmeroptions.map((option, index) => (
                            <option key={index} value={option.NameFarmer}>
                                {option.NameFarmer}
                            </option>
                        ))}
                      </Form.Control>
                      <br></br>
                      <Form.Label>{t('Search City:')}</Form.Label>
                      <Form.Control
                          type="text"
                          placeholder={t('Search City:')}
                          value={city}
                          onChange={(e) => setCity(e.target.value)} />
                      <br></br>
                      <br></br>
                      <Form.Label>{t('The red point is the old location of the warehouse')}</Form.Label>
                      <MapContainer ref={mapRef} center={[long, lat]} zoom={mapZoom} style={{ height: '500px', width: '100%', margin: 0, padding: 0 }}>
                        <LayersControl position="topright">
                            <LayersControl.BaseLayer checked name="OpenStreetMap">
                                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' />
                            </LayersControl.BaseLayer>
                            <LayersControl.BaseLayer checked name="Satellite">
                                <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" attribution='&copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community' />
                            </LayersControl.BaseLayer>
                        </LayersControl>
                        <Marker position={[long, lat]} icon={redIcon}>
                            <Popup>{t('Warehouse Old Location')}</Popup>
                        </Marker>
                        <FeatureGroup>
                            <EditControl
                                position="topleft"
                                draw={{
                                    marker: isDrawing,
                                    polygon: false,
                                    rectangle: false,
                                    polyline: false,
                                    circle: false,
                                    circlemarker: false,
                                }}
                                onCreated={_onCreate}
                                onEdited={_onEdited}
                                onDeleted={_onDeleted}
                            />
                            {mapLayers.map(layer => (
                                <Marker key={layer.id} position={layer.latlng} icon={customMarkerIcon}>
                                    <Popup>{t('Warehouse New Location')}</Popup>
                                </Marker>
                            ))}
                        </FeatureGroup>
                      </MapContainer>
                      {error &&
                          <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                              <Alert color="danger" fade={false}>{message}</Alert>
                          </Col>
                      }
                    </CardBody>
                    <CardFooter>
                      <Row>
                        {loadingsubmit ? (
                            <>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-2">
                                    <Button color="success" onClick={Submit}>
                                        {t('Edit Warehouse')} <i className="fas fa-plus"></i>
                                    </Button>
                                </Col>
                            </>
                        ) : (
                            <>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-2">
                                    <Button color="success" disabled>
                                        <Spinner size="sm">
                                            {t('Editing the Warehouse...')}
                                        </Spinner>
                                        <span>
                                            {' '}{t('Editing the Warehouse...')}
                                        </span>
                                    </Button>
                                </Col>
                            </>
                        )}
                        <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-2">
                            <Link to="/warehouse/">
                                <Button color="dark">
                                    {t('Go back')} <i className="fas fa-rotate-left"></i>
                                </Button>
                            </Link>
                        </Col>
                      </Row>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </Container>
          </>
      )}
    </div>
  )
}

export default UpdateWarehouse
