import React, { useState, useEffect } from 'react'
import Header from 'components/Headers/Header';
import { Button, Col, Row, Container, Card, CardHeader, CardBody, Spinner, CardFooter } from 'reactstrap';
import { useParams, useNavigate, Link  } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import axios from 'axios'
import { MapContainer, TileLayer, Marker, Popup, LayersControl } from 'react-leaflet';


function DeleteWarehouse() {

    const [loading, setLoading] = useState(true)
    const [loadingsubmit, setLoadingsubmit] = useState(true)

    const [name, setName]=useState('')
    const [farm, setFarm] = useState('')

    const [lat, setLat] = useState('')
    const [long, setLong] = useState('')

    const { fieldId } = useParams()
    const navigate = useNavigate()

    const token = useSelector((state) => state.userLogin.userInfo.token);

    // i18n
    const { t, i18n } = useTranslation(); 

    useEffect(() => {
        const storedLanguage = localStorage.getItem('selectedLanguageAFS');
        if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
        }
    }, [i18n]);

    useEffect(() => {

        const fetchData = async () => {
          
          try {
    
            const config = {
              headers: {
                  'Content-type': 'application/json',
                  Authorization: `Bearer ${token}`
              }
            }
    
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/company-management/warehouse/${fieldId}/`, config);
            
            setName(response.data.Name)
            setFarm(response.data.FarmerName)
            setLat(response.data.Longitudine)
            setLong(response.data.Latitudine)
           
          } catch (error) {
            // setMessage(t('Something went wrong. Please refresh the page and if the problem persists, please send us an email at automaticfarmsolution@gmail.com'))
            // setError(true)
            // setTimeout(function() {
            //     setError(false)
            // }, 5000);
            // setloadingsubmit(false);
            // return;
          } finally {
            setLoading(false)
          }
    
        };
    
        fetchData();
    }, [fieldId, token]);

    const submit = async () => {

        setLoadingsubmit(false)
        
        try {
    
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }
    
            await axios.delete(`${process.env.REACT_APP_BACKEND_API}/company-management/delete-warehouse/${fieldId}/`, config);

        } catch (error) {
            console.error(`Error deleting field with ID ${fieldId}:`, error);
        } finally {
            setLoadingsubmit(true)
            navigate('/warehouse/');
        }
    };

    return (
        <div>
            <Header/>
            {loading ? (
                <>
                <Container className="mt--15" fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                            <Spinner>
                                {t('Loading')}
                            </Spinner>
                        </Col>
                    </Row>
                </Container>
                </>
            ):(
                <>  {lat && long ? (
                        <Container className="mt--15" fluid>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                                    <Card>
                                        <CardHeader>
                                            <h3>{t('Are you sure to delete the warehouse?')}</h3>
                                        </CardHeader>
                                        <CardBody>
                                            <MapContainer center={[parseFloat(lat), parseFloat(long)]} zoom={16} style={{ height: '500px', width: '100%', margin: 0, padding: 0 }}>
                                                <LayersControl position="topright">
                                                    <LayersControl.BaseLayer checked name="OpenStreetMap">
                                                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' />
                                                    </LayersControl.BaseLayer>
                                                    <LayersControl.BaseLayer checked name="Satellite">
                                                        <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" attribution='&copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community' />
                                                    </LayersControl.BaseLayer>
                                                </LayersControl>
                                                <Marker position={[parseFloat(lat), parseFloat(long)]}>
                                                    <Popup>
                                                        <div>
                                                            <p>{t('Warehouse Name:')} {name}</p>
                                                            <p>{t('Warehouse Farm:')} {farm}</p>
                                                            <p>{t('Latitude:')} {lat}</p>
                                                            <p>{t('Longitude:')} {long}</p>
                                                        </div>
                                                    </Popup>
                                                </Marker>
                                            </MapContainer>
                                            <br></br>
                                            <ul>
                                                <li>{t('Warehouse Name:')} {name}</li>
                                                <li>{t('Warehouse Farm:')} {farm}</li>
                                            </ul>
                                        </CardBody>
                                        <CardFooter>
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="d-flex justify-content-center">
                                                {loadingsubmit ? (
                                                    <>
                                                        <Button color="danger" onClick={submit}>
                                                            {t('Delete Warehouse')} <i className="fas fa-trash"></i>
                                                        </Button>
                                                    </>
                                                    ) : (
                                                    <>
                                                    <Button color="danger" disabled>
                                                        <Spinner size="sm">
                                                            {t('Deleting the Warehouse...')}
                                                        </Spinner>
                                                        <span>
                                                        {' '}{t('Deleting the Warehouse...')}
                                                        </span>
                                                    </Button>
                                                    </>
                                                    )
                                                }
                                                <div className="mx-2"></div>
                                                <Link to="/warehouse/">
                                                    <Button color="primary">
                                                        {t('Go back')} <i className="fas fa-rotate-left"></i>
                                                    </Button>
                                                </Link>                                           
                                            </Col>
                                        </CardFooter>
                                    </Card>

                                </Col>
                            </Row>
                        </Container>
                    ) : null}

                </>
            )}
        </div>
    )
}

export default DeleteWarehouse
