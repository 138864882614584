import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactFlagsSelect from "react-flags-select";

import './LanguageSelector.css'

const LanguageSelector = () => {

  const { i18n } = useTranslation();

  const handleLanguageChange = (countryCode) => {
    i18n.changeLanguage(countryCode);
    localStorage.setItem('selectedLanguageAFS', countryCode);
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem('selectedLanguageAFS');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);

  return (
    <>
        <ReactFlagsSelect
            selected={i18n.language}
            onSelect={(countryCode) => handleLanguageChange(countryCode)}
            countries={["US", "GB", "IT", "ES", "FR", "DE"]}
            customLabels={{US: "EN-US", GB:"GB", IT:"IT", ES:"ES", FR:"FR", DE:"DE"}}
            placeholder="Language"
            showSelectedLabel={false}
            showSecondarySelectedLabel={false}
            showOptionLabel={false}
            className="custom-flags-select"
            fullWidth={false}
        />
    </>
  );
};

export default LanguageSelector;
