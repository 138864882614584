import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button, Col, Row, Container, Card, CardHeader, Spinner, CardBody, CardFooter } from 'reactstrap';

import axios from 'axios';
import Header from 'components/Headers/Header'
import { useSelector } from 'react-redux'

import { MapContainer, TileLayer, Marker, LayersControl, Popup } from 'react-leaflet';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerIconShadow from 'leaflet/dist/images/marker-shadow.png';
import L from 'leaflet'; // Import leaflet library

import { useTranslation } from 'react-i18next';

function DeleteAllert() {

    const [loading, setloading] = useState(true)
    const [deletenew, setdeletenew] = useState(true)
    
    const [iotposition, setiotposition] = useState([]);

    const { fieldId } = useParams();

    const navigate = useNavigate();

    const token = useSelector((state) => state.userLogin.userInfo.token);
    
    ////////////
    const { t, i18n } = useTranslation();  // Destructure i18n from useTranslation

    useEffect(() => {
        const storedLanguage = localStorage.getItem('selectedLanguageAFS');
        if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);  // Use i18n to change language
        }
    }, [i18n]);
    ////////////

    const customMarkerIcon = new L.Icon({
      iconUrl: markerIcon,
      shadowUrl: markerIconShadow,
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41],
    });

    const [farmerData, setFarmerData] = useState({
      Cabina: '',
      Maggiore_Minore: '',
      Valore: '',
      Variabile: '',
    });
  
    useEffect(() => {
      const fetchData = async () => {
          try {
              const config = {
                  headers: {
                      'Content-type': 'application/json',
                      Authorization: `Bearer ${token}`
                  }
              }  
              const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/iot/allert/${fieldId}/`, config);

              setFarmerData({
                Cabina: response.data.Cabina,
                Maggiore_Minore: response.data.Maggiore_Minore,
                Valore: response.data.Valore,
                Variabile: response.data.Variabile
              });
              
          } catch (error) {
              console.error('Error fetching field data:', error);
          }
      };
  
      fetchData();
    }, [fieldId, token]);

    useEffect(() => {

      const fetchData = async () => {
  
        try {
  
          const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`
            }
          }
  
          setiotposition('')
          
          const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/iot/iot-position?cabina=${farmerData.Cabina}`, config);
          
          setiotposition(response.data)
  
        } catch (error) {
          console.error('Errore durante la richiesta GET:', error);
        } finally {
          setloading(false)
        }
        
      };
  
      fetchData();
    }, [token, farmerData]);

    const deleteAllert = async () => {
      setdeletenew(false)
      try {
  
          const config = {
              headers: {
                  'Content-type': 'application/json',
                  Authorization: `Bearer ${token}`
              }
          }  
  
          await axios.delete(`${process.env.REACT_APP_BACKEND_API}/iot/delete-allert/${fieldId}/`,config);
          setdeletenew(true)
          navigate('/allert/');
      } catch (error) {
          console.error(`Error deleting field with ID ${fieldId}:`, error);
      }
      
    };

    return (
      <div>
        <Header/>
        {loading ? (
          <>
            <Container className="mt--15" fluid>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Spinner>
                  {t('Loading')}
                  </Spinner>
                </Col>
              </Row>
            </Container>
          </>
        ):(
          <>
            <Container className="mt--15" fluid>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Card className="shadow">
                    <CardHeader className="border-0">
                      <h3 className="mb-0">{t('Are you sure to remove this allert?')}</h3>
                    </CardHeader>
                    <CardBody>
                    {iotposition.length > 0 ? (
                        <>
                          <h3>{t('IoT Position')}</h3>
                          <MapContainer center={[iotposition[0].lat, iotposition[0].longi]} zoom={15} style={{ height: '200px', width: '100%' }}>
                            <LayersControl position="topright">
                                <LayersControl.BaseLayer checked name="OpenStreetMap">
                                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'/>
                                </LayersControl.BaseLayer>
                                <LayersControl.BaseLayer checked name="Satellite">
                                <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"/>        
                                </LayersControl.BaseLayer>
                            </LayersControl>
                            <Marker position={[iotposition[0].lat, iotposition[0].longi]} icon={customMarkerIcon}>
                                <Popup>
                                    <h3>{t('IoT Number')} {0 + 1}</h3>
                                </Popup>
                            </Marker>
                          </MapContainer>
                        </>
                      ) : (
                        <>
                        </>
                      )}
                      <br></br>
                      <ul>
                        <li>{t('Cabin Number:')} <strong>{farmerData.Cabina}</strong></li>
                        <li>{t('Logic - Higher or Lower:')} <strong>{farmerData.Maggiore_Minore}</strong></li>
                        <li>{t('Threshold Value:')} <strong>{farmerData.Valore}</strong></li>
                        <li>{t('Variabile:')} <strong>{farmerData.Variabile}</strong></li>
                      </ul>
                    </CardBody>
                    <CardFooter>
                      <Row>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                        {deletenew ? (
                          <>
                            <Button color="danger" onClick={deleteAllert}>
                              {t('Delete Allert')} <i className="fas fa-trash"></i>
                            </Button>
                          </>
                        ):(
                          <>
                            <Button color="danger" disabled>
                              <Spinner size="sm">
                              {t('Loading...')}
                              </Spinner>
                              <span>
                                {' '}{t('Loading')}
                              </span>
                            </Button>
                          </>
                        )}
                        </Col>
                        <br></br>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                          <Link to="/allert/">
                            <Button color="primary">
                              {t('Go back')}<i className="fas fa-rotate-left"></i>
                            </Button>
                          </Link>  
                        </Col>
                      </Row>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </Container>
          </>
        )}
      </div>
    )
}

export default DeleteAllert
