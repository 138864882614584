import React, { useState, useEffect } from 'react'
import Header from 'components/Headers/Header';
import { Button, Col, Row, Container, Card, CardHeader, CardBody, Spinner, CardFooter } from 'reactstrap';
import { useParams, useNavigate, Link  } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import axios from 'axios'

function DeleteFertilizer() {

    const [loading, setLoading] = useState(true)
    const [loadingsubmit, setLoadingsubmit] = useState(true)

    const [nutrition, setNutrition]=useState('')

    const { fieldId } = useParams()
    const navigate = useNavigate()

    const token = useSelector((state) => state.userLogin.userInfo.token);

    // i18n
    const { t, i18n } = useTranslation(); 

    useEffect(() => {
        const storedLanguage = localStorage.getItem('selectedLanguageAFS');
        if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
        }
    }, [i18n]);

    useEffect(() => {

        const fetchData = async () => {
  
          try {
    
            const config = {
              headers: {
                  'Content-type': 'application/json',
                  Authorization: `Bearer ${token}`
              }
            }
  
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/company-management/fertilizer/${fieldId}`, config);
            
            setNutrition(response.data)
            
          } catch (error) {
            console.error('Errore durante la richiesta GET:', error);
          } finally {
            setLoading(false)
          }
          
        };
    
        fetchData();
    }, [token, fieldId]);

    const submit = async () => {

        setLoadingsubmit(false)
        
        try {
    
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }
    
            await axios.delete(`${process.env.REACT_APP_BACKEND_API}/company-management/delete-fertilizer/${fieldId}/`, config);

        } catch (error) {
            console.error(`Error deleting field with ID ${fieldId}:`, error);
        } finally {
            setLoadingsubmit(true)
            navigate('/fertilizer/');
        }
    };

    return (
        <div>
            <Header/>
            {loading ? (
                <>
                    <Container className="mt--15" fluid>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                                <Spinner>
                                    {t('Loading')}
                                </Spinner>
                            </Col>
                        </Row>
                    </Container>
                </>
            ):(
                <>
                    <Container className="mt--15" fluid>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                                <Card>
                                    <CardHeader>
                                        <h3>{t('Are you sure to delete this fertilizer?')}</h3>
                                    </CardHeader>
                                    <CardBody>
                                        <p>{t('Commercial Product Name')} {nutrition.Name}</p>
                                        <p>{t('Macro Type:')} {nutrition.MacroType}</p>
                                        <p>{t('Type')} {nutrition.Type}</p>
                                        <p>{t('Crop')} {nutrition.Crop}</p>
                                        <p>{t('Productor')} {nutrition.Productor}</p>
                                        <p>{t('Organic Product')} {nutrition.OrganicApproved ? 'Yes' : 'No'}</p>
                                        <p>{t('Nitrogen %')} {nutrition.Azoto}</p>
                                        <p>{t('Phosphorus %')} {nutrition.Fosforo}</p>
                                        <p>{t('Potassium %')} {nutrition.Potassio}</p>
                                    </CardBody>
                                    <CardFooter>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="d-flex justify-content-center">
                                            {loadingsubmit ? (
                                                <>
                                                    <Button color="danger" onClick={submit}>
                                                        {t('Delete Fertilizer')} <i className="fas fa-trash"></i>
                                                    </Button>
                                                </>
                                                ) : (
                                                <>
                                                <Button color="danger" disabled>
                                                    <Spinner size="sm">
                                                        {t('Deleting the Fertilizer...')}
                                                    </Spinner>
                                                    <span>
                                                    {' '}{t('Deleting the Fertilizer...')}
                                                    </span>
                                                </Button>
                                                </>
                                                )
                                            }
                                            <div className="mx-2"></div>
                                            <Link to="/fertilizer/">
                                                <Button color="primary">
                                                    {t('Go back')} <i className="fas fa-rotate-left"></i>
                                                </Button>
                                            </Link>                                           
                                    </Col>
                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </>
            )}
        </div>
    )
}

export default DeleteFertilizer
