import React, {useEffect} from 'react'
import Header from 'components/Headers/Header';
import { Container, Row, Col, Button, Card, CardHeader, CardBody} from 'reactstrap'
import {useDispatch} from 'react-redux'
import {logout} from 'actions/userActions'
import {useNavigate} from 'react-router-dom'
import { useTranslation } from 'react-i18next';

function PaymentSuccess() {

    ////////////

    const { t, i18n } = useTranslation();

    useEffect(() => {
        const storedLanguage = localStorage.getItem('selectedLanguageAFS');
        if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
        }
    }, [i18n]);

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const logoutsubmit = async (event) => {
        event.preventDefault();
        dispatch(logout())
        navigate("/")
    };

    return (
        <div>
            <Header/>
            <Container>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                        <Card>
                            <CardHeader>
                                <h3>{t('The payment was successful')}</h3>
                            </CardHeader>
                            <CardBody>                                
                                <h3>{t('Now you should logout and login again to access all the features just unlocked')}</h3>
                                <br></br>
                                <Button color='success' onClick={logoutsubmit}>
                                {t('Logout')}
                                </Button>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default PaymentSuccess
