import React, { useState, useEffect } from 'react'
import Header from 'components/Headers/Header';
import { Button, Col, Row, Container, Card, CardHeader, CardBody, Spinner, CardFooter } from 'reactstrap';
import { useParams, useNavigate, Link  } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import axios from 'axios'
import { MapContainer, TileLayer, Marker, Popup, LayersControl } from 'react-leaflet';

function FieldNoteDelete() {

  const [loading, setLoading] = useState(true)
  const [loadingsubmit, setLoadingsubmit] = useState(true)

  const [fieldnote, setFieldnote] = useState([])

  const token = useSelector((state) => state.userLogin.userInfo.token);

  const { fieldId } = useParams()
  const navigate = useNavigate()

  // i18n
  const { t, i18n } = useTranslation(); 

  useEffect(() => {
      const storedLanguage = localStorage.getItem('selectedLanguageAFS');
      if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
      }
  }, [i18n]);

  useEffect(() => {

    const fetchData = async () => {
      
      try {

        const config = {
          headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${token}`
          }
        }

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/company-management/field-note/${fieldId}/`, config);

        setFieldnote(response.data)
       
      } catch (error) {

      } finally {
        setLoading(false)
      }

    };

    fetchData();
  }, [fieldId, token]);

  const submit = async () => {

    setLoadingsubmit(false)
    
    try {

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }

        await axios.delete(`${process.env.REACT_APP_BACKEND_API}/company-management/delete-field-note/${fieldId}/`, config);

    } catch (error) {
        console.error(`Error deleting field with ID ${fieldId}:`, error);
    } finally {
        setLoadingsubmit(true)
        navigate('/field-note/');
    }
  };

  return (
    
    <div>
      <Header/>
      {loading ? (
          <>
            <Container className="mt--15" fluid>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                  <Spinner>
                    {t('Loading')}
                  </Spinner>
                </Col>
              </Row>
            </Container>
          </>
      ):(
        <>
          <Container className="mt--15" fluid>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
              <Card>
                <CardHeader>
                  <h3>{t('Are you sure to delete this field note?')}</h3>
                </CardHeader>
                <CardBody>
                  <MapContainer center={[parseFloat(fieldnote.Longitudine),parseFloat(fieldnote.Latitudine)]} zoom={16} style={{ height: '500px', width: '100%', margin: 0, padding: 0 }}>
                    <LayersControl position="topright">
                      <LayersControl.BaseLayer checked name="OpenStreetMap">
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' />
                      </LayersControl.BaseLayer>
                      <LayersControl.BaseLayer checked name="Satellite">
                        <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" attribution='&copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community' />
                      </LayersControl.BaseLayer>
                    </LayersControl>
                    <Marker position={[parseFloat(fieldnote.Longitudine),parseFloat(fieldnote.Latitudine)]}>
                      <Popup>
                        <div>
                          <p>{t('Field Note Date:')} {fieldnote.NoteDate}</p>
                          <p>{t('Field Note Farm:')} {fieldnote.FarmerName}</p>
                          <p>{t('Field Note Field:')} {fieldnote.FieldName}</p>
                          <p>{t('Field Note Operator:')} {fieldnote.OperatorName}</p>
                          <p>{t('Field Note Type:')} {fieldnote.Type}</p>
                          <p>{t('Field Note Title:')} {fieldnote.Title}</p>
                          <p>{t('Field Note Description:')} {fieldnote.Description}</p>
                        </div>
                      </Popup>
                    </Marker>
                  </MapContainer>
                  <br></br>
                  <h3>{t('Field Note Data')}</h3>
                  <ul>
                      <li>{t('Field Note Date:')} {fieldnote.NoteDate}</li>
                      <li>{t('Field Note Farm:')} {fieldnote.FarmerName}</li>
                      <li>{t('Field Note Field:')} {fieldnote.FieldName}</li>
                      <li>{t('Field Note Operator:')} {fieldnote.OperatorName}</li>
                      <li>{t('Field Note Type:')} {fieldnote.Type}</li>
                      <li>{t('Field Note Title:')} {fieldnote.Title}</li>
                      <li>{t('Field Note Description:')} {fieldnote.Description}</li>
                  </ul>
                </CardBody>
                <CardFooter>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="d-flex justify-content-center">
                    {loadingsubmit ? (
                      <>
                        <Button color="danger" onClick={submit}>
                          {t('Delete Field Note')} <i className="fas fa-trash"></i>
                        </Button>
                      </>
                      ) : (
                      <>
                      <Button color="danger" disabled>
                        <Spinner size="sm">
                          {t('Deleting the Field Note...')}
                        </Spinner>
                        <span>
                          {' '}{t('Deleting the Field Note...')}
                        </span>
                      </Button>
                      </>
                      )
                    }
                    <div className="mx-2"></div>
                    <Link to="/field-note/">
                      <Button color="primary">
                        {t('Go back')} <i className="fas fa-rotate-left"></i>
                      </Button>
                    </Link>                                           
                  </Col>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          </Container>
        </>
      )}
    </div>
  )
}

export default FieldNoteDelete
