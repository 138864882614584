import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate, Link  } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import Header from 'components/Headers/Header';
import { Form, Col, Row } from 'react-bootstrap';
import {Container, Alert, Button, Spinner, Card, CardBody, CardHeader, CardFooter} from 'reactstrap'
import { MapContainer, TileLayer, LayersControl, GeoJSON, FeatureGroup, Marker, Popup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerIconShadow from 'leaflet/dist/images/marker-shadow.png';
import L from 'leaflet';
import axios from 'axios'
import 'leaflet/dist/leaflet.css';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png',
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png',
});

function FieldNoteUpdate() {
  
  const [isLoading, setIsLoading] = useState(true)
  const [loadingsubmit, setLoadingsubmit] = useState(true)
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const [title, setTitle] = useState('')
  const [farm, setFarm] = useState('')
  const [farmoptions, setFarmoptions] = useState([])

  const [field, setField] = useState('')
  const [fieldoptions, setFieldoptions] = useState([])

  const [oldpoint, setOldpoint] = useState({
    Latitudine: '',
    Longitudine: '',
  });

  const [type, setType] = useState('')
  const [description, setDescription] = useState('')
  const [notedate, setNotedate] = useState('')
  
  const [operator, setOperator] = useState('')
  const [operatoroptions, setOperatoroptions] = useState([])

  const [fieldData, setfieldData] = useState({
    Field: '',
    Centroid: '',
    Crop: '',
    CropRotation: '',
    OrganicVsConventional: ''
  });

  const [mapLayers, setMapLayers] = useState([]);
  const [isDrawing, setIsDrawing] = useState(true);
  const [mapCenter, setMapCenter] = useState([45.1657, 10.4515]);
  const [mapZoom, setMapZoom] = useState(4);
  
  const { fieldId } = useParams()
  const navigate = useNavigate()
  
  const token = useSelector((state) => state.userLogin.userInfo.token);

  // i18n
  const { t, i18n } = useTranslation(); 

  useEffect(() => {
      const storedLanguage = localStorage.getItem('selectedLanguageAFS');
      if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
      }
  }, [i18n]);

  useEffect(() => {

    const fetchData = async () => {
        
      try {

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        };

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/company-management/field-note/${fieldId}`, config);
        
        setTitle(response.data.Title);

        setFarm(response.data.FarmerName);

        setField(response.data.FieldName)

        setOldpoint({
          Latitudine: response.data.Latitudine,
          Longitudine: response.data.Longitudine,
        });

        setType(response.data.Type)

        setDescription(response.data.Description)

        setNotedate(response.data.NoteDate)

        setOperator(response.data.OperatorName)
         
      } catch (error) {
        console.error('Error during GET request:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token, fieldId]);

  useEffect(() => {
    const fetchData = async () => {
        try {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            };

            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/farmavailable/`, config);

            const extractedNames = response.data.map(item => item.NameFarmer);

            setFarmoptions(extractedNames);
          
        } catch (error) {
            console.error('Error during GET request:', error);
        }
    };

    fetchData();
  }, [token]);

  useEffect(() => {

    const fetchData = async () => {

        try {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            };

            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/field-by-farmer/${farm}`, config);
            
            setFieldoptions(response.data);
          
        } catch (error) {
            console.error('Error during GET request:', error);
        }
    };

    fetchData();
  }, [token, farm]);

  useEffect(() => {

    const fetchData = async () => {

        try {

          const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`
            }
          }
      
          const responsefield = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/field-selected/${field}`, config);
      
            setfieldData({
                Field: responsefield.data,
                Centroid: responsefield.data.properties.centroid,
                Crop: responsefield.data.properties.ActualCrop,
                CropRotation: responsefield.data.properties.CropRotation,
                OrganicVSConventional: responsefield.data.properties.OrganicVSConventional
            });

            setMapCenter([responsefield.data.properties.centroid[1], responsefield.data.properties.centroid[0]]);

            setMapZoom(15);
          
        } catch (error) {
            console.error('Error during GET request:', error);
        }
    };

    fetchData();
  }, [token, field]);

  useEffect(() => {

    const fetchData = async () => {
        
      try {

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        };

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/company-management/operator/`, config);

        setOperatoroptions(response.data)
          
      } catch (error) {
          console.error('Error during GET request:', error);
      }
    };

    fetchData();
  }, [token]);

  const customMarkerIcon = new L.Icon({
    iconUrl: markerIcon,
    shadowUrl: markerIconShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  const redIcon = new L.Icon({
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  const _onCreate = (e) => {
    if (!isDrawing) {
      return;
    }

    const { layerType, layer } = e;
    if (layerType === 'marker') {
      const { lat, lng } = layer.getLatLng();
      setMapLayers([{ id: layer._leaflet_id, latlng: [lng, lat] }]);
      setIsDrawing(false);
    }
};

  const _onEdited = (e) => {
    const { layers: { _layers } } = e;
    const editedLayers = Object.values(_layers).map(({ _leaflet_id, editing }) => {
        
        const { lat, lng } = editing._marker._latlng;
        return { id: _leaflet_id, latlng: [lng, lat] };
    });
    setMapLayers(editedLayers);
  };

  const _onDeleted = (e) => {
    const { layers: { _layers } } = e;
    const remainingLayers = mapLayers.filter(layer => !_layers[layer.id]);
    setMapLayers(remainingLayers);
    setIsDrawing(true);
  };

  const getFarmerId = async (farm) => {
    try {
        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`
            }
          }   
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/farm/${farm}`, config);
        return response.data;
    } catch (error) {
        console.error("Error fetching farmer ID:", error);
        throw error;
    }
  };

  const getFieldId = async (field) => {

    try {

      const config = {

        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${token}`
        }
        
      }

      const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/field-selected/${field}`, config);
      
      return response.data.id;

    } catch (error) {
      console.error("Error fetching farmer ID:", error);
      throw error;
    }
  };

  const getOperatorId = async (operator) => {

    try {

      const config = {

        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${token}`
        }
        
      }

      const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/company-management/operator/operatorname/${operator}`, config);
      
      return response.data.id;

    } catch (error) {
      console.error("Error fetching farmer ID:", error);
      throw error;
    }
  };

  const submit = async () => {

    setLoadingsubmit(false);
      
    if (mapLayers.length===0) {
        setMessage(t('Please report the position of the field note'))
        setError(true)
        setTimeout(function() {
          setError(false)
        }, 5000);
        setLoadingsubmit(true);
        return;
    }     
    
    if (title==='') {
        setMessage(t('Please insert the title of the field note'))
        setError(true)
        setTimeout(function() {
          setError(false)
        }, 5000);
        setLoadingsubmit(true);
        return;
    }
    
    if (farm==='') {
      setMessage(t('Please set the farm related to the field note'))
      setError(true)
      setTimeout(function() {
        setError(false)
      }, 5000);
      setLoadingsubmit(true);
      return;
    }

    if (field==='') {
      setMessage(t('Please set the field related to the field note'))
      setError(true)
      setTimeout(function() {
        setError(false)
      }, 5000);
      setLoadingsubmit(true);
      return;
    }

    if (type==='') {
      setMessage(t('Please insert the type of the field note'))
      setError(true)
      setTimeout(function() {
        setError(false)
      }, 5000);
      setLoadingsubmit(true);
      return;
    }

    if (description==='') {
      setMessage(t('Please insert the type of the field note'))
      setError(true)
      setTimeout(function() {
        setError(false)
      }, 5000);
      setLoadingsubmit(true);
      return;
    }

    if (notedate==='') {
      setMessage(t('Please insert the date of the field note'))
      setError(true)
      setTimeout(function() {
        setError(false)
      }, 5000);
      setLoadingsubmit(true);
      return;
    }

    if (operator==='') {
      setMessage(t('Please insert the operator that create the field note'))
      setError(true)
      setTimeout(function() {
        setError(false)
      }, 5000);
      setLoadingsubmit(true);
      return;
    }

    try {
        
      const Latitudine = mapLayers[0].latlng[0]
      const Longitudine = mapLayers[0].latlng[1]
      const farmerId = await getFarmerId(farm)    
      const fieldid = await getFieldId(field)
      const operatorID = await getOperatorId(operator)
    
      const payload = {
        Title: title,
        Farm: farmerId,
        Field: fieldid,
        Latitudine: Latitudine,
        Longitudine:  Longitudine,
        Type: type,
        Description: description,
        NoteDate: notedate,
        Operator: operatorID
      };
  
      const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
        }
      } 
          
      axios.put(`${process.env.REACT_APP_BACKEND_API}/company-management/update-field-note/${fieldId}/`, payload, config)

    } catch (error) {
      console.log(error)
      setMessage(t('An error occured while creating the field. If the problem persiste please send us an email automaticfarmsolution@gmail.com'))
      setError(true)
      setTimeout(function() {
          setError(false)
      }, 5000);
      setLoadingsubmit(true);
      return;

    } finally {
      setLoadingsubmit(true);
      navigate('/field-note/');
    }
    
  };

  return (
    <div>
      <Header />
      {isLoading ? (
        <Container className="mt--15" fluid>
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                    <Spinner>{t('Loading')}</Spinner>
                </Col>
            </Row>
        </Container>
      ) : (
        <>
          <Container className="mt--15" fluid>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                <Card>
                  <CardHeader>
                    <h3>{t('Edit Field Note')}</h3>
                  </CardHeader>
                  <CardBody>
                    <Form.Label>{t('Title of the note')}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t('Title of the note')}
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}>
                    </Form.Control>
                    <br></br>
                    <Form.Label>{t('Farmer Company Name:')}</Form.Label>
                    <Form.Control
                      as="select"
                      placeholder={t('Farmer Company Name:')}
                      value={farm}
                      onChange={(e) => setFarm(e.target.value)}>
                      <option value={""}></option>
                      {farmoptions.map((option, index) => (
                          <option key={index} value={option}>
                              {option}
                          </option>
                      ))}
                    </Form.Control>
                    <br></br>
                    <Form.Label>{t('Field')}</Form.Label>
                    <Form.Control
                      as="select"
                      placeholder={t('Field')}
                      value={field}
                      onChange={(e) => setField(e.target.value)}>
                      <option value={""}></option>
                      {fieldoptions.map((option, index) => (
                          <option key={index} value={option}>
                              {option}
                          </option>
                      ))}
                    </Form.Control>
                    <br></br>
                    <br></br>
                    <Form.Label>{t('In red there is the old Field Note')}</Form.Label>
                    <br></br>
                    <MapContainer key={JSON.stringify(fieldData)} center={mapCenter} zoom={mapZoom} style={{ height: '500px', width: '100%', margin: 0, padding: 0 }}>
                      <LayersControl position="topright">
                        <LayersControl.BaseLayer checked name="OpenStreetMap">
                          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' />
                        </LayersControl.BaseLayer>
                        <LayersControl.BaseLayer checked name="Satellite">
                          <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" attribution='&copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community' />
                        </LayersControl.BaseLayer>
                      </LayersControl>
                      {fieldData && fieldData.Field && fieldData.Field.geometry ? (
                        <FeatureGroup>
                          <GeoJSON      
                            data={{
                              type: 'Feature',
                              geometry: {
                                type: 'Polygon',
                                coordinates: fieldData.Field.geometry.coordinates,
                              },
                              properties: fieldData.Field.properties,
                            }}
                            style={{ fillColor: 'blue', weight: 1, opacity: 1, color: 'blue', fillOpacity: 0.15 }}
                          />
                        </FeatureGroup>
                      ) : null}
                      <FeatureGroup>
                        <EditControl
                          position="topleft"
                          draw={{
                            marker: isDrawing,
                            polygon: false,
                            rectangle: false,
                            polyline: false,
                            circle: false,
                            circlemarker: false,
                          }}
                          onCreated={_onCreate}
                          onEdited={_onEdited}
                          onDeleted={_onDeleted}
                        />
                        {oldpoint ? (
                          <Marker position={[oldpoint.Longitudine, oldpoint.Latitudine]} icon={redIcon} draggable={false}>
                            <Popup>{t('Old Field Note')}</Popup>
                          </Marker>
                        ) : null}
                      </FeatureGroup>
                    </MapContainer>
                    <br></br>
                    <Form.Label>{t('Type of Note')}</Form.Label>
                    <Form.Control
                      as="select"
                      placeholder={t('Type of Note')}
                      value={type}
                      onChange={(e) => setType(e.target.value)}>
                      <option value={""}>

                      </option>
                      <option value={"generic"}>
                        {t('Generic')}
                      </option>
                      <option value={"phatogens"}>
                        {t('Pathogens')}
                      </option>
                      <option value={"insect"}>
                        {t('Insects')}
                      </option>
                      <option value={"weed"}>
                        {t('Weed')}
                      </option>
                      <option value={"waterlogging"}>
                        {t('Waterlogging')}
                      </option>
                      <option value={"genericdamage"}>
                        {t('Generic Damage')}
                      </option>
                      <option value={"fieldsurvey"}>
                        {t('Field Survey')}
                      </option>
                      <option value={"cropstatus"}>
                        {t('Crop Status')}
                      </option>
                    </Form.Control>
                    <br></br>
                    <Form.Label>{t('Description')}</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={t('Description')}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}>
                    </Form.Control>
                    <br></br>
                    <Form.Label>{t('Date')}</Form.Label>
                    <Form.Control
                        type="date"
                        placeholder={t('Date')}
                        value={notedate}
                        onChange={(e) => setNotedate(e.target.value)}>
                    </Form.Control>
                    <br></br>
                    <Form.Label>{t('Operator')}</Form.Label>
                    <Form.Control
                      as="select"
                      placeholder={t('Operator')}
                      value={operator}
                      onChange={(e) => setOperator(e.target.value)}>
                      <option value={""}></option>
                      {operatoroptions.map((option, index) => (
                          <option key={index} value={option.Name}>
                              {option.Name}
                          </option>
                      ))}
                    </Form.Control>
                    <br></br>
                    {error &&
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                            <Alert color="danger" fade={false}>{message}</Alert>
                        </Col>
                    }
                  </CardBody>
                  <CardFooter>
                    <Row>
                      {loadingsubmit ? (
                          <>
                              <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-2">
                                  <Button color="success" onClick={submit}>
                                      {t('Edit Field Note')} <i className="fas fa-plus"></i>
                                  </Button>
                              </Col>
                          </>
                      ) : (
                          <>
                              <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-2">
                                  <Button color="success" disabled>
                                      <Spinner size="sm">
                                          {t('Editing the Field Note...')}
                                      </Spinner>
                                      <span>
                                          {' '}{t('Editing the Field Note...')}
                                      </span>
                                  </Button>
                              </Col>
                          </>
                      )}
                      <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-2">
                        <Link to="/field-note/">
                          <Button color="dark">
                            {t('Go back')} <i className="fas fa-rotate-left"></i>
                          </Button>
                        </Link>
                      </Col>
                      </Row>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </div>
  )
}

export default FieldNoteUpdate
