import React, { useEffect, useState } from 'react';
import {Row, Col, Spinner, Container, Card, CardBody, CardHeader, Alert, Button, CardTitle} from 'reactstrap'
import { useTranslation } from 'react-i18next';
import {useSelector} from 'react-redux'
import Header from 'components/Headers/Header';
import {Form} from 'react-bootstrap'
import { MapContainer, TileLayer, LayersControl, GeoJSON, FeatureGroup } from 'react-leaflet';
import axios from 'axios'

function SoilMapping() {

  const [loading, setloading] = useState(true)
  const [error, seterror] = useState('')
  const [message, setmessage] = useState('')

  const [loadingsubmitting, setloadingsubmitting] = useState(true)

  const [year, setyear] = useState('')
  const [nutrient, setnutrient] = useState('')

  const [farmeroption, setfarmeroption] = useState([])
  const [farmerselected, setfarmerselected] = useState('')

  const [fieldoption, setfieldoption] = useState([])
  const [fieldselected, setfieldselected] = useState('')

  const [map, setmap] = useState('')
  const [graph, setgraph] = useState('')
  const [percentange, setpercentange] = useState('')
  const [tonsco2, settonsco2] = useState('')
  const [creditmoney, setcreditmoney] = useState('')

  const [fieldData, setfieldData] = useState({
    Field: '',
    Centroid: '',
    Crop: '',
    CropRotation: '',
    OrganicVsConventional: ''
  });

  const [loadingmap, setloadingmap] = useState(true)
  const [loadinggraph, setloadinggraph] = useState(true)
  const [loadingpercentange, setloadingpercentange] = useState(true)
  const [loadingtonsco2, setloadingtonsco2] = useState(true)
  const [loadingcreditmoney, setloadingcreditmoney] = useState(true)

  const [label, setlabel]=useState('')

  const token = useSelector((state) => state.userLogin.userInfo.token);

  ////////////
  const { t, i18n } = useTranslation();

  useEffect(() => {
      const storedLanguage = localStorage.getItem('selectedLanguageAFS');
      if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
      }
  }, [i18n]);
  ////////////

  useEffect(() => {
        
    const fetchData = async () => {
        try {

            const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`
            }
            }
            
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/farm/`, config);
                            
            setfarmeroption(response.data);

        } catch (error) {
            setmessage(t('Something went wrong. If the problem persist please send us an email at automaticfarmsolution@gmail.com'))
            seterror(true)
            setTimeout(function() {
                seterror(false)
            }, 5000);
            return;
        } finally {
            setloading(false)
        }
    };

    fetchData(); 
  }, [token]);

  const handleSelectChange1 = async (e) => {
    
    setfarmerselected(e.target.value)
    
    const farmersele = e.target.value

    try {

        const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
        }
        }
        
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/field-by-farmer/${farmersele}`, config);
                    
        setfieldoption(response.data);

    } catch (error) {
        // setmessage(t('Something went wrong. If the problem persist please send us an email at automaticfarmsolution@gmail.com'))
        // seterror(true)
        // setTimeout(function() {
        //     seterror(false)
        // }, 5000);
        // return;
    }
  };

  const handleSelectChange2 = async (e) => {
    
    setfieldselected(e.target.value)
    
    const fieldsele = e.target.value

    try {
        const config = {
          headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${token}`
          }
        }
    
        const responsefield = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/field-selected/${fieldsele}`, config);
    
        setfieldData({
          Field: responsefield.data,
          Centroid: responsefield.data.properties.centroid,
          Crop: responsefield.data.properties.ActualCrop,
          CropRotation: responsefield.data.properties.CropRotation,
          OrganicVSConventional: responsefield.data.properties.OrganicVSConventional
        });

    } catch(error) {
        // console.log(error)
        // setmessage(t('Something went wrong. If the problem persist please send us an email at automaticfarmsolution@gmail.com'))
        // seterror(true)
        // setTimeout(function() {
        //     seterror(false)
        // }, 5000);
        // return;
    }
  };

  const submit = async (e) => {
  
    setloadingsubmitting(false)
    
    setlabel(nutrient)

    setloadingmap(false)
    setloadinggraph(false)
    setloadingpercentange(false)
    setloadingtonsco2(false)
    setloadingcreditmoney(false)

    if (!fieldData.Crop) {
      setmessage(t('Before you can run the model you should define the crop type. Go to the Field and update section the information'))
      seterror(true)
      setTimeout(function() {
          seterror(false)
      }, 5000);
      setloadingsubmitting(true)
      setloadingmap(true)
      setloadinggraph(true)
      setloadingpercentange(true)
      setloadingtonsco2(true)
      setloadingcreditmoney(true)
      return;
    }

    if (year==='') {
        setmessage(t('Please set the year'))
        seterror(true)
        setTimeout(function() {
            seterror(false)
        }, 5000);
        setloadingsubmitting(true)
        setloadingmap(true)
        setloadinggraph(true)
        setloadingpercentange(true)
        setloadingtonsco2(true)
        setloadingcreditmoney(true)
        return;
    }

    if (nutrient==='') {
        setmessage(t('Please set the nutrient'))
        seterror(true)
        setTimeout(function() {
            seterror(false)
        }, 5000);
        setloadingsubmitting(true)
        setloadingmap(true)
        setloadinggraph(true)
        setloadingpercentange(true)
        setloadingtonsco2(true)
        setloadingcreditmoney(true)
        return;
    }

    if (farmerselected==='') {
        setmessage(t('Please set the farmer'))
        seterror(true)
        setTimeout(function() {
            seterror(false)
        }, 5000);
        setloadingsubmitting(true)
        setloadingmap(true)
        setloadinggraph(true)
        setloadingpercentange(true)
        setloadingtonsco2(true)
        setloadingcreditmoney(true)
        return;
    }

    if (fieldselected==='') {
        setmessage(t('Please set the field'))
        seterror(true)
        setTimeout(function() {
            seterror(false)
        }, 5000);
        setloadingsubmitting(true)
        setloadingmap(true)
        setloadinggraph(true)
        setloadingpercentange(true)
        setloadingtonsco2(true)
        setloadingcreditmoney(true)
        return;
    }

    try {
      // Mappa / grafico / percentange / tonsco2 / creditmoney

      const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
        }
      } 
      
      const responsemap = await axios.get(`${process.env.REACT_APP_BACKEND_API}/soil-mapping/map/${fieldselected}/${year}/${nutrient}`, config)

      // data
      setmap(responsemap.data.data)

      if (responsemap.request.status===202) {

        setloadingmap(true)
        setloadinggraph(true)
        setloadingpercentange(true)
        setloadingtonsco2(true)
        setloadingcreditmoney(true)

      } else if (responsemap.request.status===400) {

        setloadingmap(true)
        setloadinggraph(true)
        setloadingpercentange(true)
        setloadingtonsco2(true)
        setloadingcreditmoney(true)

      } else {

        setloadingmap(true)
        // grafico / percentange / tonsco2 / creditmoney

        const responsegraph = await axios.get(`${process.env.REACT_APP_BACKEND_API}/soil-mapping/graph/${fieldselected}/${year}/${nutrient}`, config)

        setgraph(responsegraph.data.data)
        
        setloadinggraph(true)

        const responsepercentage = await axios.get(`${process.env.REACT_APP_BACKEND_API}/soil-mapping/carbon-credits-percentage/${fieldselected}/${year}/${nutrient}`, config)

        setpercentange(responsepercentage.data.data)
        setloadingpercentange(true)

        const responsetons = await axios.get(`${process.env.REACT_APP_BACKEND_API}/soil-mapping/carbon-credits-tons/${fieldselected}/${year}/${nutrient}`, config)

        settonsco2(responsetons.data.data)
        setloadingtonsco2(true)
        
        const responsecredit = await axios.get(`${process.env.REACT_APP_BACKEND_API}/soil-mapping/carbon-credits-money/${fieldselected}/${year}/${nutrient}`, config)
           
        setcreditmoney(responsecredit.data.data)
        setloadingcreditmoney(true)

      }


    } catch(error) {
        
        console.log(error)

    } finally {           
        
        setloadingsubmitting(true)

    }

  }

  return (
      <div>
          <Header />
          {loading ? (
              <>
                <Container className="mt--15" fluid>
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                      <Spinner animation="border" role="status">
                        <span className="sr-only">{t('Loading...')}</span>
                      </Spinner>
                    </Col>
                  </Row>
                </Container>
            </>
          ):(
            <>
              <Container className="mt--15" fluid>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                        <Card>
                            <CardHeader>
                                <h3>{t('Set the Field where apply the analysis')}</h3>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
                                        <Form>
                                            <Form.Label>{t('Set the Farmer')}</Form.Label>
                                            <Form.Control 
                                                as="select" 
                                                onChange={handleSelectChange1}
                                                value={farmerselected}>
                                                    <option value={""}>
                                                        
                                                    </option>
                                                {farmeroption.map((farmer, index) => (
                                                    <option key={index} value={farmer.NameFarmer}>
                                                        {farmer.NameFarmer}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                            <br></br>
                                            <Form.Label>{t('Set the Field')}</Form.Label>
                                            <Form.Control 
                                                as="select" 
                                                onChange={handleSelectChange2}
                                                value={fieldselected}>
                                                    <option value={""}>
                                                        
                                                    </option>
                                                {fieldoption.map((farmer, index) => (
                                                    <option key={index} value={farmer}>
                                                        {farmer}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                            <br></br>
                                            <Form.Label>{t('Set the Year of analysis')}</Form.Label>
                                            <Form.Control 
                                                as="select" 
                                                onChange={(e) => setyear(e.target.value)}
                                                value={year}>
                                                    <option value="">

                                                    </option>
                                                    <option value="2015">
                                                        2015
                                                    </option>
                                                    <option value="2016">
                                                        2016
                                                    </option>
                                                    <option value="2017">
                                                        2017
                                                    </option>
                                                    <option value="2018">
                                                        2018
                                                    </option>
                                                    <option value="2019">
                                                        2019
                                                    </option>
                                                    <option value="2020">
                                                        2020
                                                    </option>
                                                    <option value="2021">
                                                        2021
                                                    </option>
                                                    <option value="2022">
                                                        2022
                                                    </option>
                                                    <option value="2023">
                                                        2023
                                                    </option>
                                            </Form.Control>
                                            <br></br>
                                            <Form.Label>{t('Set the nutrient to visualize')}</Form.Label>
                                            <Form.Control 
                                                as="select" 
                                                onChange={(e) => setnutrient(e.target.value)}
                                                value={nutrient}>
                                                    <option value="">

                                                    </option>
                                                    <option value="carbonates">
                                                        {t('Carbonates')}
                                                    </option>
                                                    <option value="organic_carbon">
                                                        {t('Organic Carbon')}
                                                    </option>
                                                    <option value="soil_electrical_conductivity">
                                                        {t('Soil Electrical Conductivity')}
                                                    </option>
                                                    <option value="nitrogen">
                                                        {t('Nitrogen')}
                                                    </option>
                                                    <option value="potassium">
                                                        {t('Potassium')}
                                                    </option>
                                                    <option value="phosphorus">
                                                        {t('Phosphorus')}
                                                    </option>
                                                    <option value="pH">
                                                        {t('pH')}
                                                    </option>
                                            </Form.Control>
                                        </Form>
                                    </Col>
                                    <Col xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
                                        {fieldData.Field && (
                                            <>
                                                <br></br>
                                                <br></br>
                                                <h3>{t('Actual Crop:')}</h3>
                                                <h3>{t(`translation:${fieldData.Crop}`)}</h3>
                                                <br></br>
                                                <h3>{t('Crop Rotation:')}</h3>
                                                <h3>{t(`translation:${fieldData.CropRotation}`)}</h3>
                                                <br></br>
                                                <h3>{t('Organic vs Conventional:')}</h3>
                                                <h3>{t(`translation:${fieldData.OrganicVSConventional}`)}</h3>
                                                <br></br>
                                            </>
                                        )}
                                    </Col>
                                    <br></br>                              
                                    {fieldData.Field && (
                                        <>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={9}>
                                                <br></br>
                                                <MapContainer center={[fieldData.Centroid[1], fieldData.Centroid[0]]} zoom={16} style={{ height: '500px', width: '100%', margin: 0, padding: 0 }} key={JSON.stringify(fieldData.Field)}>
                                                    <LayersControl position="topright">
                                                        <LayersControl.BaseLayer checked name="OpenStreetMap">
                                                            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' />
                                                        </LayersControl.BaseLayer>
                                                        <LayersControl.BaseLayer checked name="Satellite">
                                                            <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" attribution='&copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community' />
                                                        </LayersControl.BaseLayer>
                                                    </LayersControl>
                                                    <FeatureGroup>
                                                    <GeoJSON      
                                                        data={{
                                                            type: 'Feature',
                                                            geometry: {
                                                            type: 'Polygon',
                                                            coordinates: fieldData.Field.geometry.coordinates,
                                                            },
                                                            properties: fieldData.Field.properties,
                                                        }}
                                                        style={{ fillColor: 'blue', weight: 1, opacity: 1, color: 'blue', fillOpacity: 0.15 }}
                                                    />
                                                    </FeatureGroup>
                                                </MapContainer>
                                                <br></br>
                                                <br></br> 
                                            </Col>
                                        </>
                                    )}
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        {loadingsubmitting ? (
                                            <>
                                                <Button color='success' onClick={submit}>
                                                    {t('Run The Analysis')}
                                                </Button>
                                            </>
                                        ):(
                                            <>
                                                <Button color="success" disabled>
                                                    <Spinner size="sm">
                                                        Loading...
                                                    </Spinner>
                                                    <span>
                                                    {' '}{t('Loading')}
                                                    </span>
                                                </Button>
                                            </>
                                        )}
                                        <br></br>
                                        <br></br>
                                        {error && <Alert color="danger" fade={false}>{message}</Alert>}
                                    </Col>
                                </Row>                             
                            </CardBody>
                        </Card>
                    </Col>
                    {map !== '' ? (
                        <>
                            {map === "false" ? (
                                <>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                                        <Alert color="danger" fade={false}>{t('The map is not ready yet')}</Alert>
                                    </Col>
                                </>
                            ):(
                                <>
                                    {label==="organic_carbon" ? (
                                        <>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-5">
                                                <Card>
                                                    <CardHeader>
                                                        {t('Soil Map')}
                                                    </CardHeader>
                                                    <CardBody>
                                                        {loadingmap ? (
                                                            <>
                                                                <iframe id="iframemapsoilmap" width="100%" height="410" srcDoc={map}></iframe>
                                                            </>
                                                        ):(
                                                            <>
                                                                <Spinner animation="border" role="status">
                                                                    <span className="sr-only">{t('Loading...')}</span>
                                                                </Spinner>
                                                            </>
                                                        )}                                                        
                                                    </CardBody>
                                                </Card>                                                
                                            </Col>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-5">
                                                <Card>
                                                    <CardHeader>
                                                        {t('Temporal Variation')}
                                                    </CardHeader>
                                                    <CardBody>
                                                        {loadinggraph ? (
                                                            <>
                                                                <iframe id="iframemapsoilmapgraph" width="100%" height="500" srcDoc={graph}></iframe>
                                                            </>
                                                        ):(
                                                            <>
                                                                <Spinner animation="border" role="status">
                                                                    <span className="sr-only">{t('Loading...')}</span>
                                                                </Spinner>
                                                            </>
                                                        )}                                                        
                                                    </CardBody>
                                                </Card>                                                
                                            </Col>
                                            {percentange>0 ? (
                                                <>
                                                    {loadingpercentange ? (
                                                        <>
                                                            <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} className="text-center mt-5">
                                                                <Card className="card-stats mb-4 mb-xl-0">
                                                                    <CardBody>
                                                                        <Row>
                                                                        <div className="col">
                                                                            <CardTitle
                                                                                tag="h5"
                                                                                className="text-uppercase text-muted mb-0"
                                                                                >
                                                                            {t('Annual percentage increase')}
                                                                            </CardTitle>
                                                                            <span className="h2 font-weight-bold mb-0">
                                                                            {percentange}
                                                                            </span>
                                                                        </div>
                                                                        <Col className="col-auto">
                                                                            <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                                                                            <i className="fa fa-plus" />
                                                                            </div>
                                                                        </Col>
                                                                        </Row>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        </>
                                                    ):(
                                                        <>
                                                            <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} className="text-center mt-5">
                                                                <Spinner animation="border" role="status">
                                                                    <span className="sr-only">{t('Loading...')}</span>
                                                                </Spinner>
                                                            </Col>
                                                        </>
                                                    )}

                                                </>
                                            ):(
                                                <>
                                                    {loadingpercentange ? (
                                                        <>
                                                            <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} className="text-center mt-5">
                                                                <Card className="card-stats mb-4 mb-xl-0">
                                                                    <CardBody>
                                                                        <Row>
                                                                        <div className="col">
                                                                            <CardTitle
                                                                                tag="h5"
                                                                                className="text-uppercase text-muted mb-0"
                                                                                >
                                                                            {t('Annual percentage increase')}
                                                                            </CardTitle>
                                                                            <span className="h2 font-weight-bold mb-0">
                                                                            {percentange}
                                                                            </span>
                                                                        </div>
                                                                        <Col className="col-auto">
                                                                            <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                                                            <i className="fa fa-minus" />
                                                                            </div>
                                                                        </Col>
                                                                        </Row>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        </>
                                                     ):(
                                                        <>
                                                            <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} className="text-center mt-5">
                                                                <Spinner animation="border" role="status">
                                                                    <span className="sr-only">{t('Loading...')}</span>
                                                                </Spinner>
                                                            </Col>
                                                        </>
                                                     )}

                                                </>
                                            )}
                                            {tonsco2>0 ? (
                                                <>
                                                    {loadingtonsco2 ? (
                                                        <>
                                                            <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} className="text-center mt-5">
                                                                <Card className="card-stats mb-4 mb-xl-0">
                                                                    <CardBody>
                                                                        <Row>
                                                                        <div className="col">
                                                                            <CardTitle
                                                                                tag="h5"
                                                                                className="text-uppercase text-muted mb-0"
                                                                                >
                                                                            {t('Tons of CO2 Sequestered')}
                                                                            </CardTitle>
                                                                            <span className="h2 font-weight-bold mb-0">
                                                                            {tonsco2}
                                                                            </span>
                                                                        </div>
                                                                        <Col className="col-auto">
                                                                            <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                                                                            <i className="fa fa-plus" />
                                                                            </div>
                                                                        </Col>
                                                                        </Row>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        </>
                                                    ):(
                                                        <>
                                                            <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} className="text-center mt-5">
                                                                <Spinner animation="border" role="status">
                                                                    <span className="sr-only">{t('Loading...')}</span>
                                                                </Spinner>
                                                            </Col>
                                                        </>
                                                    )}

                                                </>
                                            ):(
                                                <>
                                                    {loadingtonsco2 ? (
                                                        <>
                                                            <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} className="text-center mt-5">
                                                                <Card className="card-stats mb-4 mb-xl-0">
                                                                    <CardBody>
                                                                        <Row>
                                                                        <div className="col">
                                                                            <CardTitle
                                                                                tag="h5"
                                                                                className="text-uppercase text-muted mb-0"
                                                                                >
                                                                            {t('Tons of CO2 Sequestered')}
                                                                            </CardTitle>
                                                                            <span className="h2 font-weight-bold mb-0">
                                                                            {tonsco2}
                                                                            </span>
                                                                        </div>
                                                                        <Col className="col-auto">
                                                                            <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                                                            <i className="fa fa-minus" />
                                                                            </div>
                                                                        </Col>
                                                                        </Row>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        </>
                                                    ):(
                                                        <>
                                                            <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} className="text-center mt-5">
                                                                <Spinner animation="border" role="status">
                                                                    <span className="sr-only">{t('Loading...')}</span>
                                                                </Spinner>
                                                            </Col>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                            {creditmoney>0 ? (
                                                <>
                                                    {loadingcreditmoney ? (
                                                        <>
                                                            <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} className="text-center mt-5">
                                                                <Card className="card-stats mb-4 mb-xl-0">
                                                                    <CardBody>
                                                                        <Row>
                                                                        <div className="col">
                                                                            <CardTitle
                                                                                tag="h5"
                                                                                className="text-uppercase text-muted mb-0"
                                                                                >
                                                                            {t('Carbon Credits Generated')}
                                                                            </CardTitle>
                                                                            <span className="h2 font-weight-bold mb-0">
                                                                            {creditmoney}
                                                                            </span>
                                                                        </div>
                                                                        <Col className="col-auto">
                                                                            <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                                                                            <i className="fa fa-plus" />
                                                                            </div>
                                                                        </Col>
                                                                        </Row>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        </>
                                                    ):(
                                                        <>
                                                            <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} className="text-center mt-5">
                                                                <Spinner animation="border" role="status">
                                                                    <span className="sr-only">{t('Loading...')}</span>
                                                                </Spinner>
                                                            </Col>
                                                        </>
                                                    )}
                                                </>
                                            ):(
                                                <>
                                                    {loadingcreditmoney ? (
                                                        <>
                                                            <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} className="text-center mt-5">
                                                                <Card className="card-stats mb-4 mb-xl-0">
                                                                    <CardBody>
                                                                        <Row>
                                                                        <div className="col">
                                                                            <CardTitle
                                                                                tag="h5"
                                                                                className="text-uppercase text-muted mb-0"
                                                                                >
                                                                            {t('Carbon Credits Generated')}
                                                                            </CardTitle>
                                                                            <span className="h2 font-weight-bold mb-0">
                                                                            {creditmoney}
                                                                            </span>
                                                                        </div>
                                                                        <Col className="col-auto">
                                                                            <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                                                            <i className="fa fa-minus" />
                                                                            </div>
                                                                        </Col>
                                                                        </Row>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        </>
                                                    ):(
                                                        <>
                                                            <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} className="text-center mt-5">
                                                                <Spinner animation="border" role="status">
                                                                    <span className="sr-only">{t('Loading...')}</span>
                                                                </Spinner>
                                                            </Col>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    ):(
                                        <>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-5">
                                                <Card>
                                                    <CardHeader>
                                                        {t('Soil Map')}
                                                    </CardHeader>
                                                    <CardBody>
                                                        {loadingmap ? (
                                                            <>
                                                                <iframe id="iframemapsoilmap" width="100%" height="410" srcDoc={map}></iframe>
                                                            </>
                                                        ):(
                                                            <>
                                                                <Spinner animation="border" role="status">
                                                                    <span className="sr-only">{t('Loading...')}</span>
                                                                </Spinner>
                                                            </>
                                                        )} 
                                                    </CardBody>
                                                </Card>                                                
                                            </Col>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-5">
                                                <Card>
                                                    <CardHeader>
                                                        {t('Temporal Variation')}
                                                    </CardHeader>
                                                    <CardBody>
                                                        {loadinggraph ? (
                                                            <>
                                                                <iframe id="iframemapsoilmapgraph" width="100%" height="500" srcDoc={graph}></iframe>
                                                            </>
                                                        ):(
                                                            <>
                                                                <Spinner animation="border" role="status">
                                                                    <span className="sr-only">{t('Loading...')}</span>
                                                                </Spinner>
                                                            </>
                                                        )}  
                                                    </CardBody>
                                                </Card>                                                
                                            </Col>
                                            {percentange>0 ? (
                                                <>
                                                    {loadingpercentange ? (
                                                        <>
                                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                                                                <Card className="card-stats mb-4 mb-xl-0">
                                                                    <CardBody>
                                                                        <Row>
                                                                        <div className="col">
                                                                            <CardTitle
                                                                                tag="h5"
                                                                                className="text-uppercase text-muted mb-0"
                                                                                >
                                                                            {t('Annual percentage increase')}
                                                                            </CardTitle>
                                                                            <span className="h2 font-weight-bold mb-0">
                                                                            {percentange}
                                                                            </span>
                                                                        </div>
                                                                        <Col className="col-auto">
                                                                            <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                                                                            <i className="fa fa-plus" />
                                                                            </div>
                                                                        </Col>
                                                                        </Row>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        </>
                                                    ):(
                                                        <>
                                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                                                                <Spinner animation="border" role="status">
                                                                    <span className="sr-only">{t('Loading...')}</span>
                                                                </Spinner>
                                                            </Col>
                                                        </>
                                                    )}
                                                </>
                                            ):(
                                                <>
                                                    {loadingpercentange ? (
                                                        <>
                                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                                                                <Card className="card-stats mb-4 mb-xl-0">
                                                                    <CardBody>
                                                                        <Row>
                                                                        <div className="col">
                                                                            <CardTitle
                                                                                tag="h5"
                                                                                className="text-uppercase text-muted mb-0"
                                                                                >
                                                                            {t('Annual percentage increase')}
                                                                            </CardTitle>
                                                                            <span className="h2 font-weight-bold mb-0">
                                                                            {percentange}
                                                                            </span>
                                                                        </div>
                                                                        <Col className="col-auto">
                                                                            <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                                                            <i className="fa fa-minus" />
                                                                            </div>
                                                                        </Col>
                                                                        </Row>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        </>
                                                    ):(
                                                        <>
                                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                                                                <Spinner animation="border" role="status">
                                                                    <span className="sr-only">{t('Loading...')}</span>
                                                                </Spinner>
                                                            </Col>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}

                                </>
                            )}
                        </>
                    ):null}
                  </Row>
                </Container>
              </>
          )}
      </div>
  )
}

export default SoilMapping
