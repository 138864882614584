import React, { useEffect, useState } from 'react';
import ReactWeather, { useOpenWeather } from 'react-open-weather';
import Header from 'components/Headers/Header';
import axios from 'axios';
import {Container, Row, Col, Card, CardHeader, CardBody, Spinner} from 'reactstrap'
import { useSelector } from 'react-redux'; 
import {Form} from 'react-bootstrap'

import { useTranslation } from 'react-i18next';

function Weather() {
  const [location, setLocation] = useState({ lat: '', lon: '' });
  const [cityName, setCityName] = useState('');
  const [loadingCityName, setLoadingCityName] = useState(true);
  const [layer, setLayer] = useState('temperature');

  const [htmlContentPlot, sethtmlContentPlot] = useState('');
  const [htmlmap, sethtmlmap] = useState('');

  const [loadingmap, setloadingmap] = useState(true)
  const [loadingplot, setloadingplot] = useState(true)

  const token = useSelector((state) => state.userLogin.userInfo.token);

  ////////////

  const { t, i18n } = useTranslation();

  useEffect(() => {
      const storedLanguage = localStorage.getItem('selectedLanguageAFS');
      if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
      }
  }, [i18n]);

  ////////////

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            lat: position.coords.latitude.toString(),
            lon: position.coords.longitude.toString(),
          });
        },
        (error) => {
          console.error('Error getting geolocation:', error.message);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }, []);

  useEffect(() => {
    if (location.lat && location.lon) {
      axios
        .get(
          `https://api.opencagedata.com/geocode/v1/json?key=0e194db9d8b843a097c2f6f2a788f8b2&language=en&q=${location.lat}+${location.lon}`
        )
        .then((response) => {
          const city = response.data.results[0].components.town;
          setCityName(city);
        })
        .catch((error) => {
          console.error('Error fetching city name:', error.message);
        })
        .finally(() => {
          setLoadingCityName(false);
        });
    }
  }, [location]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (location.lat === '' && location.lon === '') {
          // Handle the case when location.lat or location.lon is empty
        } else {
          const config = {
            headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          };
  
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_API}/wheater/map?long=${location.lon}&lat=${location.lat}&layer=${layer}`,
            config
          );

          sethtmlmap(response.data);
          setloadingmap(false)
        }
      } catch (error) {
        // Handle any errors that occurred during the request
        console.error('Error fetching weather data:', error);
      }
    };
  
    fetchData(); // Call the fetchData function
  
  }, [location, token, layer]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        if (location.lat === '' && location.lon === '') {
          // Handle the case when location.lat or location.lon is empty
        } else {
          const config = {
            headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          };

          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_API}/wheater/forecastwheater?long=${location.lon}&lat=${location.lat}`,
            config
          );
  
          sethtmlContentPlot(response.data);
          setloadingplot(false)
        }
      } catch (error) {
        // Handle any errors that occurred during the request
        console.error('Error fetching weather data:', error);
      }
    };
  
    fetchData(); // Call the fetchData function
  
  }, [location, token]);

  const { data, isLoading, errorMessage } = useOpenWeather({
    key: '1b9109d4e7b08a45173973e6d2d7993d',
    lat: location.lat,
    lon: location.lon,
    lang: 'it',
    unit: 'metric',
  });

  const customStyles = {
    fontFamily:  'Helvetica, sans-serif',
    gradientStart:  '#0181C2',
    gradientMid:  '#04A7F9',
    gradientEnd:  '#4BC4F7',
    locationFontColor:  '#FFF',
    todayTempFontColor:  '#FFF',
    todayDateFontColor:  '#B5DEF4',
    todayRangeFontColor:  '#B5DEF4',
    todayDescFontColor:  '#B5DEF4',
    todayInfoFontColor:  '#B5DEF4',
    todayIconColor:  '#FFF',
    forecastBackgroundColor:  '#FFF',
    forecastSeparatorColor:  '#DDD',
    forecastDateColor:  '#777',
    forecastDescColor:  '#777',
    forecastRangeColor:  '#777',
    forecastIconColor:  '#4BC4F7',
  };

  return (
    <div>
      <Header />
      <Container className="mt--15" fluid>
        <Row>
          {location.lat==='' ? (
            <>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
              <h3>{t('Please enable the geolocation in order to get the wheater data and visualization')}</h3>
            </Col>              
            </>
          ):(
            <>
              <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} className="text-center mt-5">
                <Card>
                  <CardHeader>
                    {t('Actual Wheater Data')}
                  </CardHeader>
                  <CardBody>
                    <ReactWeather
                      theme={customStyles}
                      isLoading={isLoading || loadingCityName}
                      errorMessage={errorMessage}
                      data={data}
                      lang="en"
                      locationLabel={cityName}
                      unitsLabels={{ temperature: 'C', windSpeed: 'Km/h' }}
                      showForecast
                    />
                  </CardBody>
                </Card>
              </Col>
              {loadingplot ? (
                <>
                  <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8} className="text-center mt-5">
                    <Spinner>
                      Loading
                    </Spinner>
                  </Col>
                </>
              ):(
                <>
                </>
              )}
              {loadingmap ? (
                <>
                  <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8} className="text-center mt-5">
                    <Spinner>
                      Loading
                    </Spinner>
                  </Col>
                </>
              ):(
                <>
                </>
              )}
              {htmlmap && (
                <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8} className="text-center mt-5">
                  <Card>
                    <CardHeader>
                      {t('Wheater Map')}
                    </CardHeader>
                    <CardBody>
                      <Form.Label>
                        {t('Select the climate variable to see')}
                      </Form.Label>
                      <Form.Control 
                        as="select" 
                        onChange={(e) => setLayer(e.target.value)}
                        >
                          <option value="temperature">
                            {t('Temperature')}
                          </option>
                          <option value="clouds">
                            {t('Clouds')}
                          </option>
                          <option value="precipitation">
                            {t('Precipitation')}                                
                          </option>
                          <option value="snow">
                            {t('Snow')}                                
                          </option>
                          <option value="pressure">
                            {t('Pressure')}
                          </option>
                          <option value="wind">
                            {t('Wind')}                                
                          </option>
                      </Form.Control>
                      <br></br>
                      <iframe id="iframe3" width="100%" height="400" srcDoc={htmlmap}></iframe>
                    </CardBody>
                  </Card>
                </Col>
              )}
              {htmlContentPlot && (
                <>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                    <Card>
                      <CardHeader>
                      {t('Forecast Temperature')}                        
                      </CardHeader>
                      <CardBody>
                        <iframe id="iframe4" width="100%" height="500" srcDoc={htmlContentPlot}></iframe>
                      </CardBody>
                    </Card>
                  </Col>
                </>
              )}
            </>
          )}
        </Row>
      </Container>
    </div>
  );
}

export default Weather;
