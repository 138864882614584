import React from "react";
import { useLocation, Route, Routes } from "react-router-dom";

import { Container } from "reactstrap";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import Index from "views/Index.js";

// Farm
import Farm from '../views/examples/farm/Farm'
import FarmCreate from '../views/examples/farm/FarmCreate'
import FarmDelete from '../views/examples/farm/FarmDelete'
import FarmUpdate from '../views/examples/farm/FarmUpdate'

// Field
import Field from '../views/examples/field/Field'
import CreateField from '../views/examples/field/CreateField'
import DeleteField from '../views/examples/field/DeleteField'
import UpdateField from '../views/examples/field/UpdateField'

// Satellite
import Satellite from '../views/examples/satellite/Satellite'

// Prescription Map
import PrescriptionMap from '../views/examples/prescriptionmap/PrescriptionMap'
import PrescriptionMapAI from '../views/examples/prescriptionmap/PrescriptionMapAI'

// Integration
import Integration from '../views/examples/integration/Integration'
import Confirmintegration from '../views/examples/integration/Confirmintegration'
import Problmeintegration from '../views/examples/integration/Problmeintegration'

// wheater
import Wheater from '../views/examples/agrometeo/Wheater'
import ClimaticIndex from '../views/examples/agrometeo/ClimaticIndex'

// IoT
import IoTSummary from '../views/examples/iot/IoTSummary'
import IoTAnalysis from '../views/examples/iot/IoTAnalysis'
import IoTAnomaly from '../views/examples/iot/IoTAnomaly'
import IoTComparation from '../views/examples/iot/IoTComparation'
import IoTTable from '../views/examples/iot/IoTTable'

// Allert
import Allert from '../views/examples/iot/Allert'
import CreateAllert from '../views/examples/iot/CreateAllert'
import DeleteAllert from '../views/examples/iot/DeleteAllert'
import UpdateAllert from '../views/examples/iot/UpdateAllert'

// Nutrient Balance
import NutrientBalance from '../views/examples/nutrientbalance/NutrientBalance'

// Soil Data
import SoilData from '../views/examples/soildata/SoilData'

// chat gpt
import Chatgpt from '../views/examples/chatgpt/Chatgpt'

// pestmodel
// import PestModel from '../views/examples/pestmodel/PestModel'

// cropmodel
import CropModel from '../views/examples/cropmodel/CropModel'

// SoilMapping
import SoilMapping from '../views/examples/soilmapping/SoilMapping'

// BioVsConv
// import BioVsConv from '../views/examples/bioconv/BioVsConv'

// Profile
import Profile from '../views/examples/profile/Profile'
import Support from '../views/examples/profile/Support'
import SupportMessageSendSuccess from '../views/examples/profile/SupportMessageSendSuccess'

import ExportDocument from '../views/examples/exportdocument/ExportDocument'

import StaffSection from '../views/examples/staffsection/StaffSection'

import { useSelector } from 'react-redux'; 
import { useState } from "react";

import ScrollToTop from "react-scroll-to-top";

import CreateOperator from '../views/examples/operator/CreateOperator'
import Operator from '../views/examples/operator/Operator'
import DeleteOperator from '../views/examples/operator/DeleteOperator'
import UpdateOperator from '../views/examples/operator/UpdateOperator'

import Warehouse from '../views/examples/warehouse/Warehouse'
import CreateWarehouse from '../views/examples/warehouse/CreateWarehouse'
import DeleteWarehouse from '../views/examples/warehouse/DeleteWarehouse'
import UpdateWarehouse from '../views/examples/warehouse/UpdateWarehouse'

import FieldNote from '../views/examples/fieldnote/FieldNote'
import FieldNoteCreate from '../views/examples/fieldnote/FieldNoteCreate'
import FieldNoteDelete from '../views/examples/fieldnote/FieldNoteDelete'
import FieldNoteUpdate from '../views/examples/fieldnote/FieldNoteUpdate'

import Yield from '../views/examples/yield/Yield'
import CreateYield from '../views/examples/yield/CreateYield'
import UpdateYield from '../views/examples/yield/UpdateYield'
import DeleteYield from '../views/examples/yield/DeleteYield'

import Fertilizer from '../views/examples/fertilzer/Fertilizer'
import CreateFertilizer from '../views/examples/fertilzer/CreateFertilizer'
import DeleteFertilizer from '../views/examples/fertilzer/DeleteFertilizer'
import UpdateFertilizer from '../views/examples/fertilzer/UpdateFertilizer'
import DuplicateFertilizer from '../views/examples/fertilzer/DuplicateFertilizer'

import Seed from '../views/examples/seed/Seed'
import CreateSeed from '../views/examples/seed/CreateSeed'
import DeleteSeed from '../views/examples/seed/DeleteSeed'
import UpdateSeed from '../views/examples/seed/UpdateSeed'
import DuplicateSeed from '../views/examples/seed/DuplicateSeed'

import Pesticide from '../views/examples/pesticide/Pesticide'
import CreatePesticide from '../views/examples/pesticide/CreatePesticide'
import UpdatePesticide from '../views/examples/pesticide/UpdatePesticide'
import DeletePesticide from '../views/examples/pesticide/DeletePesticide'
import DuplicatePesticide from '../views/examples/pesticide/DuplicatePesticide'

import FieldActivities from '../views/examples/fieldactivities/FieldActivities'
import CreateFieldActivites from '../views/examples/fieldactivities/CreateFieldActivites'
import DeleteFieldActivities from '../views/examples/fieldactivities/DeleteFieldActivities'
import UpdateFieldActivities from '../views/examples/fieldactivities/UpdateFieldActivities'
import DuplicateFieldActivities from '../views/examples/fieldactivities/DuplicateFieldActivities'

import Economy from '../views/examples/economy/Economy'

import OperatorCost from '../views/examples/operatorcost/OperatorCost'
import CreateOperatorCost from '../views/examples/operatorcost/CreateOperatorCost'
import UpdateOperatorCost from '../views/examples/operatorcost/UpdateOperatorCost'
import DeleteOperatorCost from '../views/examples/operatorcost/DeleteOperatorCost'
import DuplicateOperatorCost from '../views/examples/operatorcost/DuplicateOperatorCost'

import OtherRevenue from '../views/examples/otherrevenue/OtherRevenue'
import CreateRevenue from '../views/examples/otherrevenue/CreateRevenue'
import DeleteRevenue from '../views/examples/otherrevenue/DeleteRevenue'
import UpdateRevenue from '../views/examples/otherrevenue/UpdateRevenue'
import DuplicateRevenue from '../views/examples/otherrevenue/DuplicateRevenue'

import Irrigation from '../views/examples/irrigation/Irrigation'

import UpgradeYourProfile from '../views/examples/profile/UpgradeYourProfile'
import PaymentSuccess from '../views/examples/profile/PaymentSuccess'
import PaymentProblem from '../views/examples/profile/PaymentProblem'

// import axios from 'axios'

// core components
// import SendUsRequestToActivate from '../views/examples/welcomeoneboard/SendUsRequestToActivate'
// import WelcomeOnBoard from '../views/examples/welcomeoneboard/WelcomeOnBoard'
// import FarmCreateWelcome from '../views/examples/welcomeoneboard/FarmCreateWelcome'
// import CreateFieldWelcome from '../views/examples/welcomeoneboard/CreateFieldWelcome'

const Admin = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    if (mainContent.current) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      mainContent.current.scrollTop = 0;
    }
  }, [location]);

  const token = useSelector((state) => state.userLogin.userInfo.token);

  const have_sensor = useSelector((state) => state.userLogin.userInfo.have_sensor);

  const [ishave_sensor, setishave_sensor] = useState(have_sensor);

  const prescription_map = useSelector((state) => state.userLogin.userInfo.prescription_map);

  const [isprescription_map, setisprescription_map] = useState(prescription_map);

  const prescription_map_AI = useSelector((state) => state.userLogin.userInfo.prescription_map_AI);

  const [isprescription_map_ai, setisprescription_map_ai] = useState(prescription_map_AI);

  const nutrient_balance = useSelector((state) => state.userLogin.userInfo.nutrient_balance);

  const [isnutrient_balance, setisnutrient_balance] = useState(nutrient_balance);

  const soil_data = useSelector((state) => state.userLogin.userInfo.soil_data);

  const [issoil_data, setissoil_data] = useState(soil_data);

  const crop_models = useSelector((state) => state.userLogin.userInfo.crop_models);

  const [iscrop_models, setiscrop_models] = useState(crop_models);

  const pest_models = useSelector((state) => state.userLogin.userInfo.pest_models);

  const [ispest_models, setispest_models] = useState(crop_models);

  const soil_mapping = useSelector((state) => state.userLogin.userInfo.soil_mapping);

  const [issoil_mapping, setissoil_mapping] = useState(soil_mapping);

  const super_user = useSelector((state) => state.userLogin.userInfo.is_superuser);

  return (
    <>
      <ScrollToTop smooth color="#6f00ff" />
      <Sidebar
        {...props}
        logo={{
          innerLink: "/",
          imgSrc: require("../assets/img/brand/argon-react.png"),
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={"Marco"}
        />
        <Routes>
          <Route path="/" element={<Index />} />
          
          <Route path="/farm" element={<Farm />} />
          <Route path="/create-farm" element={<FarmCreate />} />
          <Route path="/update-farm/:fieldId" element={<FarmUpdate />} />
          <Route path="/delete-farm/:fieldId" element={<FarmDelete />} />

          <Route path="/field" element={<Field />} />
          <Route path="/create-field" element={<CreateField />} />
          <Route path="/update-field/:fieldId" element={<UpdateField />} />
          <Route path="/delete-field/:fieldId" element={<DeleteField />} />

          <Route path="/user-profile" element={<Profile />} />
          <Route path="/support" element={<Support />} />
          <Route path="/support-message-send-success" element={<SupportMessageSendSuccess />} />

          <Route path="/integration" element={<Integration />} />  
          <Route path="/confirm-integration" element={<Confirmintegration />} />
          <Route path="/problem-integration" element={<Problmeintegration />} />  

          <Route path="/wheater" element={<Wheater />} />
          <Route path="/climaticindex" element={<ClimaticIndex />} />

          <Route path="/satellite" element={<Satellite />} />

          <Route path="/digital-consulting" element={<Chatgpt />} />

          <Route path="/export-document" element={<ExportDocument />} />          

          <Route path="/operator" element={<Operator />} />
          <Route path="/create-operator" element={<CreateOperator />} />
          <Route path="/delete-operator/:fieldId" element={<DeleteOperator />} />
          <Route path="/update-operator/:fieldId" element={<UpdateOperator />} />

          <Route path="/warehouse" element={<Warehouse />} />
          <Route path="/create-warehouse" element={<CreateWarehouse />} />
          <Route path="/delete-warehouse/:fieldId" element={<DeleteWarehouse />} />
          <Route path="/update-warehouse/:fieldId" element={<UpdateWarehouse />} />

          <Route path="/field-note" element={<FieldNote />} />
          <Route path="/create-field-note" element={<FieldNoteCreate />} />
          <Route path="/delete-field-note/:fieldId" element={<FieldNoteDelete />} />
          <Route path="/update-field-note/:fieldId" element={<FieldNoteUpdate />} />

          <Route path="/yield" element={<Yield />} />
          <Route path="/create-yield" element={<CreateYield />} />
          <Route path="/delete-yield/:fieldId" element={<DeleteYield />} />
          <Route path="/update-yield/:fieldId" element={<UpdateYield />} />
          
          <Route path="/fertilizer" element={<Fertilizer />} />
          <Route path="/create-fertilizer" element={<CreateFertilizer />} />
          <Route path="/delete-fertilizer/:fieldId" element={<DeleteFertilizer />} />
          <Route path="/update-fertilizer/:fieldId" element={<UpdateFertilizer />} />    
          <Route path="/duplicate-fertilizer/:fieldId" element={<DuplicateFertilizer />} />    

          <Route path="/seed" element={<Seed />} />
          <Route path="/create-seed" element={<CreateSeed />} />
          <Route path="/delete-seed/:fieldId" element={<DeleteSeed />} />
          <Route path="/update-seed/:fieldId" element={<UpdateSeed />} />
          <Route path="/duplicate-seed/:fieldId" element={<DuplicateSeed />} />

          <Route path="/pesticide" element={<Pesticide />} />
          <Route path="/create-pesticide" element={<CreatePesticide />} />
          <Route path="/delete-pesticide/:fieldId" element={<DeletePesticide />} />
          <Route path="/update-pesticide/:fieldId" element={<UpdatePesticide />} /> 
          <Route path="/duplicate-pesticide/:fieldId" element={<DuplicatePesticide />} /> 

          <Route path="/field-activity" element={<FieldActivities />} />
          <Route path="/create-field-activity" element={<CreateFieldActivites />} />
          <Route path="/delete-field-activity/:fieldId" element={<DeleteFieldActivities />} />
          <Route path="/update-field-activity/:fieldId" element={<UpdateFieldActivities />} /> 
          <Route path="/duplicate-field-activity/:fieldId" element={<DuplicateFieldActivities />} />                  
          
          <Route path="/economy" element={<Economy />} /> 

          <Route path="/operator-cost" element={<OperatorCost />} />
          <Route path="/create-operator-cost" element={<CreateOperatorCost />} />
          <Route path="/delete-operator-cost/:fieldId" element={<DeleteOperatorCost />} />
          <Route path="/update-operator-cost/:fieldId" element={<UpdateOperatorCost />} /> 
          <Route path="/duplicate-operator-cost/:fieldId" element={<DuplicateOperatorCost />} />  

          <Route path="/revenue" element={<OtherRevenue />} />
          <Route path="/create-revenue" element={<CreateRevenue />} />
          <Route path="/delete-revenue/:fieldId" element={<DeleteRevenue />} />
          <Route path="/update-revenue/:fieldId" element={<UpdateRevenue />} /> 
          <Route path="/duplicate-revenue/:fieldId" element={<DuplicateRevenue />} />  

          <Route path="/upgrade-your-profile" element={<UpgradeYourProfile />} />
          <Route path="/payment-success" element={<PaymentSuccess />} />
          <Route path="/payment-problem" element={<PaymentProblem />} />

          {isprescription_map ? (
            <>
              <Route path="/prescription-map" element={<PrescriptionMap />} />   
            </>
          ):(
            <>
            </>
          )}

          {isprescription_map_ai ? (
            <>
              <Route path="/ai-prescription-map" element={<PrescriptionMapAI />} />   
            </>
          ):(
            <>
            </>
          )}         

          {ishave_sensor ? (
            <>
              <Route path="/summary" element={<IoTSummary />} />
            </>
          ):(
            <>
            </>
          )}
          {ishave_sensor ? (
            <>
              <Route path="/iot-analisys" element={<IoTAnalysis />} />
            </>
          ):(
            <>
            </>
          )}
          {ishave_sensor ? (
            <>
              <Route path="/iot-anomalies" element={<IoTAnomaly />} />
            </>
          ):(
            <>
            </>
          )}         
          {ishave_sensor ? (
            <>
              <Route path="/iot-comparation" element={<IoTComparation />} /> 
            </>
          ):(
            <>
            </>
          )}
          {ishave_sensor ? (
            <>
              <Route path="/iot-table" element={<IoTTable />} /> 
            </>
          ):(
            <>
            </>
          )}
          {ishave_sensor ? (
            <>
              <Route path="/allert" element={<Allert />} />
            </>
          ):(
            <>
            </>
          )}
          {ishave_sensor ? (
            <>
              <Route path="/create-allert" element={<CreateAllert />} />
            </>
          ):(
            <>
            </>
          )}
          {ishave_sensor ? (
            <>
              <Route path="/update-allert/:fieldId" element={<UpdateAllert />} />
            </>
          ):(
            <>
            </>
          )}
          {ishave_sensor ? (
            <>
              <Route path="/delete-allert/:fieldId" element={<DeleteAllert />} />
            </>
          ):(
            <>
            </>
          )}
          {ishave_sensor ? (
            <>
              <Route path="/irrigation/" element={<Irrigation />} />
            </>
          ):(
            <>
            </>
          )}          
          {issoil_data ? (
            <>
              <Route path="/nutrient-balance" element={<NutrientBalance />} />
            </>
          ):(
            <>
            </>
          )}
          {issoil_data ? (
            <>
              <Route path="/soil-data" element={<SoilData />} />
            </>
          ):(
            <>
            </>
          )}
          {iscrop_models ? (
            <>
              <Route path="/crop-models" element={<CropModel />} /> 
            </>
          ):(
            <>
            </>
          )}         
          {issoil_mapping ? (
            <>
              <Route path="/carbon-credits" element={<SoilMapping />} />       
            </>
          ):(
            <>
            </>
          )}          
                    
          {super_user ? (
            <>
              <Route path="/staff-section" element={<StaffSection />} />       
            </>
          ):(
            <>
            </>
          )}      

          {/* {ispest_models ? (
            <>
              <Route path="/pest-model" element={<PestModel />} />
            </>
          ):(
            <>
            </>
          )} */}
        </Routes>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
        
    </>
  );
};

export default Admin;
