import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link  } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import Header from 'components/Headers/Header';
import { Form, Col, Row } from 'react-bootstrap';
import {Container, Alert, Button, Spinner, Card, CardBody, CardHeader, CardFooter} from 'reactstrap'
import axios from 'axios'

function UpdateFertilizer() {
    
    const [loading, setLoading] = useState(true)
    const [loadingsubmit, setLoadingsubmit] = useState(true)
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const [name, setName] = useState('')
    const [type, setType] = useState('')
    const [organic, setOrganic] = useState(false)

    const [nitrogen, setNitrogen] = useState(0)
    const [phosphorus, setPhosphorus] = useState(0)
    const [potassium, setPotassium] = useState(0)

    const [productor, setProductor] = useState('')

    const [crop, setCrop] = useState('')
    const [description, setDescription] = useState('')

    const { fieldId } = useParams()
    const navigate = useNavigate()
  
    const token = useSelector((state) => state.userLogin.userInfo.token);
  
    // i18n
    const { t, i18n } = useTranslation(); 
  
    useEffect(() => {
        const storedLanguage = localStorage.getItem('selectedLanguageAFS');
        if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
        }
    }, [i18n]);

    useEffect(() => {

        const fetchData = async () => {
  
          try {
    
            const config = {
              headers: {
                  'Content-type': 'application/json',
                  Authorization: `Bearer ${token}`
              }
            }
  
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/company-management/fertilizer/${fieldId}`, config);
            
            console.log(response.data)

            setName(response.data.Name)
            setType(response.data.Type)
            setOrganic(response.data.OrganicApproved)
            setNitrogen(response.data.Azoto)
            setPhosphorus(response.data.Fosforo)
            setPotassium(response.data.Potassio)
            setProductor(response.data.Productor)
            setCrop(response.data.Crop)
            setDescription(response.data.Description)

            
          } catch (error) {
            console.error('Errore durante la richiesta GET:', error);
          } finally {
            setLoading(false)
          }
          
        };
    
        fetchData();
    }, [token, fieldId]);

    const submit = async () => {

        setLoadingsubmit(false);
           
        if (name==='') {
            setMessage(t('Please set the name of the fertilizer'))
            setError(true)
            setTimeout(function() {
                setError(false)
            }, 5000);
            setLoadingsubmit(true);
            return;
        }

        if (type==='') {
            setMessage(t('Please set the type of fertilizer'))
            setError(true)
            setTimeout(function() {
            setError(false)
            }, 5000);
            setLoadingsubmit(true);
            return;
        }
        
        if (productor==='') {
            setMessage(t('Please set the productor name'))
            setError(true)
            setTimeout(function() {
                setError(false)
            }, 5000);
            setLoadingsubmit(true);
            return;
        }
        
        if (crop==='') {
            setMessage(t('Please set the crop name'))
            setError(true)
            setTimeout(function() {
                setError(false)
            }, 5000);
            setLoadingsubmit(true);
            return;
        }

                if (nitrogen==='') {
            setMessage(t('Please set the nitrogen'))
            setError(true)
            setTimeout(function() {
                setError(false)
            }, 5000);
            setLoadingsubmit(true);
            return;
        }

        if (potassium==='') {
            setMessage(t('Please set the potassium'))
            setError(true)
            setTimeout(function() {
                setError(false)
            }, 5000);
            setLoadingsubmit(true);
            return;
        }

        if (phosphorus==='') {
            setMessage(t('Please set the phosphorus'))
            setError(true)
            setTimeout(function() {
                setError(false)
            }, 5000);
            setLoadingsubmit(true);
            return;
        }
    
        try {

            const payload = {
              Name: name,
              MacroType: "Nutrizione",
              Type: type,
              OrganicApproved: organic,
              Azoto: nitrogen,
              Fosforo: phosphorus,
              Potassio: potassium,
              Productor: productor,
              Crop: crop,
              Description: description
            };
        
            const config = {
              headers: {
                  'Content-type': 'application/json',
                  Authorization: `Bearer ${token}`
              }
            }   
                
            axios.put(`${process.env.REACT_APP_BACKEND_API}/company-management/update-fertilizer/${fieldId}/`, payload, config)
    
        } catch (error) {
          console.log(error)
          setMessage(t('An error occured while creating the field. If the problem persiste please send us an email automaticfarmsolution@gmail.com'))
          setError(true)
          setTimeout(function() {
              setError(false)
          }, 5000);
          setLoadingsubmit(true);
          return;
    
        } finally {
          setLoadingsubmit(true);
          navigate('/fertilizer/');
        }
        
    };

    return (
        <div>
            <Header/>
            {loading ? (
            <>
                <Container className="mt--15" fluid>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                    <Spinner>
                        {t('Loading')}
                    </Spinner>
                    </Col>
                </Row>
                </Container>
            </>
            ):( 
                <>
                    <Container className="mt--15" fluid>
                        <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                            <Card>
                                <CardHeader>
                                    <h3>{t('Edit Fertilizer')}</h3>
                                </CardHeader>
                                <CardBody>
                                    <Form.Label>{t('Commercial Product Name:')}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={t('Commercial Product Name:')}
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}>
                                    </Form.Control>
                                    <br></br>
                                    <Form.Label>{t('Product Type:')}</Form.Label>
                                    <Form.Control
                                        as="select"
                                        placeholder={t('Product Type:')}
                                        value={type}
                                        onChange={(e) => setType(e.target.value)}>
                                        <option value={""}>

                                        </option>
                                        <option value={"minerale"}>
                                            {t('Mineral Product')}
                                        </option>
                                        <option value={"organico"}>
                                            {t('Organic Product')}
                                        </option>
                                    </Form.Control>
                                    <br></br>
                                    <Form.Label>{t('Can be used in organic farming production?')}</Form.Label>
                                    <Form.Check
                                        type={"checkbox"}
                                        value={organic}
                                        onClick={(e) => {
                                            setOrganic(e.target.checked);
                                        }}/>
                                    <br></br>
                                    <Row>
                                        <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} className="text-center mt-5">
                                            <Form.Label>{t('Nitrogen Percentange')}</Form.Label>
                                            <Form.Control
                                                type="numeric"
                                                placeholder={t('Nitrogen Percentange')}
                                                value={nitrogen}
                                                onChange={(e) => setNitrogen(e.target.value)}>
                                            </Form.Control>
                                        </Col>
                                        <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} className="text-center mt-5">
                                            <Form.Label>{t('Phosphorus Percentange')}</Form.Label>
                                            <Form.Control
                                                type="numeric"
                                                placeholder={t('Phosphorus Percentange')}
                                                value={phosphorus}
                                                onChange={(e) => setPhosphorus(e.target.value)}>
                                            </Form.Control>
                                        </Col>
                                        <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} className="text-center mt-5">
                                            <Form.Label>{t('Potassium Percentange')}</Form.Label>
                                            <Form.Control
                                                type="numeric"
                                                placeholder={t('Potassium Percentange')}
                                                value={potassium}
                                                onChange={(e) => setPotassium(e.target.value)}>
                                            </Form.Control>
                                        </Col>
                                    </Row>
                                    <br></br>
                                    <Form.Label>{t('Productor')}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={t('Productor')}
                                        value={productor}
                                        onChange={(e) => setProductor(e.target.value)}>
                                    </Form.Control>                                
                                    <br></br>
                                    <Form.Label>{t('Crop')}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={t('Crop')}
                                        value={crop}
                                        onChange={(e) => setCrop(e.target.value)}>
                                    </Form.Control>
                                    <br></br>
                                    <Form.Label>{t('Description')}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={t('Description')}
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}>
                                    </Form.Control>
                                    {error &&
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                                            <Alert color="danger" fade={false}>{message}</Alert>
                                        </Col>
                                    }
                                </CardBody>
                                <CardFooter>
                                    <Row>
                                        {loadingsubmit ? (
                                            <>
                                                <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-2">
                                                <Button color="success" onClick={submit}>
                                                    {t('Edit Fertilizer')} <i className="fas fa-plus"></i>
                                                </Button>
                                                </Col>
                                            </>
                                        ) : (
                                            <>
                                                <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-2">
                                                <Button color="success" disabled>
                                                    <Spinner size="sm">
                                                    {t('Editing the Fertilizer...')}
                                                    </Spinner>
                                                    <span>
                                                    {' '}{t('Editing the Fertilizer...')}
                                                    </span>
                                                </Button>
                                                </Col>
                                            </>
                                        )}
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-2">
                                            <Link to="/fertilizer/">
                                                <Button color="dark">
                                                    {t('Go back')} <i className="fas fa-rotate-left"></i>
                                                </Button>
                                            </Link>
                                        </Col>
                                    </Row>
                                </CardFooter>
                            </Card>
                        </Col>
                        </Row>
                    </Container>
                </>
            )}       
        </div>
    )
}

export default UpdateFertilizer
