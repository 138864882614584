import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Table, Card, CardHeader, Button, Spinner } from 'reactstrap';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Header from 'components/Headers/Header';

export default function Allert() {

  const [FarmerData, setFarmerData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [existiot, setexistiot] = useState('');

  const token = useSelector((state) => state.userLogin.userInfo.token);

  ////////////
  const { t, i18n } = useTranslation();  // Destructure i18n from useTranslation

  useEffect(() => {
      const storedLanguage = localStorage.getItem('selectedLanguageAFS');
      if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);  // Use i18n to change language
      }
  }, [i18n]);
  ////////////

  useEffect(() => {
    const fetchData = async () => {
      try {
        const config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        };
  
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/iot/allert`, config);
        setFarmerData(response.data);
      } catch (error) {
        console.error('Error fetching GeoJSON data:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [token]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        };
  
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/iot/exist-iot`, config);
        setexistiot(response.data);
        
      } catch (error) {
        console.error('Error fetching GeoJSON data:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [token]);
  
  return (
    <>
      <Header />
      {loading ? (
        <>
        <Container className="mt--15" fluid>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
              <Spinner>
                {t('Loading')}
              </Spinner>
            </Col>
          </Row>
        </Container>
        </>
      ) : (
        <>  
        <Container className="mt--15" fluid>
          <Row>
              {FarmerData && FarmerData.length > 0 ? (
                <>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-right mt-5">
                    <Link to="/create-allert">
                      <Button color="success" className="ml-auto">
                        {t('Add Allert')} <i className='fa fa-plus'></i>
                      </Button>
                    </Link>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                    <Card className="shadow">
                      <CardHeader className="border-0">
                        <h3 className="mb-0">{t('Allert Registred')}</h3>
                      </CardHeader>
                      <Table
                        className="align-items-center table-flush"
                        responsive
                        >
                        <thead>
                          <tr>
                            <th>{t('Sensor')}</th>
                            <th>{t('Variable')}</th>
                            <th>{t('Condition')}</th>
                            <th>{t('Value')}</th>  
                            <th>{t('Edit')}</th>
                            <th>{t('Delete')}</th>                  
                          </tr>
                        </thead>
                        <tbody>
                          {FarmerData.map((farmer, index) => (
                            <tr key={index}>
                              <td>{farmer.Cabina}</td>
                              <td>{farmer.Variabile}</td>
                              <td>{farmer.Maggiore_Minore}</td>
                              <td>{farmer.Valore}</td> 
                              <td>
                                <Link to={`/update-allert/${farmer.id}`}>
                                  <Button color="warning">{t('Edit')} <i className="fas fa-edit"></i></Button>
                                </Link>
                              </td>
                              <td>
                                <Link to={`/delete-allert/${farmer.id}`}>
                                  <Button color="danger">
                                  {t('Delete')} <i className="fas fa-trash"></i>
                                  </Button>
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Card>
                  </Col>
                </>
                ):(
                  <>
                    {existiot.length === 0 ? (
                      <>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                          <Card className="shadow">
                            <CardHeader className="border-0">
                              <h3 className="mb-0">{t('No IoT is linked to your account')}</h3>
                            </CardHeader>
                            <Table
                              className="align-items-center table-flush"
                              responsive
                              >
                            </Table>
                          </Card>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-right mt-5">
                          <Link to="/create-allert">
                            <Button color="success" className="ml-auto">
                              {t('Add Allert')} <i className='fa fa-plus'></i>
                            </Button>
                          </Link>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                          <Card className="shadow">
                            <CardHeader className="border-0">
                              <h3 className="mb-0">{t('No IoT allert is setted')}</h3>
                            </CardHeader>
                            <Table
                              className="align-items-center table-flush"
                              responsive
                              >
                            </Table>
                          </Card>
                        </Col>
                      </>
                    )}
                  </>        
                )
              }
          </Row>
        </Container>
      </>
      )}
    </>
  );
}
