import React, { useState, useEffect } from 'react'
import Header from 'components/Headers/Header';
import { Button, Col, Row, Container, Card, CardHeader, CardBody, Spinner, CardFooter } from 'reactstrap';
import { useParams, useNavigate, Link  } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import axios from 'axios'
import { MapContainer, TileLayer, LayersControl, GeoJSON, FeatureGroup } from 'react-leaflet';

function DeleteYield() {

  const [loading, setLoading] = useState(true)
  const [loadingsubmit, setLoadingsubmit] = useState(true)

  const [yielddata, setYielddata] = useState([])

  const [fieldData, setfieldData] = useState({
    Field: '',
    Centroid: '',
    Crop: '',
    CropRotation: '',
    OrganicVsConventional: ''
  });

  const token = useSelector((state) => state.userLogin.userInfo.token);

  const { fieldId } = useParams()
  const navigate = useNavigate()

  // i18n
  const { t, i18n } = useTranslation(); 

  useEffect(() => {
      const storedLanguage = localStorage.getItem('selectedLanguageAFS');
      if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
      }
  }, [i18n]);

  useEffect(() => {

    const fetchData = async () => {
      
      try {
        
        const config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        };

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/company-management/yield/${fieldId}`, config);
        
        setYielddata(response.data);
      
      } catch (error) {
        
        console.error('Error during GET request:', error);
      
      } finally {
        
        setLoading(false);

      }
    };

    fetchData();
  }, [token, fieldId]);


  useEffect(() => {
        
    const fetchData = async () => {

      try {

        const config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        }
        
        const responsefield = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/field-selected/${yielddata.FieldName}`, config);
        
        setfieldData({
          Field: responsefield.data,
          Centroid: responsefield.data.properties.centroid,
          Crop: responsefield.data.properties.ActualCrop,
          CropRotation: responsefield.data.properties.CropRotation,
          OrganicVSConventional: responsefield.data.properties.OrganicVSConventional
        });

      } catch (error) {
        console.log(error)
      }
    };

    fetchData(); 
  }, [token, yielddata]);

  const submit = async () => {

    setLoadingsubmit(false)
    
    try {

      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }

      await axios.delete(`${process.env.REACT_APP_BACKEND_API}/company-management/delete-yield/${fieldId}/`, config);

    } catch (error) {
      console.error(`Error deleting field with ID ${fieldId}:`, error);
    } finally {
      setLoadingsubmit(true)
      navigate('/yield/');
    }
  };

  return (
    <div>
      <Header/>
      {loading ? (
        <Container className="mt--15" fluid>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
              <Spinner>
                {t('Loading')}
              </Spinner>
            </Col>
          </Row>
        </Container>
      ):(
        <Container className="mt--15" fluid>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
              <Card>
                <CardHeader>
                  <h3>{t('Are you sure to delete this yield data?')}</h3>
                </CardHeader>
                <CardBody>
                  {fieldData.Field && (
                    <>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <br></br>
                        <MapContainer center={[fieldData.Centroid[1], fieldData.Centroid[0]]} zoom={16} style={{ height: '500px', width: '100%', margin: 0, padding: 0 }} key={JSON.stringify(fieldData.Field)}>
                          <LayersControl position="topright">
                            <LayersControl.BaseLayer checked name="OpenStreetMap">
                              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' />
                            </LayersControl.BaseLayer>
                            <LayersControl.BaseLayer checked name="Satellite">
                              <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" attribution='&copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community' />
                            </LayersControl.BaseLayer>
                          </LayersControl>
                            <FeatureGroup>
                              <GeoJSON      
                                data={{
                                  type: 'Feature',
                                  geometry: {
                                    type: 'Polygon',
                                    coordinates: fieldData.Field.geometry.coordinates,
                                  },
                                  properties: fieldData.Field.properties,
                                }}
                                style={{ fillColor: 'blue', weight: 1, opacity: 1, color: 'blue', fillOpacity: 0.15 }}
                              />
                            </FeatureGroup>
                        </MapContainer>
                        <br></br>
                        <br></br> 
                      </Col>
                    </>
                  )}
                  <br></br>
                  <ul>
                    <li>{t('Farm')} {" "} {yielddata.FarmerName}</li>
                    <li>{t('Field')} {" "} {yielddata.FieldName}</li>
                    <li>{t('Harvest Date')} {" "} {yielddata.DateYield}</li>
                    <li>{t('Operator')} {" "} {yielddata.OperatorName}</li>
                    <li>{t('Crop')} {" "} {yielddata.Crop}</li>
                    <li>{t('Total Quantity')} {" "} {yielddata.TotalQuantity}</li>
                    <li>{t('Price')} {" "} {yielddata.Price}</li>
                    <li>{t('Total Cost')} {" "} {yielddata.TotalCost}</li>
                  </ul>
                </CardBody>
                <CardFooter>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="d-flex justify-content-center">
                    {loadingsubmit ? (
                      <>
                        <Button color="danger" onClick={submit}>
                          {t('Delete Yield')} <i className="fas fa-trash"></i>
                        </Button>
                      </>
                      ) : (
                      <>
                      <Button color="danger" disabled>
                        <Spinner size="sm">
                          {t('Deleting the Yield...')}
                        </Spinner>
                        <span>
                          {' '}{t('Deleting the Yield...')}
                        </span>
                      </Button>
                      </>
                      )
                    }
                    <div className="mx-2"></div>
                    <Link to="/yield/">
                      <Button color="primary">
                        {t('Go back')} <i className="fas fa-rotate-left"></i>
                      </Button>
                    </Link>                                           
                  </Col>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  )
}

export default DeleteYield
