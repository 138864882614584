import React, { useState, useEffect } from 'react'
import Header from 'components/Headers/Header';
import { Button, Col, Row, Container, Card, CardHeader, CardBody, Spinner, CardFooter } from 'reactstrap';
import { useParams, useNavigate, Link  } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import axios from 'axios'

function DeleteOperatorCost() {

  const [loading, setLoading] = useState(true)
  const [loadingsubmit, setLoadingsubmit] = useState(true)

  const [operatorcost, setOperatorcost] = useState([])

  const token = useSelector((state) => state.userLogin.userInfo.token);

  const { fieldId } = useParams()
  const navigate = useNavigate()

  // i18n
  const { t, i18n } = useTranslation(); 

  useEffect(() => {
      const storedLanguage = localStorage.getItem('selectedLanguageAFS');
      if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
      }
  }, [i18n]);

  useEffect(() => {

    const fetchData = async () => {
      
      try {

        const config = {
          headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${token}`
          }
        }

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/company-management/operator-cost/${fieldId}/`, config);
        
        setOperatorcost(response.data)
       
      } catch (error) {

      } finally {
        setLoading(false)
      }

    };

    fetchData();
  }, [fieldId, token]);

  
  const submit = async () => {

    setLoadingsubmit(false)
    
    try {

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }

        await axios.delete(`${process.env.REACT_APP_BACKEND_API}/company-management/delete-operator-cost/${fieldId}/`, config);

    } catch (error) {
        console.error(`Error deleting field with ID ${fieldId}:`, error);
    } finally {
        setLoadingsubmit(true)
        navigate('/operator-cost/');
    }
  };


  return (
    <div>
      <Header/>
      {loading ? (
          <>
            <Container className="mt--15" fluid>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                  <Spinner>
                    {t('Loading')}
                  </Spinner>
                </Col>
              </Row>
            </Container>
          </>
      ):(
        <>
          <Container className="mt--15" fluid>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                <Card>
                  <CardHeader>
                    <h3>{t('Are you sure to delete this operator cost')}</h3>
                  </CardHeader>
                  <CardBody>
                    <br></br>
                    <h3>{t('Operator Cost Detail')}</h3>
                    <ul>
                        <li>{t('Farm')} {operatorcost.FarmerName}</li>
                        <li>{t('Operator')} {operatorcost.OperatorName}</li>
                        <li>{t('Total Cost')} {operatorcost.TotalCost}</li>
                        <li>{t('Date of Hire')} {operatorcost.Date}</li>
                    </ul>
                  </CardBody>
                  <CardFooter>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="d-flex justify-content-center">
                      {loadingsubmit ? (
                        <>
                          <Button color="danger" onClick={submit}>
                            {t('Delete Operator Cost')} <i className="fas fa-trash"></i>
                          </Button>
                        </>
                        ) : (
                        <>
                        <Button color="danger" disabled>
                          <Spinner size="sm">
                            {t('Deleting the Operator Cost...')}
                          </Spinner>
                          <span>
                            {' '}{t('Deleting the Operator Cost...')}
                          </span>
                        </Button>
                        </>
                        )
                      }
                      <div className="mx-2"></div>
                      <Link to="/operator-cost/">
                        <Button color="primary">
                          {t('Go back')} <i className="fas fa-rotate-left"></i>
                        </Button>
                      </Link>                                           
                    </Col>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </div>
  )
}

export default DeleteOperatorCost
