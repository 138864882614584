import React, { useState, useEffect } from 'react'
import Header from 'components/Headers/Header';
import { Button, Col, Row, Container, Card, CardHeader, CardBody, Spinner, CardFooter } from 'reactstrap';
import { useParams, useNavigate, Link  } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import axios from 'axios'

function DeletePesticide() {

  const [loading, setLoading] = useState(true)
  const [loadingsubmit, setLoadingsubmit] = useState(true)

  const [pesticide, setPesticide]=useState('')

  const { fieldId } = useParams()
  const navigate = useNavigate()

  const token = useSelector((state) => state.userLogin.userInfo.token);

  // i18n
  const { t, i18n } = useTranslation(); 

  useEffect(() => {
      const storedLanguage = localStorage.getItem('selectedLanguageAFS');
      if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
      }
  }, [i18n]);

  useEffect(() => {

      const fetchData = async () => {

        try {
  
          const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`
            }
          }

          const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/company-management/pesticide/${fieldId}`, config);
          console.log(response.data)
          setPesticide(response.data)
          
        } catch (error) {
          console.error('Errore durante la richiesta GET:', error);
        } finally {
          setLoading(false)
        }
        
      };
  
      fetchData();
    }, [token, fieldId]);

    const submit = async () => {

      setLoadingsubmit(false)
      
      try {
  
        const config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        }

        await axios.delete(`${process.env.REACT_APP_BACKEND_API}/company-management/delete-pesticide/${fieldId}/`, config);

      } catch (error) {
        console.error(`Error deleting field with ID ${fieldId}:`, error);
      } finally {
        setLoadingsubmit(true)
        navigate('/pesticide/');
      }
    };

  return (
    <div>
      <Header/>
      {loading ? (
        <>
          <Container className="mt--15" fluid>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                <Spinner>
                  {t('Loading')}
                </Spinner>
              </Col>
            </Row>
          </Container>
        </>
      ):(
        <>
          <Container className="mt--15" fluid>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                <Card>
                  <CardHeader>
                    <h3>{t('Are you sure to delete this product?')}</h3>
                  </CardHeader>
                  <CardBody>
                    <p>{t('Commercial Product Name')} {pesticide.CommercialProductName}</p>
                    <p>{t('Macro Type:')} {pesticide.MacroType}</p>
                    <p>{t('Type')} {pesticide.Type}</p>
                    <p>{t('Crop')} {pesticide.Crop}</p>
                    <p>{t('Productor')} {pesticide.Productor}</p>
                    <p>{t('Organic Product')} {pesticide.OrganicApproved ? 'Yes' : 'No'}</p>
                  </CardBody>
                  <CardFooter>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="d-flex justify-content-center">
                      {loadingsubmit ? (
                        <>
                          <Button color="danger" onClick={submit}>
                            {t('Delete Product')} <i className="fas fa-trash"></i>
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button color="danger" disabled>
                            <Spinner size="sm">
                              {t('Deleting the Product...')}
                            </Spinner>
                            <span>
                              {' '}{t('Deleting the Product...')}
                            </span>
                          </Button>
                        </>
                      )}
                      <div className="mx-2"></div>
                      <Link to="/pesticide/">
                        <Button color="primary">
                          {t('Go back')} <i className="fas fa-rotate-left"></i>
                        </Button>
                      </Link>                                           
                    </Col>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </div>
  )
}

export default DeletePesticide
