// reactstrap components
import { Container, Row } from "reactstrap";

const Login = () => {
  return (
    <>
      <footer>
        <Container>
          <Row className="align-items-center justify-content-xl-between">
            {/* <Col xl="12">
              <div>
                © {new Date().getFullYear()}{" "}
                <a
                  className="font-weight-bold ml-1"
                  href="https://www.automaticfarmsolution.com/"
                >
                  Automatic Farm Solution
                </a>
              </div>
            </Col> */}
          </Row>
          <br></br>
        </Container>
      </footer>
    </>
  );
};

export default Login;
