import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'; 
import axios from 'axios';
import Header from 'components/Headers/Header';
import StripeCheckout from 'components/stripe/StripeCheckout';
import { Container, Row, Col, Card, CardBody, CardHeader, CardFooter, Button } from "reactstrap";
import { useTranslation } from 'react-i18next';
import {Link} from 'react-router-dom'

function UpgradeYourProfile() {
    
    const [hectares, setHectares] = useState(83200);
    const token = useSelector((state) => state.userLogin.userInfo.token);
    const farmerPro = useSelector((state) => state.userLogin.userInfo.prescription_map_AI);
    
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const storedLanguage = localStorage.getItem('selectedLanguageAFS');
        if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
        }
    }, [i18n]);

    // useEffect(() => {
    //     const fetchHectares = async () => {
    //         try {
    //             const config = {
    //                 headers: {
    //                     'Content-type': 'application/json',
    //                     Authorization: `Bearer ${token}`
    //                 }
    //             };
    //             const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/dashboard/field-hectares/`, config);
    //             // setHectares(response.data.area_hectares);
    //         } catch (error) {
    //             console.error('Error fetching hectares:', error);
    //         }
    //     };
    //     fetchHectares(); 
    // }, [token]);

    return (
        <div>
            <Header/>
            {farmerPro ? (
                <>
                    <Container className="mt--15" fluid>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                                <Card>
                                    <CardHeader>
                                        <h3>{t('Your Account is PRO')}</h3>
                                    </CardHeader>
                                    <CardBody>
                                        <h3>{t('You can access all the services')}</h3>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </>
            ) : (
                <>  
                    <Container className="mt--15" fluid>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                                <Card>
                                    <CardHeader>
                                        <h3>{t('Your Account is not PRO')}</h3>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}  style={{ textAlign: 'left' }}>
                                                <h3>{t('Purchase the PRO Service')}</h3>
                                                <br></br>
                                                <h3>{t('The total price is 832 € (VAT Included)')}</h3>
                                                <br></br>
                                                <h5>{t('By purchasing this service you will access:')}</h5>
                                                <ul>
                                                    <li>{t('All the modules of free version')}</li>
                                                    <li>{t('Generation of prescription map with Artificial Intelligence')}</li>
                                                    <li>{t('Prescription maps that can be integrated with your display tractors')}</li>
                                                    <li>{t('Capability to connect IoT Sensors')}</li>
                                                    <li>{t('Visualization & Analysis of sensor data')}</li>
                                                    <li>{t('Set automatic alerts')}</li>
                                                    <li>{t('All the agronomic tools')}</li>
                                                    <li>{t('Artificial Intelligence Digital Soil Mapping')}</li>
                                                    <li>{t('Advanced Report & Documentation')}</li>
                                                    <li>{t('Online Live Training of the Platform')}</li>
                                                </ul>
                                            </Col>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                                                <StripeCheckout hectares={hectares} />
                                            </Col>
                                        </Row>                                    
                                    </CardBody>
                                    <CardFooter>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-2">
                                                <Link to="/user-profile/">
                                                    <Button color="dark">
                                                        {t('Go back')} <i className="fas fa-rotate-left"></i>
                                                    </Button>
                                                </Link>
                                            </Col>
                                        </Row>
                                        
                                    </CardFooter>
                                </Card> 
                            </Col>
                        </Row>
                    </Container>
                </>
            )}
        </div>
    );
}

export default UpgradeYourProfile;
