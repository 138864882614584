import React, { useState, useEffect } from 'react'
import Header from 'components/Headers/Header';
import { Button, Col, Row, Container, Card, CardHeader, CardBody, Spinner, CardFooter } from 'reactstrap';
import { useParams, useNavigate, Link  } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import axios from 'axios'

function DeleteOperator() {

  const [loading, setLoading] = useState(true)
  const [loadingsubmit, setLoadingsubmit] = useState(true)

  const [name, setName]=useState('')
  const [lastname, setLastname] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')

  const { fieldId } = useParams()
  const navigate = useNavigate()

  const token = useSelector((state) => state.userLogin.userInfo.token);

  // i18n
  const { t, i18n } = useTranslation(); 

  useEffect(() => {
      const storedLanguage = localStorage.getItem('selectedLanguageAFS');
      if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
      }
  }, [i18n]);

  useEffect(() => {
    const fetchData = async () => {
      
      try {

        const config = {
          headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${token}`
          }
        }

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/company-management/operator/${fieldId}/`, config);
        
        setName(response.data.Name)
        setLastname(response.data.Cognome)
        setEmail(response.data.Email)
        setPhone(response.data.Phone)
      
      } catch (error) {
        // setMessage(t('Something went wrong. Please refresh the page and if the problem persists, please send us an email at automaticfarmsolution@gmail.com'))
        // setError(true)
        // setTimeout(function() {
        //     setError(false)
        // }, 5000);
        // setloadingsubmit(false);
        // return;
      } finally {
        setLoading(false)
      }

    };

    fetchData();
  }, [fieldId, token]);

  const deleteOperator = async () => {
    setLoadingsubmit(false)
    
    try {

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }

        await axios.delete(`${process.env.REACT_APP_BACKEND_API}/company-management/delete-operator/${fieldId}/`, config);

    } catch (error) {
        console.error(`Error deleting field with ID ${fieldId}:`, error);
    } finally {
      setLoadingsubmit(true)
      navigate('/operator/');
    }
  };

  return (
    <div>
      <Header/>
      {loading ? (
        <>
          <Container className="mt--15" fluid>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                <Spinner>
                  {t('Loading')}
                </Spinner>
              </Col>
            </Row>
          </Container>
        </>
      ):(
        <>
          <Container className="mt--15" fluid>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                <Card>
                  <CardHeader>
                    <h3>{t('Are you sure to delete this operator?')}</h3>
                  </CardHeader>
                  <CardBody>
                    <ul>
                      <li>{t('Operator Name:')} {name}</li>
                      <li>{t('Operator Last Name:')} {lastname}</li>
                      <li>{t('Operator Email:')} {email}</li>
                      <li>{t('Operator Phone:')} {phone}</li>
                    </ul>
                  </CardBody>
                  <CardFooter>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="d-flex justify-content-center">
                      {loadingsubmit ? (
                        <>
                          <Button color="danger" onClick={deleteOperator}>
                              {t('Delete Operator')} <i className="fas fa-trash"></i>
                          </Button>
                        </>
                      ):(
                        <>
                          <Button color="danger" disabled>
                            <Spinner size="sm">
                                {t('Deleting the Operator...')}
                            </Spinner>
                            <span>
                              {' '}{t('Deleting the Operator...')}
                            </span>
                          </Button>
                        </>
                      )}
                      <Link to="/operator">
                        <Button color="primary">
                            {t('Go back')} <i className="fas fa-rotate-left"></i>
                        </Button>
                      </Link>  
                    </Col>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </div>
  )
}

export default DeleteOperator
