import React, {useEffect, useState} from 'react'
import Header from 'components/Headers/Header';
import {Row, Col, Container, Spinner, Button, Card, CardBody, CardHeader, Table} from 'reactstrap'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {Link} from 'react-router-dom'
import axios from 'axios'

function OperatorCost() {

  const [loading, setLoading] = useState(true)
  const [checkoperator, setcheckoperator] = useState([])
  const [checkfarm, setCheckfarm] = useState([])
  const [operator, setOperator] = useState([])

  const token = useSelector((state) => state.userLogin.userInfo.token);
  
  ////////////
  const { t, i18n } = useTranslation(); 

  useEffect(() => {
      const storedLanguage = localStorage.getItem('selectedLanguageAFS');
      if (storedLanguage) {
      i18n.changeLanguage(storedLanguage); 
      }
  }, [i18n]);
  ////////////

  useEffect(() => {

    const fetchData = async () => {

      try {

        const config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        }

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/company-management/operator/`, config);
        
        setcheckoperator(response.data)    
        
      } catch (error) {
        console.error('Errore durante la richiesta GET:', error);
      } finally{
        setLoading(false)
      }
      
    };

    fetchData();
  }, [token]);

  useEffect(() => {

    const fetchData = async () => {

      try {

        const config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        }

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/farmavailable/`, config);
        console.log(response.data)
        setCheckfarm(response.data)    
        
      } catch (error) {
        console.error('Errore durante la richiesta GET:', error);
      } finally{
        setLoading(false)
      }
      
    };

    fetchData();
  }, [token]);

  useEffect(() => {

    const fetchData = async () => {

      try {

        const config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        }

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/company-management/operator-cost/`, config);
        
        setOperator(response.data)    
        
      } catch (error) {
        console.error('Errore durante la richiesta GET:', error);
      } finally{
        setLoading(false)
      }
      
    };

    fetchData();
  }, [token]);

  return (
    <div>
      <Header/>
      {loading ? (
          <>
              <Container className="mt--15" fluid>
                  <Row>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                          <Spinner>
                              {t('Loading')}
                          </Spinner>
                      </Col>
                  </Row>
              </Container>
          </>
      ):(
          <>
            <Container className="mt--15" fluid>
              <Row>
              {checkfarm && checkfarm.length> 0 ? (
                <>
                  {checkoperator && checkoperator.length > 0 ? (
                    <>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-right mt-5">
                        <Link to="/create-operator-cost">
                          <Button color="success">
                              {t('Create Operator Cost')} <i className='fa fa-plus'></i>
                          </Button>
                        </Link>
                      </Col>
                      {operator && operator.length>0 ? (
                        <>
                          <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                            <Card className="shadow">
                              <CardHeader className="border-0">
                                <h3 className="mb-0">{t('Operator Cost Registered')}</h3>
                              </CardHeader>
                              <CardBody>
                                <Table
                                  className="align-items-center table-flush"
                                  responsive>
                                    <thead>
                                      <tr>
                                        <th>{t('Farm')}</th>
                                        <th>{t('Operator')}</th>
                                        <th>{t('Total Cost')}</th>
                                        <th>{t('Date of Hire')}</th>
                                        <th>{t('Duplicate')}</th>
                                        <th>{t('Edit')}</th>
                                        <th>{t('Delete')}</th>                  
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {operator.map((farmer, index) => (
                                          <tr key={index}>
                                            <td>{farmer.FarmerName}</td>
                                            <td>{farmer.OperatorName}</td>
                                            <td data-tip={`Detailed cost: ${farmer.TotalCost}`}>{farmer.TotalCost}</td>
                                            <td>{farmer.Date}</td>
                                            <td>
                                              <Link to={`/duplicate-operator-cost/${farmer.id}`}>
                                                <Button color="primary">{t('Duplicate')} <i className="fas fa-copy"></i></Button>
                                              </Link>
                                            </td>
                                            <td>
                                              <Link to={`/update-operator-cost/${farmer.id}`}>
                                                <Button color="warning">{t('Edit')} <i className="fas fa-edit"></i></Button>
                                              </Link>
                                            </td>
                                            <td>
                                              <Link to={`/delete-operator-cost/${farmer.id}`}>
                                                <Button color="danger">
                                                    {t('Delete')} <i className="fas fa-trash"></i>
                                                </Button>
                                              </Link>
                                            </td>
                                          </tr>
                                      ))}
                                    </tbody>
                                </Table>
                              </CardBody>
                            </Card>
                          </Col>
                        </>
                      ):null}
                    </>
                  ):(
                    <>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                        <h3>{t('No Operator are available for your account.')}</h3>
                        <br></br>
                        <h3>{t('First you have to create an operator')}</h3>
                        <br></br>
                        <Link to="/create-operator">
                          <Button color="success">
                              {t('Create Operator')} <i className='fa fa-plus'></i>
                          </Button>
                        </Link>
                      </Col>
                    </>
                  )}
                </>
              ):(
                <>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                    <h3>{t('No Farm are available for your account.')}</h3>
                    <br></br>
                    <h3>{t('First you have to create an Farm')}</h3>
                    <br></br>
                    <Link to="/create-farm">
                      <Button color="success">
                        {t('Create Farm')} <i className='fa fa-plus'></i>
                      </Button>
                    </Link>
                  </Col>
                </>
              )}
                
              </Row>
            </Container>
          </>
      )}
    </div>
  )
}

export default OperatorCost
