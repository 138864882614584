// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  CardFooter,
  Alert,
  Spinner,
  FormText
} from "reactstrap";


import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {register} from 'actions/userActions'

import axios from 'axios'

import { useTranslation } from 'react-i18next';
import { useEffect } from "react";

import validator from 'validator';

const Register = () => {
  const [agreement, setAgremment] = useState(false);
  const [agreement1, setAgremment1] = useState(false);
  const [name, setName] = useState("");  
  const [email, setEmail] = useState("");  
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const [emailError, setEmailError] = useState('');

  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch()
  const navigate = useNavigate();

  const validateEmail = (value) => {
    if (validator.isEmail(value)) {
      setEmailError('');
    } else {
      setEmailError(t('Invalid email address'))
    }
  };

  ////////////
  const { t, i18n } = useTranslation();  // Destructure i18n from useTranslation

  useEffect(() => {
      const storedLanguage = localStorage.getItem('selectedLanguageAFS');
      if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);  // Use i18n to change language
      }
  }, [i18n]);

  ////////////

  const handleSetAgremment = () => setAgremment(!agreement);

  const handleSetAgremment1 = () => setAgremment1(!agreement1);

  const handleSubmit  = async (e) => {
    e.preventDefault()
    
    setLoading(false)

    if (agreement===false) {
      setMessage(t('In order to register you should accept the Privacy Policy'))
      setError(true)
      setTimeout(function() {
        setError(false)
      }, 5000);
      setLoading(true)
      return;
    } 

    if (agreement1===false) {
      setMessage(t('In order to register you should accept the Terms And Conditions'))
      setError(true)
      setTimeout(function() {
        setError(false)
      }, 5000);
      setLoading(true)
      return;
    } 

    if (name==='') {
      setMessage(t('In order to register you should provide your name'))
      setError(true)
      setTimeout(function() {
        setError(false)
      }, 5000);
      setLoading(true)
      return;
    } 

    if (email==='') {
      setMessage(t('In order to register you should provide a valid email'))
      setError(true)
      setTimeout(function() {
        setError(false)
      }, 5000);
      setLoading(true)
      return;
    } 

    if (password1 !== password2) {
      setMessage(t("The password don't match"))
      setError(true)
      setTimeout(function() {
        setError(false)
      }, 5000);
      setLoading(true)
      return;
    } 

    // check email is used
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/users/check-email/${email}/`);
      
      if (response.request.status===200) {
          
      } else {
        setMessage(t("The current email is used, please use another one!"))
        setError(true)
        setTimeout(function() {
          setError(false)
        }, 5000);
        setLoading(true)
        return;
      }
    } catch (error) {
      setMessage(t("The current email is used, please use another one!"))
      setError(true)
      setTimeout(function() {
        setError(false)
      }, 5000);
      setLoading(true)
      return;
    }

    try {
      dispatch(register(name, email, password1, navigate))
    } catch (error) {
      setMessage(t("The email is used"))
      setError(true)
      setTimeout(function() {
        setError(false)
      }, 5000);
      setLoading(true)
      return;
    }
    
  }

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <h1>{t("Registration")}</h1>
            </div>
            <div style={{ textAlign: 'center' }}>
              <img
                alt="Automatic Farm Solution"
                src={"https://i.ibb.co/NZ8qZsk/111111.png"}
                width={"200px"}
                height={"200px"}
                style={{ display: 'inline-block' }}
              />
            </div>
            <Form role="form">
              {error && <Alert  color="danger" fade={false}>{message}</Alert >}
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-hat-3" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input 
                    placeholder={t("Name")}
                    type="text"
                    controlid='name'
                    value={name} 
                    onChange={(e) => setName(e.target.value)} />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    controlid='email'
                    value={email} 
                    onChange={(e) => {
                      setEmail(e.target.value);
                      validateEmail(e.target.value);
                    }}/>
                </InputGroup>
                <FormText color="danger">{emailError}</FormText>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                    controlid='password1'
                    value={password1} 
                    onChange={(e) => setPassword1(e.target.value)}/>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder={t("Confirm Password")}
                    type="password"
                    autoComplete="new-password"
                    controlid='password2'
                    value={password2} 
                    onChange={(e) => setPassword2(e.target.value)}/>
                </InputGroup>
              </FormGroup>
              <Row className="my-4">
                <Col xs="12">
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id="customCheckRegister"
                      type="checkbox"
                      onChange={handleSetAgremment}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheckRegister"
                    >
                      <span className="text-muted">
                        {t("I agree with the")} {" "}
                        <a href="https://www.automaticfarmsolution.com/privacy-policy">
                          Privacy Policy
                        </a>
                      </span>
                    </label>
                  </div>
                </Col>
              </Row>
              <Row className="my-4">
                <Col xs="12">
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id="customCheckRegister1"
                      type="checkbox"
                      onChange={handleSetAgremment1}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheckRegister1"
                    >
                      <span className="text-muted">
                        {t("I agree with the")} {" "}
                        <a href="https://www.automaticfarmsolution.com/terms-conditions">
                          Terms & Conditions
                        </a>
                      </span>
                    </label>
                  </div>
                </Col>
              </Row>
              <div className="text-center">
              {loading ? (
                  <>
                    <Button className="my-4" color="primary" type="button" onClick={handleSubmit}>
                      {t("Register")} 
                    </Button>
                  </>
                ) : (
                  <>
                    <Button color="primary" disabled>
                        <Spinner size="sm">
                        {t("Registration...")}
                        </Spinner>
                        <span>
                        {' '}{t("Registration...")}
                        </span>
                    </Button>
                  </>
                )}
              </div>
            </Form>
          </CardBody>
          <CardFooter>
            {t("Already have an account?")}  <a href="/">Login</a>
          </CardFooter>
        </Card>
      </Col>
    </>
  );
};

export default Register;
