import React, { useEffect, useState } from 'react';
import Header from 'components/Headers/Header';
import { Row, Col, Container, Spinner, Button, Card, Alert, CardBody, CardFooter } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';

function StaffSection() {

  const [loading, setLoading] = useState(true)
  const [loadingsubmitting, setLoadingsubmitting] = useState(true)
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const [user, setUser]=useState([])
  const [user1, setUser1] = useState('')
  const [user2, setUser2] = useState('')

  const token = useSelector((state) => state.userLogin.userInfo.token);

  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const storedLanguage = localStorage.getItem('selectedLanguageAFS');
    if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);

  useEffect(() => {

    const fetchData = async () => {

      try {

        const config = {
          headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${token}`
          }
        }

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/users/all-user/`, config);

        setUser(response.data)        

      } catch (error) {
        console.error('Errore durante la richiesta GET:', error);
      } finally{
        setLoading(false)
      }
      
    };

    fetchData();
  }, [token]);

  const getUserId = async (user) => {

    try {

      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }   
      
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/users/user-id/${user}`, config);
      
      return response.data.id;
    
    } catch (error) {
      
      console.error("Error fetching farmer ID:", error);
      
      throw error;

    }
  };

  const Submit = async() => {

    setLoadingsubmitting(false);

    if (user1===user2) {
      setMessage(t('The user are the same'))
      setError(true)
      setTimeout(function() {
        setError(false)
      }, 3000);
      setLoadingsubmitting(true);
      return;
    } 

    try {
        
        const userID1 = await getUserId(user1)
        const userID2 = await getUserId(user2)
      
        const payload = {
          user_origine_id: userID1,
          user_destinazione_id: userID2,
        };
    
        const config = {
          headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${token}`
          }
        }   
            
        axios.post(`${process.env.REACT_APP_BACKEND_API}/users/user-copy-farm/`, payload, config)

    } catch (error) {
        console.log(error)

    } finally {
        setLoadingsubmitting(true);
        //navigate('/');
    }
    
  };

  return (
    <div>
      <Header/>
      {loading ? (
        <>
            <Container className="mt--15" fluid>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                        <Spinner>
                            {t('Loading')}
                        </Spinner>
                    </Col>
                </Row>
            </Container>
        </>
      ):(
        <Container className="mt--15" fluid>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Form.Label>{t('The user to copy FROM')}</Form.Label>
                  <Form.Control
                      as="select"
                      placeholder={t('The user to copy FROM')}
                      value={user1}
                      onChange={(e) => setUser1(e.target.value)}>
                      <option value={""}>
                        
                      </option>
                      {user.map((option, index) => (
                        <option key={index} value={option.email}>
                            {option.email}
                        </option>
                      ))}
                  </Form.Control>
                  <Form.Label>{t('The user to copy TO')}</Form.Label>
                  <Form.Control
                      as="select"
                      placeholder={t('The user to copy TO')}
                      value={user2}
                      onChange={(e) => setUser2(e.target.value)}>
                      <option value={""}>
                        
                      </option>
                      {user.map((option, index) => (
                        <option key={index} value={option.email}>
                            {option.email}
                        </option>
                      ))}
                  </Form.Control>
                  {error &&
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                      <Alert color="danger" fade={false}>{message}</Alert>
                    </Col>
                  }
                </CardBody>
                <CardFooter>
                  <Row>
                    {loadingsubmitting ? (
                        <>
                          <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-2">
                              <Button color="success" onClick={Submit}>
                                  {t('Copy')} <i className="fas fa-plus"></i>
                              </Button>
                          </Col>
                        </>
                    ) : (
                        <>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-2">
                                <Button color="success" disabled>
                                    <Spinner size="sm">
                                        {t('Copy...')}
                                    </Spinner>
                                    <span>
                                        {' '}{t('Copy...')}
                                    </span>
                                </Button>
                            </Col>
                        </>
                    )}
                  </Row>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>        
      )}
    </div>
  )
}

export default StaffSection
