import React, {useEffect, useState} from 'react'
import Header from 'components/Headers/Header';
import {Row, Col, Container, Spinner, Button, Card, CardBody, CardHeader, Table} from 'reactstrap'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {Link} from 'react-router-dom'
import axios from 'axios'

function Pesticide() {

  const [loading, setLoading] = useState(true)
  const [pesticide, setPesticide] = useState([])

  const token = useSelector((state) => state.userLogin.userInfo.token);
  const userid = useSelector((state) => state.userLogin.userInfo.id);
  
  ////////////
  const { t, i18n } = useTranslation(); 

  useEffect(() => {
      const storedLanguage = localStorage.getItem('selectedLanguageAFS');
      if (storedLanguage) {
      i18n.changeLanguage(storedLanguage); 
      }
  }, [i18n]);
  ////////////

  useEffect(() => {

    const fetchData = async () => {

      try {

        const config = {
          headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${token}`
          }
        }

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/company-management/pesticide/`, config);
        
        console.log(response.data)
        
        setPesticide(response.data)
        
      } catch (error) {
        console.error('Errore durante la richiesta GET:', error);
      } finally {
        setLoading(false)
      }
      
    };

    fetchData();

  }, [token]);

  return (
    <div>
      <Header/>
      {loading ? (
        <>
          <Container className="mt--15" fluid>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                <Spinner>
                  {t('Loading')}
                </Spinner>
              </Col>
            </Row>
          </Container>
        </>
      ):(
        <>
          {pesticide && pesticide.length >0 ? (
            <>
              <Container className="mt--15" fluid>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-right mt-5">
                      <Link to="/create-pesticide">
                          <Button color="success">
                              {t('Create Product')} <i className='fa fa-plus'></i>
                          </Button>
                      </Link>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                    <Card className="shadow">
                      <CardHeader className="border-0">
                        <h3 className="mb-0">{t('Product Registred')}</h3>
                      </CardHeader>
                      <CardBody>
                        <Table
                          className="align-items-center table-flush"
                          responsive>
                          <thead>
                            <tr>
                              <th>{t('Macro Type')}</th>
                              <th>{t('Type')}</th>
                              <th>{t('Commercial Product Name')}</th>
                              <th>{t('Productor')}</th>
                              <th>{t('Crop')}</th>
                              <th>{t('Duplicate')}</th> 
                              <th>{t('Edit')}</th>
                              <th>{t('Delete')}</th>                  
                            </tr>
                          </thead>
                          <tbody>
                            {pesticide.map((item, index) => (
                              <tr key={index}>
                                <td>{t(`translation:${item.MacroType}`)}</td>
                                <td>{t(`translation:${item.Type}`)}</td>
                                <td>{item.CommercialProductName}</td>
                                <td>{item.Productor}</td>
                                <td>{item.Crop}</td>
                                <td>
                                  <Link to={`/duplicate-pesticide/${item.id}`}>
                                    <Button color="primary">{t('Duplicate')} <i className="fas fa-copy"></i></Button>
                                  </Link>
                                </td>
                                {item.User_id === userid && (
                                  <>
                                    <td>
                                      <Link to={`/update-pesticide/${item.id}`}>
                                        <Button color="warning">{t('Edit')} <i className="fas fa-edit"></i></Button>
                                      </Link>
                                    </td>
                                    <td>
                                      <Link to={`/delete-pesticide/${item.id}`}>
                                        <Button color="danger">
                                          {t('Delete')} <i className="fas fa-trash"></i>
                                        </Button>
                                      </Link>
                                    </td>
                                  </>
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </>
          ) : (
            <>
              <Container className="mt--15" fluid>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-right mt-5">
                    <Link to="/create-pesticide">
                      <Button color="success">
                        {t('Create Product')} <i className='fa fa-plus'></i>
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Container>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default Pesticide
