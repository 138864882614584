import React, {useEffect, useState} from 'react'
import Header from 'components/Headers/Header';
import {Row, Col, Container, Spinner, Button, Card, CardBody, CardHeader, Table} from 'reactstrap'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {Link} from 'react-router-dom'
import axios from 'axios'

function OtherRevenue() {

  const [loading, setLoading] = useState(true)
  const [checkfarm, setCheckfarm] = useState([])
  const [revenue, setRevenue] = useState([])

  const token = useSelector((state) => state.userLogin.userInfo.token);

  ////////////
  const { t, i18n } = useTranslation(); 

  useEffect(() => {
      const storedLanguage = localStorage.getItem('selectedLanguageAFS');
      if (storedLanguage) {
      i18n.changeLanguage(storedLanguage); 
      }
  }, [i18n]);
  ////////////

  useEffect(() => {

    const fetchData = async () => {

      try {

        const config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        }

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/farmavailable/`, config);
        setCheckfarm(response.data)    
        
      } catch (error) {
        console.error('Errore durante la richiesta GET:', error);
      } finally{
        setLoading(false)
      }
      
    };

    fetchData();
  }, [token]);

  useEffect(() => {

    const fetchData = async () => {

      try {

        const config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        }

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/company-management/other-revenue/`, config);
        console.log(response.data)
        setRevenue(response.data)    
        
      } catch (error) {
        console.error('Errore durante la richiesta GET:', error);
      } finally{
        setLoading(false)
      }
      
    };

    fetchData();
  }, [token]);

  return (
    <div>
      <Header/>
      {loading ? (
          <>
              <Container className="mt--15" fluid>
                  <Row>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                          <Spinner>
                              {t('Loading')}
                          </Spinner>
                      </Col>
                  </Row>
              </Container>
          </>
      ):(
          <>
            <Container className="mt--15" fluid>
              <Row>
              {checkfarm && checkfarm.length> 0 ? (
                  <>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-right mt-5">
                      <Link to="/create-revenue">
                        <Button color="success">
                            {t('Create Revenue')} <i className='fa fa-plus'></i>
                        </Button>
                      </Link>
                    </Col>
                    {revenue && revenue.length>0 ? (
                      <>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                          <Card className="shadow">
                            <CardHeader className="border-0">
                              <h3 className="mb-0">{t('Revenue Registered')}</h3>
                            </CardHeader>
                            <CardBody>
                              <Table
                                className="align-items-center table-flush"
                                responsive>
                                  <thead>
                                    <tr>
                                      <th>{t('Farm')}</th>
                                      <th>{t('Type')}</th>
                                      <th>{t('Total Revenue')}</th>
                                      <th>{t('Date')}</th>
                                      <th>{t('Duplicate')}</th>
                                      <th>{t('Edit')}</th>
                                      <th>{t('Delete')}</th>                  
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {revenue.map((farmer, index) => (
                                        <tr key={index}>
                                          <td>{farmer.FarmerName}</td>
                                          <td>{t(`translation:${farmer.Type}`)}</td>
                                          <td>{farmer.TotalRevenue}</td>
                                          <td>{farmer.Date}</td>
                                          <td>
                                            <Link to={`/duplicate-revenue/${farmer.id}`}>
                                              <Button color="primary">{t('Duplicate')} <i className="fas fa-copy"></i></Button>
                                            </Link>
                                          </td>
                                          <td>
                                            <Link to={`/update-revenue/${farmer.id}`}>
                                              <Button color="warning">{t('Edit')} <i className="fas fa-edit"></i></Button>
                                            </Link>
                                          </td>
                                          <td>
                                            <Link to={`/delete-revenue/${farmer.id}`}>
                                              <Button color="danger">
                                                  {t('Delete')} <i className="fas fa-trash"></i>
                                              </Button>
                                            </Link>
                                          </td>
                                        </tr>
                                    ))}
                                  </tbody>
                              </Table>
                            </CardBody>
                          </Card>
                        </Col>
                      </>
                    ):null}
                  </>
                ):(
                  <>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                      <h3>{t('No Farm are available for your account.')}</h3>
                      <br></br>
                      <h3>{t('First you have to create an Farm')}</h3>
                      <br></br>
                      <Link to="/create-farm">
                        <Button color="success">
                            {t('Create Farm')} <i className='fa fa-plus'></i>
                        </Button>
                      </Link>
                    </Col>
                  </>
                )}
              </Row>
            </Container>
          </>
      )}
    </div>
  )
}

export default OtherRevenue
