import React, { useEffect, useState } from 'react';
import Header from 'components/Headers/Header';
import { Row, Col, Container, Spinner, Button, Card, CardBody, CardHeader, Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';

function Yield() {

  const [loading, setLoading] = useState(true);
  const [yielddata, setYielddata] = useState([]);
  const [operator, setOperator] = useState([]);
  const [fieldoptions, setFieldoptions]=useState([])

  const token = useSelector((state) => state.userLogin.userInfo.token);

  ////////////
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const storedLanguage = localStorage.getItem('selectedLanguageAFS');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);
  ////////////


  useEffect(() => {
    const fetchData = async () => {
      try {
        const config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        };

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/company-management/operator/`, config);

        setOperator(response.data);

      } catch (error) {
        console.error('Error during GET request:', error);
      }
    };

    fetchData();
  }, [token]);

  useEffect(() => {

    const fetchData = async () => {
      
      try {
        
        const config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        };

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/company-management/yield/`, config);
        
        setYielddata(response.data);
      
      } catch (error) {
        
        console.error('Error during GET request:', error);
      
      } finally {
        
        setLoading(false);

      }
    };

    fetchData();
  }, [token]);

  useEffect(() => {

    const fetchData = async () => {

      try {

        const config = {
          headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${token}`
          }
        }

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/fieldname/`, config);
        
        setFieldoptions(response.data)    
      
      } catch (error) {
        console.error('Errore durante la richiesta GET:', error);
      }
      
    };

    fetchData();
}, [token]);

  return (
    <div>
        <Header/>
        {loading ? (
            <>
              <Container className="mt--15" fluid>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                    <Spinner>
                      {t('Loading')}
                    </Spinner>
                  </Col>
                </Row>
              </Container>
            </>
        ):(
            <>
                <Container className="mt--15" fluid>
                    <Row>
                      {fieldoptions && fieldoptions.length > 0 ? (
                        <>
                          {operator && operator.length > 0 ? (
                            <>
                              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-right mt-5">
                                <Link to="/create-yield">
                                  <Button color="success">
                                    {t('Create Yield data')} <i className='fa fa-plus'></i>
                                  </Button>
                                </Link>
                              </Col>
                              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                                <Card className="shadow">
                                  <CardHeader className="border-0">
                                    <h3 className="mb-0">{t('Yield Data Registred')}</h3>
                                  </CardHeader>
                                  <CardBody>
                                    {yielddata && yielddata.length>0 ? (
                                      <>
                                        <Table
                                          className="align-items-center table-flush"
                                          responsive
                                          >
                                          <thead>
                                            <tr>
                                              <th>{t('Farm')}</th>
                                              <th>{t('Field')}</th>
                                              <th>{t('Operator')}</th>
                                              <th>{t('Harvest Date')}</th>
                                              <th>{t('Crop')}</th>
                                              <th>{t('Total Quantity')}</th>
                                              <th>{t('Price')}</th>
                                              <th>{t('Total Cost')}</th>
                                              <th>{t('Edit')}</th>
                                              <th>{t('Delete')}</th>                  
                                            </tr>
                                          </thead>
                                          <tbody>
                                          {yielddata.map((farmer, index) => (
                                            <tr key={index}>
                                              <td>{farmer.FarmerName}</td>
                                              <td>{farmer.FieldName}</td>
                                              <td>{farmer.OperatorName}</td>
                                              <td>{farmer.DateYield}</td>
                                              <td>{t(`translation:${farmer.Crop}`)}</td>
                                              <td>{farmer.TotalQuantity}</td>
                                              <td>{farmer.Price}</td>
                                              <td>{farmer.TotalCost}</td>
                                            <td>
                                              <Link to={`/update-yield/${farmer.id}`}>
                                                <Button color="warning">{t('Edit')} <i className="fas fa-edit"></i></Button>
                                              </Link>
                                            </td>
                                            <td>
                                              <Link to={`/delete-yield/${farmer.id}`}>
                                                <Button color="danger">
                                                  {t('Delete')} <i className="fas fa-trash"></i>
                                                </Button>
                                              </Link>
                                            </td>
                                            </tr>
                                          ))}
                                          </tbody>
                                        </Table>
                                      </>
                                      ):(
                                      <>
                                        <h3 className="mb-0">{t('No Yield data are registred')}</h3>
                                      </>
                                    )}
                                  </CardBody>
                                </Card>
                              </Col>
                            </>
                          ):(
                            <>
                              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                                <h3>{t('No Operator exist for your account')}</h3>
                                <h3>{t('If you want to register an Yield you should create first a Operator')}</h3>
                              </Col>
                              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">                                                
                                <Link to="/create-operator">
                                  <Button color="success">
                                    {t('Create Operator')}
                                  </Button>
                                </Link>
                              </Col>  
                            </>
                          )}
                        </>
                      ):(
                        <>
                          <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                            <h3>{t('No Field exist for your account')}</h3>
                            <h3>{t('If you want to register an Yield you should create first a Field')}</h3>
                          </Col>
                          <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">                                                
                            <Link to="/create-field">
                              <Button color="success">
                                {t('Create Field')}
                              </Button>
                            </Link>
                          </Col>                        
                        </>
                      )}
                    </Row>
                </Container>
            </>
        )}
    </div>
)
}

export default Yield;
