import React, { useState, useEffect } from 'react';
import Header from 'components/Headers/Header';
import { useSelector } from 'react-redux'; 
import axios from 'axios'
import {Spinner, Container, Row, Col, Card, CardHeader, CardBody} from 'reactstrap'
import { MapContainer, TileLayer, Marker, LayersControl, Popup } from 'react-leaflet';

import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerIconShadow from 'leaflet/dist/images/marker-shadow.png';
import L from 'leaflet'; // Import leaflet library

import { format } from 'date-fns';

import { useTranslation } from 'react-i18next';

function IoTSummary() {
    
    const [iot, setiot] = useState([]);
    const [iotposition, setiotposition] = useState([]);
    const [loadingiot, setloadingiot] = useState(true)

    const token = useSelector((state) => state.userLogin.userInfo.token);

    ////////////
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const storedLanguage = localStorage.getItem('selectedLanguageAFS');
        if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
        }
    }, [i18n]);
    ////////////
    
    const customMarkerIcon = new L.Icon({
        iconUrl: markerIcon,
        shadowUrl: markerIconShadow,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
      });

    useEffect(() => {
      
        const fetchData = async () => {
          try {
    
            const config = {
              headers: {
                  'Content-type': 'application/json',
                  Authorization: `Bearer ${token}`
              }
            }
    
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/iot/iotriepilogo/`, config);
            console.log(response.data)
            setiot(response.data);
            
            const responseposition = await axios.get(`${process.env.REACT_APP_BACKEND_API}/iot/positioniot/`, config);
            
            setiotposition(responseposition.data);
            setloadingiot(false)

          } catch (error) {
            // console.error('Errore durante la richiesta GET:', error);
            // setMessage(t('Something went wrong. If the problem persist please send us an email at automaticfarmsolution@gmail.com'))
            // setError(true)
            // setTimeout(function() {
            //   setError(false)
            // }, 5000);
            // return;
          }
        };
    
        fetchData();
      }, [token]);

    return (
        <div>
        <Header />
        {loadingiot ? (
          <Container className="mt--15" fluid>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                <Spinner>{t('Loading')}</Spinner>
              </Col>
            </Row>
          </Container>
        ) : (
          <Container className="mt--15" fluid>
            <Row>
              {iot.data !== "false" ? (
                iot.data.map((iotFeature, index) => (
                  <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-5" key={index}>
                    <Card>
                        <CardHeader>
                            <h3>{t('IoT Number')} {index + 1}</h3>
                        </CardHeader>
                        <CardBody>
                            <h5>{t('IoT Position')}</h5>
                            <MapContainer center={[iotposition[index].lat, iotposition[index].longi]} zoom={17} style={{ height: '400px', width: '100%' }}>
                                <LayersControl position="topright">
                                    <LayersControl.BaseLayer checked name="OpenStreetMap">
                                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'/>
                                    </LayersControl.BaseLayer>
                                    <LayersControl.BaseLayer checked name="Satellite">
                                    <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"/>        
                                    </LayersControl.BaseLayer>
                                </LayersControl>
                                <Marker position={[iotposition[index].lat, iotposition[index].longi]} icon={customMarkerIcon}>
                                    <Popup>
                                        <h3>{t('IoT Number')} {index + 1}</h3>
                                    </Popup>
                                </Marker>
                            </MapContainer>
                        </CardBody>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                <p>{t('Last Reding')} <i className='fa-solid fa-clock'></i> {format(new Date(iotFeature.Data), 'dd-MM-yyyy HH:mm')}</p>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                                <p>{t('Air Temperature')} <i className='fa-solid fa-temperature-quarter'></i> {iotFeature.Temperatura.toFixed(2)} °C </p>
                                <p>{t('Rainfall')} <i class="fa-solid fa-droplet"></i> {iotFeature.Precipitazione.toFixed(2)} mm</p> 
                                <p>{t('Air Humidity')} <i className='fa-solid fa-cloud'></i> {iotFeature.Umidità_aria.toFixed(2)} %</p> 
                                <p>{t('Evapotraspiration')} <i className='fa-solid fa-leaf'></i> {iotFeature.Evapotraspirazione.toFixed(2)} mm</p>  
                                <p>{t('Degree Day')} <i class="fa-solid fa-thermometer"></i> {iotFeature.Gradi_Giorno.toFixed(2)}</p>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                                <p>{t('Atmospheric Pressure')} <i class="fa-solid fa-map-pin"></i> {iotFeature.Pressione_atmosferica.toFixed(2)} hPa</p>
                                <p>{t('TWH Index')} <i class="fa-solid fa-toilet-portable"></i> {iotFeature.TWH_Index.toFixed(2)}</p> 
                                <p>{t('Dew Point')} <i class="fa-solid fa-map-pin"></i> {iotFeature.Punto_rugiada.toFixed(2)} °C Td</p>                                 
                                <p>{t('Wind')} <i class="fa-solid fa-wind"></i> {iotFeature.Vento.toFixed(2)} km/h</p>
                                <p>{t('Gust')} <i class="fa-solid fa-bolt"></i> {iotFeature.Raffica.toFixed(2)} km/h</p>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                              <p>{t('Soil Humidity')} <i class="fa-solid fa-hand-holding-droplet"></i> {iotFeature.Umidità_Suolo.toFixed(2)} %</p> 
                            </Col>
                        </Row>
                    </Card>
                  </Col>
                ))
              ) : (
                <Col xs={12} className="text-center mt-5">
                  <h3>No Sensors Are Linked to Your Account</h3>
                </Col>
              )}
            </Row>
          </Container>
        )}
      </div>
    )
}

export default IoTSummary
