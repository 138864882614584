// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    background: url('https://i.ibb.co/7t6gSX9/agriculture-1867212-1280.jpg') center center/cover no-repeat;
  }`, "",{"version":3,"sources":["webpack://./src/components/Headers/stylesheadersbackgroundimage.css"],"names":[],"mappings":"AAAA;IACI,sGAAsG;EACxG","sourcesContent":[".header {\r\n    background: url('https://i.ibb.co/7t6gSX9/agriculture-1867212-1280.jpg') center center/cover no-repeat;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
