import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux'
import store from './store'

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translation from './translation'; // Correct the import statement
import App from 'App'

i18n
  .use(initReactI18next)
  .init({
    resources: translation,
    lng: 'en',
    fallbackLng: 'en', 
    interpolation: {
      escapeValue: false,
    },
  });

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <App/>
  </Provider>
);
