import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';

import { useSelector } from 'react-redux'

import { Container, Row, Col, Alert, Spinner, Button, Card, CardBody } from "reactstrap";

import Header from 'components/Headers/Header'

import { useTranslation } from 'react-i18next';

import ReactFlagsSelect from "react-flags-select";

import validator from 'validator'; // Assicurati di installare la libreria validator

import PhoneInput from 'react-phone-input-2';

function FarmCreate() {

    const [FarmerCompanyName, setFarmerCompanyName] = useState('');
    const [Name, setName] = useState('');
    const [Surname, setSurname] = useState('');
    const [City, setCity] = useState('');
    const [Adress, setAdress] = useState('');
    const [Email, setEmail] = useState('');
    const [PhoneNumber, setPhoneNumber] = useState('');
    const [CountryCode, setCountryCode] = useState('');

    const [emailError, setEmailError] = useState('');

    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    const token = useSelector((state) => state.userLogin.userInfo.token);
    const userId = useSelector((state) => state.userLogin.userInfo.id);

    ////////////
    const { t, i18n } = useTranslation();  // Destructure i18n from useTranslation

    useEffect(() => {
        const storedLanguage = localStorage.getItem('selectedLanguageAFS');
        if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);  // Use i18n to change language
        }
    }, [i18n]);
    ////////////

    const handleSubmit = () => {

        setLoading(false)

        if (FarmerCompanyName==='') {
            setMessage(t('The farmer company name is empty'))
            setError(true)
            setTimeout(function() {
              setError(false)
            }, 5000);
            setLoading(true)
            return;
          } 

          if (Name==='') {
            setMessage(t('The Name of the farmer is empty'))
            setError(true)
            setTimeout(function() {
              setError(false)
            }, 5000);
            setLoading(true)
            return;
          } 

          if (Surname==='') {
            setMessage(t('The Surname of the farmer is empty'))
            setError(true)
            setTimeout(function() {
              setError(false)
            }, 5000);
            setLoading(true)
            return;
          } 

          if (CountryCode==='') {
            setMessage(t('The CountryCode of the farmer is empty'))
            setError(true)
            setTimeout(function() {
              setError(false)
            }, 5000);
            setLoading(true)
            return;
          } 

          if (City==='') {
            setMessage(t('The City of the farmer is empty'))
            setError(true)
            setTimeout(function() {
              setError(false)
            }, 5000);
            setLoading(true)
            return;
          } 

          if (Adress==='') {
            setMessage(t('The Adress of the farmer is empty'))
            setError(true)
            setTimeout(function() {
              setError(false)
            }, 5000);
            setLoading(true)
            return;
          } 

          if (Email==='') {
            setMessage(t('The Email of the farmer is empty'))
            setError(true)
            setTimeout(function() {
              setError(false)
            }, 5000);
            setLoading(true)
            return;
          }

          if (PhoneNumber==='') {
            setMessage(t('The Phone Number of the farmer is empty'))
            setError(true)
            setTimeout(function() {
              setError(false)
            }, 5000);
            setLoading(true)
            return;
          } 

        const formattedPhone = '+' + PhoneNumber;
        
        const payload = {
            NameFarmer: FarmerCompanyName,
            Name: Name,
            Surname: Surname,
            Country: CountryCode,
            City: City,            
            Adress: Adress,
            Email: Email,
            PhoneNumber: formattedPhone,
            User: userId
        };
    
        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }

        try {
            axios.post(`${process.env.REACT_APP_BACKEND_API}/farm/create/`, payload, config)
                .then(response => {
                })
                .catch(error => {
                    console.error('Error creating field:', error.request.response);
                })
                .finally(() => {
                    setLoading(true)
                    navigate('/farm/');                    
                });
        } catch (error) {
            setMessage(t('An error occured during the creation of the farmer. If the problem persist please send us an email at automaticfarmsolution@gmail.com'))
            setError(true)
            setTimeout(function() {
              setError(false)
            }, 5000);
            setLoading(true)
            return;
        }
    };

    const handleRestart = () => {
        window.location.reload();
      };

    const validateEmail = (value) => {
      if (validator.isEmail(value)) {
        setEmailError('');
      } else {
        setEmailError(t('Invalid email address'))
      }
    };
  
    return (
        <div>
            <Header />
            <br></br>
            <Container className="mt--15" fluid>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                    <h3>{t('Register a new farmer')}</h3>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-5">
                    <Card>
                      <CardBody>
                        <Form>
                            <Form.Group controlId="formCreateFarmer">
                                <Form.Label>{t('Farmer Company Name:')}</Form.Label>
                                <Form.Control type="text" placeholder={t('Farmer Company Name:')} value={FarmerCompanyName} onChange={(e) => setFarmerCompanyName(e.target.value)}/>
                                <br></br>
                                <Form.Label>{t('Farmer First Name:')}</Form.Label>
                                <Form.Control type="text" placeholder={t('Farmer Company Name:')} value={Name} onChange={(e) => setName(e.target.value)}/>
                                <br></br>
                                <Form.Label>{t('Farmer Surname:')}</Form.Label>
                                <Form.Control type="text" placeholder={t('Farmer Surname:')} value={Surname} onChange={(e) => setSurname(e.target.value)}/>
                                <br></br>
                                <Form.Label>{t('Farmer Country:')}</Form.Label>
                                <ReactFlagsSelect
                                    onSelect={(countryCode) => {setCountryCode(countryCode)}}
                                    placeholder={t('Farmer Country:')}
                                    fullWidth={true}
                                    selected={CountryCode}
                                    searchable={true}
                                />
                            </Form.Group>
                        </Form>
                      </CardBody>
                    </Card>
                    <br></br>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-5">
                  <Card>
                    <CardBody>
                      <Form>
                          <Form.Label>{t('Farmer City:')}</Form.Label>
                          <Form.Control type="text" placeholder={t('Farmer City:')} value={City} onChange={(e) => setCity(e.target.value)}/>
                          <br></br>
                          <Form.Label>{t('Farmer Adress:')}</Form.Label>
                          <Form.Control type="text" placeholder={t('Farmer Adress:')} value={Adress} onChange={(e) => setAdress(e.target.value)}/>
                          <br></br>
                          <Form.Label>{t('Farmer Email:')}</Form.Label>
                          <Form.Control 
                            type="text" 
                            placeholder={t('Farmer Email:')} 
                            value={Email} 
                            onChange={(e) => {
                              setEmail(e.target.value);
                              validateEmail(e.target.value);
                            }}/>
                          <Form.Text className="text-danger">{emailError}</Form.Text>
                          <br></br>
                          <Form.Label>{t('Operator Phone:')}</Form.Label>
                          <PhoneInput
                              value={PhoneNumber}
                              onChange={(phone) => setPhoneNumber(phone)}/>
                          <br></br>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
                {error && 
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                        <Alert color="danger" fade={false}>{message}</Alert>
                    </Col>                    
                }
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                    <Col className="d-flex justify-content-center">
                        {loading ? (
                            <>
                                <Button color="success" onClick={handleSubmit}>
                                    {t('Create Farm')} <i className="fas fa-plus"></i>
                                </Button>
                            </>
                            ) : (
                            <>
                                <Button color="success" disabled>
                                  <Spinner size="sm">
                                  {t('Creating the farm...')}
                                  </Spinner>
                                  <span>
                                    {' '}{t('Creating the farm...')}
                                  </span>
                                </Button>
                            </>
                            )}
                        <div className="mx-2"></div>
                        <Link to="/farm">
                            <Button color="dark">
                                {t('Go back')} <i className="fas fa-rotate-left"></i>
                            </Button>
                        </Link>
                        <div className="mx-2"></div>
                        <Button color="primary" onClick={handleRestart}>
                                {t('Restart Creation')} <i className="fas fa-rotate-left"></i>
                        </Button>
                    </Col>
                  </Col>
              </Row>
            </Container>
        </div>
    )
}

export default FarmCreate
