import React from 'react'

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { useSelector } from 'react-redux';

function App() {

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    return (
        <Router>
            <Routes>
            {!userInfo ? (
                <>
                    <Route path="*" element={<AuthLayout />} />
                </>
            ) : (
                <>
                    <Route path="*" element={<AdminLayout />} />
                </>
            )}
            </Routes>
        </Router>
    )
}

export default App
