import React, {useState, useEffect} from 'react'
import {Row, Col, Container, Spinner, Button, Card, CardHeader, CardBody, CardFooter, Alert} from 'reactstrap'
import {Form} from 'react-bootstrap'

import Header from 'components/Headers/Header';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux'; 
import axios from 'axios'

export default function ClimaticIndex() {

  const [selectedOption, setSelectedOption] = useState("")
  const [year, setyear] = useState("2000-01-01")
  const [options, setOptions] = useState([]);

  const [message, setMessage] = useState("");
  const [error, setError] = useState("");  

  const [basetemp, setbasetemp] = useState(5)
  const [maxtemp, setmaxtemp] = useState(30)

  const minBaseTemp = -30; 
  const maxBaseTemp = 45;

  const minMaxTemp = -30; 
  const maxMaxTemp = 45; 

  const [gdd, setgdd] = useState('')
  const [huglin, sethuglin] = useState('')
  const [winkler, setwinkler] = useState('')

  const token = useSelector((state) => state.userLogin.userInfo.token);

  const [submitting, setsubmitting] = useState(true)
  const [spinnergdd, setspinnergdd] = useState(false)
  const [spinnerwinkler, setspinnerwinkler] = useState(false)
  const [spinnerhuglin, setspinnerhuglin] = useState(false)

  ////////////

  const { t, i18n } = useTranslation();

  useEffect(() => {
      const storedLanguage = localStorage.getItem('selectedLanguageAFS');
      if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
      }
  }, [i18n]);

  ////////////

  useEffect(() => {

    const fetchData = async () => {
        try {

            const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`
            }
            }
            
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/fieldname/`, config);
            
            setOptions(response.data);

        } catch (error) {
            setMessage(t('Something went wrong. If the problem persist please send us an email at automaticfarmsolution@gmail.com'))
            setError(true)
            setTimeout(function() {
            setError(false)
            }, 5000);
            return;
        }
    };

      fetchData(); 
  }, [token]);

  const submit = async () => {

    setsubmitting(false)
    setspinnergdd(true)
    setspinnerwinkler(true)
    setspinnerhuglin(true)

    let fieldselectedapi;

    if (selectedOption==='') {
      fieldselectedapi=options[0]
    } else {
      fieldselectedapi=selectedOption
    }

    // if (selectedOption==='') {
    //   setMessage("Please select a field")
    //   setspinnergdd(false)
    //   setspinnerwinkler(false)
    //   setspinnerhuglin(false)
    //   setsubmitting(true)
    //   setError(true)
    //   setTimeout(function() {
    //   setError(false)
    //   }, 5000);
    //   return;
    // }

    const config = {
      headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${token}`
      }
    }

    const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/field-selected/${fieldselectedapi}`, config);

    const long=response.data.properties.centroid[0]
    const lat=response.data.properties.centroid[1]

    const responsegdd = await axios.get(`${process.env.REACT_APP_BACKEND_API}/wheater/gdd?long=${long}&lat=${lat}&year=${year}&basetemp=${basetemp}&maxtemp=${maxtemp}`, 
                                        config);
    setgdd(responsegdd.data)

    setspinnergdd(false)

    const responsehuglin = await axios.get(`${process.env.REACT_APP_BACKEND_API}/wheater/hugling?long=${long}&lat=${lat}&year=${year}&basetemp=${basetemp}&maxtemp=${maxtemp}`, 
                                        config);
    sethuglin(responsehuglin.data)
    setspinnerwinkler(false)

    const responsewinkler = await axios.get(`${process.env.REACT_APP_BACKEND_API}/wheater/winkler?long=${long}&lat=${lat}&year=${year}&basetemp=${basetemp}&maxtemp=${maxtemp}`, 
                                        config);
    setwinkler(responsewinkler.data)
    setspinnerhuglin(false)
    
    setsubmitting(true)

  }

  return (
    <div>
      <Header/>
      <Container className="mt--15" fluid>
        <Row>
          <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} className="text-center mt-5">
            <Card className="shadow">
              <CardHeader>
                {t('Set the analysis parameter')}
              </CardHeader>
              <CardBody>
                <Form>
                  <Form.Label>{t('Select the field to analyze')}</Form.Label>
                  <Form.Control 
                    as="select" 
                    onChange={(e) => setSelectedOption(e.target.value)}
                    value={selectedOption}>
                    {options.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                    ))}
                  </Form.Control>
                  <br></br>
                  <Form.Label>{t('Select the start year to analyze')}</Form.Label>
                  <Form.Control 
                    as="select" 
                    onChange={(e) => setyear(e.target.value)}
                    value={year}>
                      <option value={"2000-01-01"}>
                        2000
                      </option>
                      <option value={"2001-01-01"}>
                        2001
                      </option>
                      <option value={"2002-01-01"}>
                        2002
                      </option>
                      <option value={"2003-01-01"}>
                        2003
                      </option>
                      <option value={"2004-01-01"}>
                        2004
                      </option>
                      <option value={"2005-01-01"}>
                        2005
                      </option>
                      <option value={"2006-01-01"}>
                        2006
                      </option>
                      <option value={"2007-01-01"}>
                        2007
                      </option>
                      <option value={"2008-01-01"}>
                        2008
                      </option>
                      <option value={"2009-01-01"}>
                        2009
                      </option>
                      <option value={"2010-01-01"}>
                        2010
                      </option>
                      <option value={"2011-01-01"}>
                        2011
                      </option>
                      <option value={"2012-01-01"}>
                        2012
                      </option>
                      <option value={"2013-01-01"}>
                        2013
                      </option>
                      <option value={"2014-01-01"}>
                        2014
                      </option>
                      <option value={"2015-01-01"}>
                        2015
                      </option>
                      <option value={"2016-01-01"}>
                        2016
                      </option>
                      <option value={"2017-01-01"}>
                        2017
                      </option>
                      <option value={"2018-01-01"}>
                        2018
                      </option>
                      <option value={"2019-01-01"}>
                        2019
                      </option>
                      <option value={"2020-01-01"}>
                        2020
                      </option>
                      <option value={"2021-01-01"}>
                        2021
                      </option>
                      <option value={"2022-01-01"}>
                        2022
                      </option>
                      <option value={"2023-01-01"}>
                        2023
                      </option>
                  </Form.Control>
                  <br></br>
                  <Form.Label>{t('Select the base temperature for the crop')}</Form.Label>
                  <br></br>
                  <Form.Range 
                    type="numeric" 
                    min={minBaseTemp}
                    max={maxBaseTemp}
                    value={basetemp}
                    onChange={(e) => setbasetemp(e.target.value)}
                    >
                  </Form.Range>
                  {basetemp}
                  <br></br>
                  <Form.Label>{t('Select the max temperature for the crop')}</Form.Label>
                  <br></br>
                  <Form.Range 
                    type="numeric" 
                    min={minMaxTemp}
                    max={maxMaxTemp}
                    value={maxtemp}
                    onChange={(e) => setmaxtemp(e.target.value)}
                    >
                  </Form.Range>
                  {maxtemp}
                </Form>
              </CardBody>
              <CardFooter>
                {error && <Alert color="danger" fade={false}>{message}</Alert>}
                {submitting ? (
                  <>
                    <Button color="success" onClick={submit}>
                    {t('Start analysis')}
                    </Button>
                  </>
                ):(
                  <>
                    <Button color="success" disabled>
                        <Spinner size="sm">
                        {t('Loading')}
                        </Spinner>
                        <span>
                        {' '}{t('Loading')}
                        </span>
                    </Button>
                  </>
                )}
              </CardFooter>
            </Card>
          </Col>
          {/* <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-5">
            {spinnergdd ? (
              <>
                <Spinner>
                  Loading
                </Spinner>
              </>
            ):(
              <>
                {gdd && (
                  <Card className="shadow">
                    <CardHeader>
                      Growing Degrees Days
                    </CardHeader>
                    <CardBody>
                      <iframe id="iframe5" width="100%" height="400" srcDoc={gdd}></iframe>
                    </CardBody>
                  </Card>
                )}  
              </>
            )}          
          </Col> */}
          <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} className="text-center mt-5">
            {spinnerwinkler ? (
              <>
                <Spinner>
                  Loading
                </Spinner>
              </>
            ):(
              <>
                {huglin && (
                  <Card className="shadow">
                    <CardHeader>
                      Huglin Index
                    </CardHeader>
                    <CardBody>
                      <iframe id="iframe6" width="100%" height="400" srcDoc={huglin}></iframe>
                    </CardBody>
                  </Card>
                )} 
              </>
            )}

          </Col>
          <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} className="text-center mt-5">
            {spinnerhuglin ? (
              <>
                <Spinner>
                  Loading
                </Spinner>
              </>
            ):(
              <>
                {winkler && (
                  <Card className="shadow">
                    <CardHeader>
                      Winkler Index
                    </CardHeader>
                    <CardBody>
                      <iframe id="iframe7" width="100%" height="400" srcDoc={winkler}></iframe>
                    </CardBody>
                  </Card>
                )} 
              </>
            )}
          </Col>
        </Row>
      </Container>      
    </div>
  )
}
