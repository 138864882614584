import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';

const stripePromise = loadStripe("pk_live_51PGO4mRwdYx22wSzAz07AbGLC7JE1kkynsftEQ9jEgRRwvlKwZWXmjNDyTWAMjoSl1eLm4ixTduYausnbpy5ecuX00UpDnHBXL");

const StripeCheckout = ({ hectares }) => {
    return (
    <Elements stripe={stripePromise} options="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">
      <CheckoutForm hectares={hectares}/>
    </Elements>
    );
};

export default StripeCheckout;
