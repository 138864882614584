import React, {useState, useEffect} from 'react'
import Header from "components/Headers/Header.js";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Form,
    Container,
    Row,
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Input,
    Spinner
  } from "reactstrap";

import {Link, useNavigate} from 'react-router-dom'

import axios from 'axios';
import { useSelector } from 'react-redux'; 
import { useTranslation } from 'react-i18next';
import {useDispatch} from 'react-redux'
import {logout} from 'actions/userActions'

function Profile() {

    const [farmer, setfarmer] = useState('');
    const [fieldnumber, setfieldnumber] = useState('');
    const [hectares, sethectares] = useState('');

    const token = useSelector((state) => state.userLogin.userInfo.token);
    const name = useSelector((state) => state.userLogin.userInfo.name);
    const email = useSelector((state) => state.userLogin.userInfo.email);
    const farmerpro = useSelector((state) => state.userLogin.userInfo.prescription_map_AI);

    useEffect(() => {

        const fetchData = async () => {
            try {
    
                const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
                }
                
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/dashboard/number-farmer/`, config);
                
                setfarmer(response.data.number_of_farmers); // Assicurati che la risposta contenga l'array di opzioni
    
            } catch (error) {
            }
        };
    
          fetchData(); 
      }, [token]);
    
    useEffect(() => {
    
        const fetchData = async () => {
            try {
    
                const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
                }
                
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/dashboard/number-field/`, config);
                
                setfieldnumber(response.data.number_of_field); // Assicurati che la risposta contenga l'array di opzioni
    
            } catch (error) {
            }
        };
    
          fetchData(); 
    }, [token]);
    
    useEffect(() => {
    
        const fetchData = async () => {
            try {
    
                const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
                }
                
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/dashboard/field-hectares/`, config);
                
                sethectares(response.data.area_hectares); // Assicurati che la risposta contenga l'array di opzioni
    
            } catch (error) {
            }
        };
    
          fetchData(); 
    }, [token]);

    ////////////

    const { t, i18n } = useTranslation();

    useEffect(() => {
        const storedLanguage = localStorage.getItem('selectedLanguageAFS');
        if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
        }
    }, [i18n]);

    ////////////

    const usedispatch = useDispatch()

    const navigate = useNavigate()

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const [emailinput, setEmailinput] = useState('')
    const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(true);

    const [submittingdeletion, setSubmittingdeletion] = useState(true);

    const checkEmail = () => {
        if (emailinput === email) {
            setDeleteButtonDisabled(false); // Abilita il pulsante se l'email è corretta
        } else {
            setDeleteButtonDisabled(true); // Disabilita il pulsante se l'email non è corretta
        }
    };

    useEffect(() => {
        checkEmail(); // Chiama la funzione di controllo dell'email ad ogni cambio di emailinput
    }, [emailinput]); // Esegui questa useEffect ogni volta che emailinput cambia

    const deleteaccount = async (e) => {

        setSubmittingdeletion(false)

        try {

            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
              }

            await axios.delete(`${process.env.REACT_APP_BACKEND_API}/users/profile/delete/`, config)

        } catch(error) {

            console.log(error)

        } finally {     
            
            setSubmittingdeletion(true)
            setModal(false)
            usedispatch(logout())
            navigate("/")
        }
    };

    return (
        <div>
            <Header />
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
                        <Card className="card-profile shadow">
                            <CardBody className="pt-0 pt-md-4">
                                <Row>
                                <div className="col">
                                    <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                                    <div>
                                        <span className="heading">{farmer}</span>
                                        <span className="description">{t('Number of farmer')}</span>
                                    </div>
                                    <div>
                                        <span className="heading">{fieldnumber}</span>
                                        <span className="description">{t('Number of field')}</span>
                                    </div>
                                    <div>
                                        <span className="heading">{hectares}</span>
                                        <span className="description">{t('Hectares Monitored')}</span>
                                    </div>
                                    </div>
                                </div>
                                </Row>
                                <div className="text-center">
                                    <h3>
                                        {name}
                                    </h3>
                                    <div className="h5 font-weight-300">
                                        <i className="ni location_pin mr-2" />
                                        {email}
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col className="order-xl-1" xl="8">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col xs="8">
                                        <h3 className="mb-0">{t('My account')}</h3>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Form>
                                    <h6 className="heading-small text-muted mb-4">
                                        {t('Account Level:')}
                                    </h6>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col lg="12">
                                                {farmerpro ? (
                                                    <>
                                                        <h3>{t('Your Acccount is PRO')}</h3>
                                                    </>
                                                ):(
                                                    <>
                                                        <h3>{t('Your account is basic')}</h3>
                                                        <br></br>
                                                        <Link to="/upgrade-your-profile/">
                                                            <Button color='primary'>
                                                                {t('Upgrade to PRO')}                                                                
                                                            </Button>
                                                        </Link>
                                                        <br></br>
                                                    </>
                                                )}
                                                <br></br>
                                                <Link to="/support/">
                                                    <Button color='success'>
                                                        {t('Send us a message')}                                                                
                                                    </Button>
                                                </Link>
                                            </Col>                                                                                       
                                            {/* Da qua in avanti
                                            <Col>
                                                <br></br>
                                                <Button color="danger" onClick={toggle}>
                                                    {t('Delete Account')}                                                             
                                                </Button>
                                            </Col> */}
                                        </Row>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                    {/* <Modal isOpen={modal} toggle={toggle}>
                        <ModalHeader toggle={toggle}>
                            <h3>{t('Are you sure to delete your account?')}</h3>
                        </ModalHeader>
                        <ModalBody>
                            <p>{t('Are you sure to delete the account?')}</p>
                            <p><strong>{t('Please be carefull cause if you delete your account all the data related will be removed')}</strong></p>
                            <p>{t('If you want to proceed, please report your email ')} <strong>{email}</strong> {t(' in the following form input')}</p>
                            <Input 
                                placeholder={t("Email")}
                                type="text"
                                value={emailinput} 
                                onChange={(e) => {
                                    setEmailinput(e.target.value);
                                    checkEmail(); // Chiama la funzione di controllo dell'email ad ogni modifica dell'input
                                }} 
                            />
                        </ModalBody>
                        <ModalFooter>
                            {submittingdeletion ? (
                                <>
                                    <Button color="danger" disabled={deleteButtonDisabled} onClick={deleteaccount}>
                                        {t("Delete account")}
                                    </Button>
                                </>
                            ):(
                                <>
                                    <Button color="danger" disabled>
                                        <Spinner size="sm">
                                            {t('Deleting the user...')}
                                        </Spinner>
                                        <span>
                                        {' '}{t('Deleting the user...')}
                                        </span>
                                    </Button>
                                </>
                            )}
                            <Button color="primary" onClick={toggle}>
                                {t('Close')}
                            </Button>
                        </ModalFooter>
                    </Modal> */}
                </Row>
            </Container>            
        </div>
    )
}

export default Profile
