import React, {useState, useEffect} from 'react'
import {Container, Alert, Button, Spinner, Card, CardBody, Col, Row, CardHeader, CardFooter} from 'reactstrap'
import Header from 'components/Headers/Header';
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import axios from 'axios'
import { useParams, useNavigate, Link  } from 'react-router-dom';
import {Form} from 'react-bootstrap'
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';

function UpdateOperator() {
    
    const [loading, setLoading] = useState(true);
    const [loadingsubmit, setLoadingsubmit] = useState(true);
    const [name, setName] = useState('')
    const [lastname, setLastname] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [farmer, setFarmer] = useState('')
    const [optionsfarm, setOptionsfarm] = useState([])

    const token = useSelector((state) => state.userLogin.userInfo.token);
    const userId = useSelector((state) => state.userLogin.userInfo.id);

    const [message, setMessage] = useState("");
    const [error, setError] = useState("");

    const { fieldId } = useParams()
    const navigate = useNavigate()

    // i18n
    const { t, i18n } = useTranslation(); 

    useEffect(() => {
        const storedLanguage = localStorage.getItem('selectedLanguageAFS');
        if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
        }
    }, [i18n]);

    useEffect(() => {

        const fetchData = async () => {
          
            try {

                const config = {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${token}`
                    }
                }

                const responsenew = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/farmavailable/`, config);
            
                const extractedNames = responsenew.data.map(item => item.NameFarmer);
                setOptionsfarm(extractedNames);
        
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/company-management/operator/${fieldId}/`, config);
                
                setName(response.data.Name)
                setLastname(response.data.Cognome)
                setPhone(response.data.Phone)
                setEmail(response.data.Email)
                setFarmer(response.data.FarmerName)
    
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }
        };
    
        fetchData();
    }, [fieldId, token]);

    const getFarmerId = async (farmer) => {

        try {

            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
              }   

            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/farm/${farmer}`, config);
            return response.data;

        } catch (error) {

            console.error("Error fetching farmer ID:", error);
            
        }
    };

    const Submit = async () => {

        setLoadingsubmit(false);

        if (name==='') {
            setMessage(t('The name is empty'))
            setError(true)
            setTimeout(function() {
              setError(false)
            }, 5000);
            setLoadingsubmit(true);
            return;
          } 

        if (lastname==='') {
            setMessage(t('The last name is empty'))
            setError(true)
            setTimeout(function() {
                setError(false)
            }, 5000);
            setLoadingsubmit(true);
            return;
        } 

        
        if (email==='') {
            setMessage(t('The email is empty'))
            setError(true)
            setTimeout(function() {
                setError(false)
            }, 5000);
            setLoadingsubmit(true);
            return;
        } 

        if (phone==='') {
            setMessage(t('The phone is empty'))
            setError(true)
            setTimeout(function() {
                setError(false)
            }, 5000);
            setLoadingsubmit(true);
            return;
        } 

        if (farmer==='') {
            setMessage(t('You must select a farm to associate to the operators'))
            setError(true)
            setTimeout(function() {
                setError(false)
            }, 5000);
            setLoadingsubmit(true);
            return;
        } 

        const formattedPhone = '+' + phone;

        try {

            const farmerId = await getFarmerId(farmer);

            const payload = {
                Name: name,
                Cognome: lastname,
                Email: email,
                Phone: formattedPhone,
                Farm: farmerId,
                User: userId
            };

            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }  

            axios.put(`${process.env.REACT_APP_BACKEND_API}/company-management/update-operator/${fieldId}/`, payload, config)

            setLoadingsubmit(true);
            navigate('/operator/');

        } catch(error) {
            console.log(error)
            setLoadingsubmit(true);
            setMessage(t("The phone number isn't correct"))
            setError(true)
            setTimeout(function() {
                setError(false)
            }, 5000);
            
        }   

    };

    return (
        <div>
        <Header/>
        {loading ? (
            <>
                <Container className="mt--15" fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                            <Spinner>
                            {t('Loading')}
                            </Spinner>
                        </Col>
                    </Row>
                </Container>
            </>
        ):(
            <>
                <Container className="mt--15" fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                            <Card>
                                <CardHeader>
                                    <h3>{t('Update Operator')}</h3>
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                        <Form.Label>{t('Farmer Company Name:')}</Form.Label>
                                        <Form.Control
                                            as="select"
                                            placeholder={t('Farmer Company Name:')}
                                            value={farmer} 
                                            onChange={(e) => setFarmer(e.target.value)}>
                                            {optionsfarm.map((option, index) => (
                                                <option key={index} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </Form.Control>
                                        <br></br>
                                        <Form.Label>{t('Operator Name:')}</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            placeholder={t('Operator Name:')} 
                                            value={name} 
                                            onChange={(e) => setName(e.target.value)}/>
                                        <br></br>
                                        <Form.Label>{t('Operator Last Name:')}</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            placeholder={t('Operator Last Name:')} 
                                            value={lastname} 
                                            onChange={(e) => setLastname(e.target.value)}/>
                                        <br></br>
                                        <Form.Label>{t('Operator Phone:')}</Form.Label>
                                        <PhoneInput
                                            value={phone}
                                            onChange={(phone) => setPhone(phone)}/>
                                        <br></br>
                                        <Form.Label>{t('Operator Email:')}</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            placeholder={t('Operator Email:')} 
                                            value={email} 
                                            onChange={(e) => setEmail(e.target.value)}/>
                                        <br></br>
                                    </Form>
                                    {error && 
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                                            <Alert color="danger" fade={false}>{message}</Alert>
                                        </Col>                    
                                    }
                                </CardBody>
                                <CardFooter>
                                    {loadingsubmit ? (
                                        <>
                                            <Button color="success" onClick={Submit}>
                                                {t('Update Operator')} <i className="fas fa-rotate-left"></i>
                                            </Button>
                                        </>
                                    ):(
                                        <>
                                            <Button color="success" disabled>
                                                <Spinner size="sm">
                                                    {t('Updating Operator...')}
                                                </Spinner>
                                                <span>
                                                    {' '}{t('Updating Operator...')}
                                                </span>
                                            </Button>
                                        </>
                                    )}
                                    <Link to="/operator/">
                                        <Button color="dark">
                                        {t('Go back')} <i className="fas fa-rotate-left"></i>
                                        </Button>
                                    </Link>
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        )}
        </div>
    )
}

export default UpdateOperator
