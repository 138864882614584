import React, {useEffect} from "react";
import { useLocation, Route, Routes} from "react-router-dom";

// reactstrap components
import { Container, Row } from "reactstrap";

// core components
import AuthFooter from "components/Footers/AuthFooter.js";

import Login from "views/examples/Registration-Login-ChangePassword/Login.js";
import Register from "views/examples/Registration-Login-ChangePassword/Register";
import SendEmailRegistration from "views/examples/Registration-Login-ChangePassword/SendEmailRegistration";
import ActivateAccounts from "views/examples/Registration-Login-ChangePassword/ActivateAccounts";
import AccountActivated from "views/examples/Registration-Login-ChangePassword/AccountActivated"
import SendEmailChangePassword from "views/examples/Registration-Login-ChangePassword/SendEmailChangePassword"
import EmailChangePasswordSent from "views/examples/Registration-Login-ChangePassword/EmailChangePasswordSent"
import ChangePassword from "views/examples/Registration-Login-ChangePassword/ChangePassword"
import ChangePasswordConfirm from "views/examples/Registration-Login-ChangePassword/ChangePasswordConfirm"

const Auth = (props) => {
  
  const mainContent = React.useRef(null);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      const backgroundImage = document.querySelector(".main-content");
      backgroundImage.style.backgroundSize = window.innerWidth >= 768 ? "cover" : "contain";
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    document.body.style.backgroundImage = 'url("https://i.ibb.co/7t6gSX9/agriculture-1867212-1280.jpg")';
    document.body.style.backgroundSize = "cover";
    document.body.style.backgroundRepeat = "no-repeat";

    return () => {
      document.body.style.backgroundImage = null;
      document.body.style.backgroundSize = null;
      document.body.style.backgroundRepeat = null;
    };
  }, []);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  return (
    <>
      <div className="main-content" ref={mainContent}>
        <div className="ciao py-3 py-lg-3">
          <Container>
            <div className="header-body text-center mb-7">
              {/* <Row className="justify-content-center">
                <Col lg="5" md="6">
                  <h1 className="text-white">AIBIOSAT</h1>
                  <h3 className="text-white">Automatic Farm Solution</h3>
                  <img
                      alt="AIBIOSAT"
                      src={"https://i.ibb.co/NZ8qZsk/111111.png"}
                      width={"200px"}
                      height={"200px"}
                  />
                </Col>
              </Row> */}
            </div>
          </Container>
          <br></br>
        </div>
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Routes>
              <Route path="/" element={<Login />} />
              
              <Route path="/register" element={<Register />} />
              <Route path="/activate-your-account" element={<SendEmailRegistration/>}  />
              <Route path="/account-activated" element={<AccountActivated/>}  />
              <Route path="/activate-account/:uid/:token" element={<ActivateAccounts/>}  />

              <Route path="/send-email-change-password" element={<SendEmailChangePassword/>}  />
              <Route path="/email-lost-password-sent" element={<EmailChangePasswordSent/>}  />
              <Route path="/change-password-confirmed" element={<ChangePasswordConfirm/>}  />
              <Route path="/change-password/:uid/:token" element={<ChangePassword/>}  />

            </Routes>
          </Row>
        </Container>
      </div>
      <Row className="justify-content-center align-items-center">
        <AuthFooter />
      </Row>      
    </>
  );
};

export default Auth;
