import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import Header from 'components/Headers/Header';
import { useSelector } from 'react-redux'; 
import axios from 'axios'
import {Spinner, Container, Row, Col, Button, Card, CardHeader, CardBody, CardFooter} from 'reactstrap'
import { MapContainer, TileLayer, Marker, LayersControl, Popup } from 'react-leaflet';
import {Form} from 'react-bootstrap'

function Irrigation() {
  
  const [loadingiot, setloadingiot] = useState(true)

  const [loadingsubmit, setloadingsubmit] = useState(true)

  const [selectediot, setselectediot] = useState('');
  const [optionsiot, setoptionsiot] = useState([]);

  const [iotposition, setIotposition] = useState([])

  const [realhistoric, setRealhistoric] = useState('');

  const [htmlContent, setHtmlContent] = useState('');

  const token = useSelector((state) => state.userLogin.userInfo.token);

  ////////////
  const { t, i18n } = useTranslation();

  useEffect(() => {
      const storedLanguage = localStorage.getItem('selectedLanguageAFS');
      if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
      }
  }, [i18n]);
  ////////////

  useEffect(() => {
      
    const fetchData = async () => {
      try {

        const config = {
          headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${token}`
          }
        }
            
        const responseposition = await axios.get(`${process.env.REACT_APP_BACKEND_API}/iot/positioniot/`, config);
        
        setoptionsiot(responseposition.data);
        
      } catch (error) {

      } finally{
        setloadingiot(false)
      }
    };

    fetchData();
  }, [token]);
  
  useEffect(() => {

    const fetchData = async () => {
      try {

        const config = {
          headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${token}`
          }
        }
            
        const responseposition = await axios.get(`${process.env.REACT_APP_BACKEND_API}/iot/positioniot/${selectediot}`, config);
        
        setIotposition(responseposition.data);

      } catch (error) {
      }
    };

    fetchData();
  }, [token, selectediot]);

  const submit = async () => {

    setloadingsubmit(false);

    try {            
      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
      
      if (realhistoric==="historic") {

        if (selectediot==='') {
        
          const response= await axios.get(`${process.env.REACT_APP_BACKEND_API}/iot/irrigation/${optionsiot[0].Cabina}/`, config);
          setHtmlContent(response.data);

        } else {
            
          const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/iot/irrigation/${selectediot}/`, config);
          setHtmlContent(response.data);

        }

      } else {
          
        if (selectediot==='') {
        
          const response= await axios.get(`${process.env.REACT_APP_BACKEND_API}/iot/irrigation-last-24-hour/${optionsiot[0].Cabina}/`, config);
          setHtmlContent(response.data);

        } else {
            
          const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/iot/irrigation-last-24-hour/${selectediot}/`, config);
          setHtmlContent(response.data);

        }

      }

    } catch (error) {  

    } finally {
        setloadingsubmit(true);
    }

       
  };

  return (
    <div>
      <Header/>
      {loadingiot ? (
        <Container className="mt--15" fluid>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
              <Spinner>{t('Loading')}</Spinner>
            </Col>
          </Row>
        </Container>
        ) : (
        <Container className="mt--15" fluid>
          <Row>
            <Col xs={12} sm={12} md={3} lg={3} xl={3} xxl={3} className="text-center mt-5">
              <Card>
                <CardHeader>
                  <h3>{t('Irrigation Analysis')}</h3>
                </CardHeader>
                <CardBody>
                  <Form.Label>{t('Select the IoT Cabin')}</Form.Label>
                  <Form.Control 
                      as="select" 
                      onChange={(e) => setselectediot(e.target.value)}
                      value={selectediot}>
                        {optionsiot.map((option, index) => (
                            <option key={index} value={option.Cabina}>
                                {option.Cabina}
                            </option>
                        ))}
                  </Form.Control>
                  <br></br>
                  {iotposition && iotposition.length>0 ? (
                    <MapContainer center={[iotposition[0].lat, iotposition[0].longi]} zoom={17} style={{ height: '200px', width: '100%' }}>
                      <LayersControl position="topright">
                        <LayersControl.BaseLayer checked name="OpenStreetMap">
                          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                        </LayersControl.BaseLayer>
                        <LayersControl.BaseLayer checked name="Satellite">
                          <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"/>        
                        </LayersControl.BaseLayer>
                      </LayersControl>
                      <Marker position={[iotposition[0].lat, iotposition[0].longi]}>
                        <Popup>
                          <h3>{t('IoT Number')} {iotposition[0].Cabina}</h3>
                        </Popup>
                      </Marker>
                    </MapContainer>
                  ):null}
                  <br></br>
                  <Form.Label>{t('Real time data or Historic data?')}</Form.Label>
                  <Form.Control 
                    as="select" 
                    onChange={(e) => setRealhistoric(e.target.value)}
                    value={realhistoric}>
                    <option value={"realtime"}>
                        {t('Last 24 Hours Data')}
                    </option>
                    <option value={"historic"}>
                        {t('Historic Data')}
                    </option>
                  </Form.Control>
                  <br></br>
                </CardBody>
                <CardFooter>
                  {loadingsubmit ? (
                    <>
                      <Button color='success' onClick={submit}>{t('View data')}</Button>
                    </>
                  ):(
                    <>
                      <Button color="success" disabled>
                        <Spinner size="sm">
                          Loading...
                        </Spinner>
                        <span>
                          {' '}Loading
                        </span>
                      </Button>
                    </>
                  )}
                </CardFooter>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={9} lg={9} xl={9} xxl={9} className="text-center mt-5">
              {htmlContent && (
                <>
                  <Card>
                    <CardHeader>
                      <h3>{t('Time series Graph')}</h3>
                    </CardHeader>
                    <CardBody>
                      <iframe id="iframe11" width="100%" height="500" srcDoc={htmlContent}></iframe>
                    </CardBody>
                  </Card>    
                </>
              )}                            
            </Col>   
          </Row>
        </Container>
      )}
      
    </div>
  )
}

export default Irrigation
