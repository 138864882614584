import React, {useEffect, useState} from 'react'
import Header from 'components/Headers/Header';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { Container, Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
} from '@chatscope/chat-ui-kit-react';

import { useTranslation } from 'react-i18next';

const API_KEY ="sk-proj-jsX6GvzeteHCZMhA5ziQT3BlbkFJvDF1f2iYqxkAla7ov7KH"

function Chatgpt() {

    ////////////
    const { t, i18n } = useTranslation();  // Destructure i18n from useTranslation

    useEffect(() => {
        const storedLanguage = localStorage.getItem('selectedLanguageAFS');
        if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);  // Use i18n to change language
        }
    }, [i18n]);
    ////////////

    const [messages, setMessages] = useState([
        {
          message: t("Hello, I'm your Digital Agronomist Consultant ! Ask me anything!"),
          sentTime: "just now",
          sender: "AIBIOSAT",
        },
      ]);
      const [isTyping, setIsTyping] = useState(false);
    
      const handleSendRequest = async (message) => {
        const newMessage = {
          message,
          direction: 'outgoing',
          sender: "user",
        };
    
        setMessages((prevMessages) => [...prevMessages, newMessage]);
        setIsTyping(true);
    
        try {
          const response = await processMessageToChatGPT([...messages, newMessage]);
          const content = response.choices[0]?.message?.content;
          if (content) {
            const chatGPTResponse = {
              message: content,
              sender: "ChatGPT",
            };
            setMessages((prevMessages) => [...prevMessages, chatGPTResponse]);
          }
        } catch (error) {
          console.error("Error processing message:", error);
        } finally {
          setIsTyping(false);
        }
      };
    
      async function processMessageToChatGPT(chatMessages) {
        const apiMessages = chatMessages.map((messageObject) => {
          const role = messageObject.sender === "ChatGPT" ? "assistant" : "user";
          return { role, content: messageObject.message };
        });
    
        const apiRequestBody = {
          "model": "gpt-3.5-turbo",
          "messages": [
            { role: "system", content: "I'm a Student using ChatGPT for learning" },
            ...apiMessages,
          ],
        };
    
        const response = await fetch("https://api.openai.com/v1/chat/completions", {
          method: "POST",
          headers: {
            "Authorization": "Bearer " + API_KEY,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(apiRequestBody),
        });
    
        return response.json();
      }
    
      return (
        <>
            <Header/>
            <Container className="mt--15" fluid>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                        <Card>
                            <CardHeader>
                                <h3>{t('Digital agronomist consultant')}</h3>
                            </CardHeader>
                            <CardBody>
                                <MainContainer>
                                    <ChatContainer>       
                                        <MessageList 
                                            scrollBehavior="smooth" 
                                            typingIndicator={isTyping ? <TypingIndicator content={t("The digital agronomist consultant is typing")} /> : null}
                                        >
                                            {messages.map((message, i) => {
                                            return <Message key={i} model={message} />
                                            })}
                                        </MessageList>
                                        <MessageInput placeholder={t("Send a Message")} onSend={handleSendRequest} />        
                                    </ChatContainer>
                                </MainContainer>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>

      )
}

export default Chatgpt
