import React, {useEffect} from 'react'
import Header from "components/Headers/Header.js";
import {
    Button,
    Card,
    CardHeader,
    Container,
    CardBody,
    Row,
    Col,
    CardFooter,
  } from "reactstrap";

import {Link} from 'react-router-dom'
import { useTranslation } from 'react-i18next';

function SupportMessageSendSuccess() {

    ////////////

    const { t, i18n } = useTranslation();

    useEffect(() => {
        const storedLanguage = localStorage.getItem('selectedLanguageAFS');
        if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
        }
    }, [i18n]);

    ////////////

    return (
        <div>
            <Header/>
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="text-center mt-5">
                        <Card>
                            <CardHeader>
                                <h3>{t('Your Message have been sent with success')}</h3>
                            </CardHeader>
                            <CardBody>
                                <h3>{t('As soon as possible one of our operator will check your request and answer you')}</h3>
                            </CardBody>
                            <CardFooter>
                                <Link to="/">
                                    <Button color='success'>
                                        {t('Home')}
                                    </Button>
                                </Link>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container>
            
        </div>
    )
}

export default SupportMessageSendSuccess
