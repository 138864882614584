import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import Header from 'components/Headers/Header';
import { useSelector } from 'react-redux'; 
import axios from 'axios'
import {Spinner, Container, Row, Col, Button, Card, CardHeader, CardBody, Table} from 'reactstrap'
import {Form} from 'react-bootstrap'
import { MapContainer, TileLayer, Marker, LayersControl, Popup } from 'react-leaflet';

import * as XLSX from 'xlsx';

function IoTTable() {

    const [loadingiot, setloadingiot] = useState(true)

    const [loadingsubmit, setloadingsubmit] = useState(true)
    const [loadingdownload, setloadingdownload] = useState(true)

    const [selectediot, setselectediot] = useState('');
    const [optionsiot, setoptionsiot] = useState([]);

    const [iotposition, setIotposition] = useState([])
    const [realhistoric, setRealhistoric] = useState('');
    

    const [data, setData] = useState([]);

    const token = useSelector((state) => state.userLogin.userInfo.token);

    useEffect(() => {
      
        const fetchData = async () => {
          try {
    
            const config = {
              headers: {
                  'Content-type': 'application/json',
                  Authorization: `Bearer ${token}`
              }
            }
                
            const responseposition = await axios.get(`${process.env.REACT_APP_BACKEND_API}/iot/positioniot/`, config);
            
            setoptionsiot(responseposition.data);
            setloadingiot(false)

          } catch (error) {
            // console.error('Errore durante la richiesta GET:', error);
            // setMessage(t('Something went wrong. If the problem persist please send us an email at automaticfarmsolution@gmail.com'))
            // setError(true)
            // setTimeout(function() {
            //   setError(false)
            // }, 5000);
            // return;
          }
        };
    
        fetchData();
    }, [token]);

    useEffect(() => {
      
        const fetchData = async () => {
          try {
    
            const config = {
              headers: {
                  'Content-type': 'application/json',
                  Authorization: `Bearer ${token}`
              }
            }
                
            const responseposition = await axios.get(`${process.env.REACT_APP_BACKEND_API}/iot/positioniot/${selectediot}`, config);
            
            setIotposition(responseposition.data);

          } catch (error) {
            // console.error('Errore durante la richiesta GET:', error);
            // setMessage(t('Something went wrong. If the problem persist please send us an email at automaticfarmsolution@gmail.com'))
            // setError(true)
            // setTimeout(function() {
            //   setError(false)
            // }, 5000);
            // return;
          }
        };
    
        fetchData();
    }, [token, selectediot]);



    ////////////
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const storedLanguage = localStorage.getItem('selectedLanguageAFS');
        if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
        }
    }, [i18n]);
    ////////////
    
    const submit = async () => {

        setloadingsubmit(false);

        try {            

            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }

            if (realhistoric==="historic") {
                
                if (selectediot==='') {

                    const response= await axios.get(`${process.env.REACT_APP_BACKEND_API}/iot/iot-table/${optionsiot[0].Cabina}/`, config)
                    
                    const responseData = JSON.parse(response.data);
                    setData(responseData); 

                } else {

                    const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/iot/iot-table/${selectediot}/`, config);
                    
                    const responseData = JSON.parse(response.data);
                    
                    setData(responseData); 

                }  

            } else {

                if (selectediot==='') {

                    const response= await axios.get(`${process.env.REACT_APP_BACKEND_API}/iot/iot-table-last-24-hour/${optionsiot[0].Cabina}/`, config)
                    
                    const responseData = JSON.parse(response.data);
                    setData(responseData); 

                } else {

                    const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/iot/iot-table-last-24-hour/${selectediot}/`, config);
                    
                    const responseData = JSON.parse(response.data);
                    
                    setData(responseData); 

                }  

            }         
          
        } catch (error) {  
            console.log(error)
            // console.error('Errore durante la richiesta GET:', error);
            // setMessage(t('Something went wrong. If the problem persist please send us an email at automaticfarmsolution@gmail.com'))
            // setError(true)
            // setTimeout(function() {
            //   setError(false)
            // }, 5000);
            // return;
        } finally {
            setloadingsubmit(true);
        }
 
    };

    const download = async () => {

        setloadingdownload(false);

        try {            

            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }

            if (realhistoric==="historic") {

                if (selectediot==='') {

                    const response= await axios.get(`${process.env.REACT_APP_BACKEND_API}/iot/iot-table/${optionsiot[0].Cabina}/`, config)

                    const responseData = JSON.parse(response.data);

                    const ws = XLSX.utils.json_to_sheet(responseData);
                    const wb = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
            
                    // Save the file
                    XLSX.writeFile(wb, 'data.xlsx');

                } else {

                    const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/iot/iot-table/${selectediot}/`, config);
                    const responseData = JSON.parse(response.data);

                    const ws = XLSX.utils.json_to_sheet(responseData);
                    const wb = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
            
                    // Save the file
                    XLSX.writeFile(wb, 'data.xlsx');
                    
                }    

            } else {

                if (selectediot==='') {

                    const response= await axios.get(`${process.env.REACT_APP_BACKEND_API}/iot/iot-table-last-24-hour/${optionsiot[0].Cabina}/`, config)

                    const responseData = JSON.parse(response.data);

                    const ws = XLSX.utils.json_to_sheet(responseData);
                    const wb = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
            
                    // Save the file
                    XLSX.writeFile(wb, 'data.xlsx');

                } else {

                    const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/iot/iot-table-last-24-hour/${selectediot}/`, config);
                    const responseData = JSON.parse(response.data);

                    const ws = XLSX.utils.json_to_sheet(responseData);
                    const wb = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
            
                    // Save the file
                    XLSX.writeFile(wb, 'data.xlsx');
                    
                }    

            }

       
          
        } catch (error) {  
            console.log(error)
            // console.error('Errore durante la richiesta GET:', error);
            // setMessage(t('Something went wrong. If the problem persist please send us an email at automaticfarmsolution@gmail.com'))
            // setError(true)
            // setTimeout(function() {
            //   setError(false)
            // }, 5000);
            // return;
        } finally {
            setloadingdownload(true);
        }
 
    };

    
    return (
        <div>
            <Header/>
            {loadingiot ? (
                <Container className="mt--15" fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                            <Spinner>{t('Loading')}</Spinner>
                        </Col>
                    </Row>
                </Container>
                ) : (
                <>
                    <Container className="mt--15" fluid>
                        <Row>
                            <Col xs={12} sm={12} md={3} lg={3} xl={3} xxl={3} className="text-center mt-5">
                                <Card>
                                    <CardHeader>
                                        <h3>{t('Analysis')}</h3>
                                    </CardHeader>
                                    <CardBody>
                                        <Form>
                                            <br></br>
                                            <Form.Label>{t('Select the IoT Cabin')}</Form.Label>
                                            <Form.Control 
                                                as="select" 
                                                onChange={(e) => setselectediot(e.target.value)}
                                                value={selectediot}>
                                                    {optionsiot.map((option, index) => (
                                                        <option key={index} value={option.Cabina}>
                                                            {option.Cabina}
                                                        </option>
                                                    ))}
                                            </Form.Control>
                                            <br></br>
                                            {iotposition && iotposition.length>0 ? (
                                                <MapContainer center={[iotposition[0].lat, iotposition[0].longi]} zoom={17} style={{ height: '200px', width: '100%' }}>
                                                    <LayersControl position="topright">
                                                        <LayersControl.BaseLayer checked name="OpenStreetMap">
                                                            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                                                        </LayersControl.BaseLayer>
                                                        <LayersControl.BaseLayer checked name="Satellite">
                                                            <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"/>        
                                                        </LayersControl.BaseLayer>
                                                    </LayersControl>
                                                    <Marker position={[iotposition[0].lat, iotposition[0].longi]}>
                                                        <Popup>
                                                            <h3>{t('IoT Number')} {iotposition[0].Cabina}</h3>
                                                        </Popup>
                                                    </Marker>
                                                </MapContainer>
                                            ):null}
                                            <br></br>
                                            <Form.Label>{t('Real time data or Historic data?')}</Form.Label>
                                            <Form.Control 
                                                as="select" 
                                                onChange={(e) => setRealhistoric(e.target.value)}
                                                value={realhistoric}>
                                                <option value={"realtime"}>
                                                    {t('Last 24 Hours Data')}
                                                </option>
                                                <option value={"historic"}>
                                                    {t('Historic Data')}
                                                </option>
                                            </Form.Control>
                                            <br></br>
                                            {loadingsubmit ? (
                                                <>
                                                    <Button color='success' onClick={submit}>{t('View data')} <i className='fa fa-eye'></i></Button>
                                                </>
                                            ):(
                                                <>
                                                    <Button color="success" disabled>
                                                        <Spinner size="sm">
                                                            Loading...
                                                        </Spinner>
                                                        <span>
                                                        {' '}Loading
                                                        </span>
                                                    </Button>

                                                </>
                                            )}
                                            <br></br>
                                            <br></br>
                                            {loadingdownload ? (
                                                <>
                                                    <Button color='primary' onClick={download}>{t('Download data')} <i className='fa fa-download'></i></Button>
                                                </>
                                            ):(
                                                <>
                                                    <Button color="primary" disabled>
                                                        <Spinner size="sm">
                                                            Loading...
                                                        </Spinner>
                                                        <span>
                                                        {' '}Loading
                                                        </span>
                                                    </Button>

                                                </>
                                            )}
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xs={12} sm={12} md={9} lg={9} xl={9} xxl={9} className="text-center mt-5">                                
                                {data && (        
                                    <Card>
                                        <CardHeader>
                                        {t('Table')}
                                        </CardHeader>
                                        <CardBody>
                                            <Table hover bordered responsive >
                                                <thead className="thead-light">
                                                <tr>
                                                    <th scope="col">{t('Data')}</th>
                                                    <th scope="col">{t('Air Temperature')} (°C)</th> 
                                                    <th scope="col">{t('Air Humidity')} (%)</th> 
                                                    <th scope="col">{t('Leaf Wetness')} (h)</th>
                                                    <th scope="col">{t('Rainfall')} (mm/h)</th>
                                                    <th scope="col">{t('Soil Humidity')} (%)</th> 
                                                    <th scope="col">{t('Evapotraspiration')} (mm/h)</th> 
                                                    <th scope="col">{t('Degree Days')} (°C)</th> 
                                                    <th scope="col">{t('Atmpsheric Pressure')} (hPa)</th>
                                                    <th scope="col">{t('Wind')} (km/h)</th>
                                                    <th scope="col">{t('Gust')} (km/h)</th> 
                                                    <th scope="col">{t('TWH Index')}</th> 

                                                </tr>
                                                </thead>
                                                <tbody>
                                                {data.map((farmer, index) => (
                                                    <tr key={index}>
                                                        <td>{new Date(farmer.Data).toLocaleString()}</td>
                                                        <td>{farmer.Temperatura.toFixed(1)}</td> 
                                                        <td>{farmer.Umidità_aria.toFixed(1)}</td> 
                                                        <td>{farmer.Bagnatura_Fogliare.toFixed(1)}</td>
                                                        <td>{farmer.Precipitazione.toFixed(1)}</td> 
                                                        <td>{farmer.Umidità_Suolo.toFixed(1)}</td> 
                                                        <td>{farmer.Evapotraspirazione.toFixed(1)}</td>
                                                        <td>{farmer.Gradi_Giorno.toFixed(1)}</td> 
                                                        <td>{farmer.Pressione_atmosferica.toFixed(1)}</td>   
                                                        <td>{farmer.Vento.toFixed(1)}</td>   
                                                        <td>{farmer.Raffica.toFixed(1)}</td>   
                                                        <td>{farmer.TWH_Index.toFixed(1)}</td>   
                                                                                                             
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </Table>
                                        </CardBody>
                                    </Card>                            

                                )}           
                            </Col>                            
                        </Row>
                    </Container>
                </>
            )}
        </div>
    )
}

export default IoTTable
