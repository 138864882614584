import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link  } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import Header from 'components/Headers/Header';
import { Form, Col, Row } from 'react-bootstrap';
import {Container, Alert, Button, Spinner, Card, CardBody, CardHeader, CardFooter} from 'reactstrap'
import axios from 'axios'

function DuplicatePesticide() {

  const [loading, setLoading] = useState(true)
  const [loadingsubmit, setLoadingsubmit] = useState(true)
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  
  const [name, setName] = useState('')
  const [productor, setProductor] = useState('')
  const [registernumber, setRegisternumber] = useState('')
  const [date, setDate] = useState('')
  const [description, setDescription] = useState('')
  const [type, setType] = useState('')
  const [state, setState] = useState('')
  const [organic, setOrganic] = useState(false)
  const [formulation, setFormulation] = useState('')
  const [activesubstance, setActivesubstance] = useState('')
  const [content100grams, setContent100grams] = useState('')
  const [dangerindication, setDangerindication] = useState('')
  const [crop, setCrop] = useState('')
  const [phenological, setPhenological] = useState('')
  const [adversityscientific, setAdversityscientific] = useState('')
  const [adversitycommon, setAdversitycommon] = useState('')
  const [maxdose,setMaxdose]=useState('')
  const [mindose,setMindose]=useState('')
  const [unitdose,setUnitdose]=useState('')
  const [temoodicarenza,setTemoodicarenza]=useState('')
  const [temoodirientro,setTemoodirientro]=useState('')
  const [intervallominfratrattamenti,setIntervallominfratrattamenti]=useState('')
  const [intervallomaxfratrattamenti,setIntervallomaxfratrattamenti]=useState('')
  const [maxtreatmentyear, setMaxtreatmentyear]=useState('')
  const [intervalmassimounit, setIntervalmassimounit]=useState('')
  const [volumeacquamax, setVolumeacquamax]=useState('')
  const [volumeacquamin, setVolumeacquamin]=useState('')

  const { fieldId } = useParams()
  const navigate = useNavigate()

  const token = useSelector((state) => state.userLogin.userInfo.token);

    // i18n
  const { t, i18n } = useTranslation(); 

  useEffect(() => {
      const storedLanguage = localStorage.getItem('selectedLanguageAFS');
      if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
      }
  }, [i18n]);

  useEffect(() => {

    const fetchData = async () => {

      try {

        const config = {
          headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${token}`
          }
        }

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/company-management/pesticide/${fieldId}`, config);
        
        console.log(response.data)

        setName(response.data.CommercialProductName)
        setProductor(response.data.Productor)
        setRegisternumber(response.data.NumberRegister)
        setDate(response.data.DateRegister)
        setDescription(response.data.Description)
        setType(response.data.Type)
        setState(response.data.State)
        setOrganic(response.data.OrganicApproved)
        setFormulation(response.data.Formulazione)
        setActivesubstance(response.data.PrincipiAttivi)
        setContent100grams(response.data.ContenutoPer100Grammi)
        setDangerindication(response.data.IndicazioniDiPericolo)
        setCrop(response.data.Crop)
        setPhenological(response.data.PhenologicalStage)
        setAdversityscientific(response.data.AvversitaNomeScientifico)
        setAdversitycommon(response.data.AvversitaNomeComune)
        setMaxdose(response.data.DoseMax)
        setMindose(response.data.DoseMin)
        setUnitdose(response.data.UnitDose)
        setTemoodicarenza(response.data.Tempodicarenza)
        setTemoodirientro(response.data.TermpoDiRientro)
        setIntervallominfratrattamenti(response.data.IntervalloMinimoFraTrattamenti)
        setIntervallomaxfratrattamenti(response.data.IntervalloMassimoFraTrattamenti)
        setMaxtreatmentyear(response.data.NMaxTreatmentAnnuali)
        setIntervalmassimounit(response.data.IntervalloMaxTrattamenti)
        setVolumeacquamax(response.data.VolumeAcquaMax)
        setVolumeacquamin(response.data.VolumeAcquaMin)
        
      } catch (error) {
        console.error('Errore durante la richiesta GET:', error);
      } finally {
        setLoading(false)
      }
      
    };

    fetchData();
  }, [token, fieldId]);
  

    const submit = async () => {

    setLoadingsubmit(false);
        
    try {

      const payload = {
        CommercialProductName: name,
        Productor: productor,
        NumberRegister: registernumber,
        DateRegister: date,
        Description: description,
        MacroType: "Defence",
        Type:type,
        State:state,
        OrganicApproved:organic,
        Formulazione:formulation,
        PrincipiAttivi:activesubstance,
        ContenutoPer100Grammi:content100grams,
        IndicazioniDiPericolo:dangerindication,
        Crop:crop,
        PhenologicalStage:phenological,
        AvversitaNomeScientifico:adversityscientific,
        AvversitaNomeComune:adversitycommon,
        DoseMax:maxdose,
        DoseMin:mindose,
        UnitDose:unitdose,
        Tempodicarenza:temoodicarenza,
        TermpoDiRientro:temoodirientro,
        IntervalloMinimoFraTrattamenti:intervallominfratrattamenti,
        IntervalloMassimoFraTrattamenti:intervallomaxfratrattamenti,
        NMaxTreatmentAnnuali:maxtreatmentyear,
        IntervalloMaxTrattamenti:intervalmassimounit,
        VolumeAcquaMax:volumeacquamax,
        VolumeAcquaMin:volumeacquamin,
      };
  
      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }   
      
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_API}/company-management/create-pesticide/`, payload, config)
      
      navigate('/pesticide/');

    } catch (error) {
      console.log(error)
      setMessage(t('Please fill all the field'))
      setError(true)
      setTimeout(function() {
          setError(false)
      }, 5000);
      setLoadingsubmit(true);
      return;
    }
    
  };


  return (
    <div>
      <Header/>
      {loading ? (
      <>
          <Container className="mt--15" fluid>
          <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
              <Spinner>
                  {t('Loading')}
              </Spinner>
              </Col>
          </Row>
          </Container>
      </>
      ):( 
        <>
          <Container className="mt--15" fluid>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                <Card>
                  <CardHeader>
                    <h3>{t('Duplicate Product')}</h3>
                  </CardHeader>
                  <CardBody>
                    <Form.Label>{t('Commercial Product Name:')}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t('Commercial  Name:')}
                      value={name}
                      onChange={(e) => setName(e.target.value)}>
                    </Form.Control>
                    <br></br>
                    <Form.Label>{t('Productor')}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t('Productor')}
                      value={productor}
                      onChange={(e) => setProductor(e.target.value)}>
                    </Form.Control>                                
                    <br></br>
                    <Form.Label>{t('Register Number')}</Form.Label>
                    <Form.Control
                      type="numeric"
                      placeholder={t('Register Number')}
                      value={registernumber}
                      onChange={(e) => setRegisternumber(e.target.value)}>
                    </Form.Control>
                    <br></br>
                    <Form.Label>{t('Registration Date')}</Form.Label>
                    <Form.Control
                      type="date"
                      placeholder={t('Registration Date')}
                      value={date}
                      onChange={(e) => setDate(e.target.value)}>
                    </Form.Control>
                    <br></br>
                    <Form.Label>{t('Description')}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t('Description')}
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}>
                    </Form.Control>
                    <br></br>
                    <Form.Label>{t('Product Type:')}</Form.Label>
                    <Form.Control
                      as="select"
                      placeholder={t('Product Type:')}
                      value={type}
                      onChange={(e) => setType(e.target.value)}>
                      <option value={""}>

                      </option>
                      <option value={"fungicida"}>
                        {t('fungicida')}
                      </option>
                      <option value={"diserbante"}>
                        {t('diserbante')}
                      </option>
                      <option value={"insetticida"}>
                        {t('insetticida')}
                      </option>
                      <option value={"acaricida"}>
                        {t('acaricida')}
                      </option>
                      <option value={"aficida"}>
                        {t('aficida')}
                      </option>
                      <option value={"antideriva"}>
                        {t('antideriva')}
                      </option>
                      <option value={"antidoto"}>
                        {t('antidoto')}
                      </option>
                      <option value={"antigermogliante"}>
                        {t('antigermogliante')}
                      </option>
                      <option value={"antiriscaldo"}>
                        {t('antiriscaldo')}
                      </option>
                      <option value={"bagnante"}>
                        {t('bagnante')}
                      </option>
                      <option value={"coaudiuvante"}>
                        {t('coaudiuvante')}
                      </option>
                      <option value={"diradante"}>
                        {t('diradante')}
                      </option>
                      <option value={"feromone"}>
                        {t('feromone')}
                      </option>
                      <option value={"fitoregolatore"}>
                        {t('fitoregolatore')}
                      </option>
                      <option value={"geodisinfestante"}>
                        {t('geodisinfestante')}
                      </option>
                      <option value={"repellente"}>
                        {t('repellente')}
                      </option>
                      <option value={"sinergizzante"}>
                        {t('sinergizzante')}
                      </option>
                      <option value={"adiuvante"}>
                        {t('adiuvante')}
                      </option>
                      <option value={"molluschicida"}>
                        {t('molluschicida')}
                      </option>
                      <option value={"organic_product"}>
                        {t('organic_product')}
                      </option>
                      <option value={"nematocida"}>
                        {t('nematocida')}
                      </option>
                      <option value={"sostanza_di_crescita"}>
                        {t('sostanza_di_crescita')}
                      </option>
                      <option value={"biostimolante"}>
                        {t('biostimolante')}
                      </option>
                    </Form.Control>
                    <br></br>
                    <Form.Label>{t('Active or Retrivied')}</Form.Label>
                    <Form.Control
                      as="select"
                      placeholder={t('Active or Retrivied')}
                      value={state}
                      onChange={(e) => setState(e.target.value)}>
                      <option value={""}>

                      </option>
                      <option value={"ritirato"}>
                        {t('ritirato')}
                      </option>
                      <option value={"attivo"}>
                        {t('attivo')}
                      </option>
                    </Form.Control>
                    <br></br>
                    <Form.Label>{t('Can be used in organic farming production?')}</Form.Label>
                    <Form.Check
                        type={"checkbox"}
                        value={organic}
                        onClick={(e) => {
                            setOrganic(e.target.checked);
                        }}/>
                    <br></br>
                    <Form.Label>{t('Product Formulation:')}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t('Product Formulation:')}
                      value={formulation}
                      onChange={(e) => setFormulation(e.target.value)}>
                    </Form.Control>                                
                    <br></br>
                    <Form.Label>{t('Active substances:')}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t('Active substances:')}
                      value={activesubstance}
                      onChange={(e) => setActivesubstance(e.target.value)}>
                    </Form.Control>
                    <br></br>
                    <Form.Label>{t('Content per 100 grams')}</Form.Label>
                    <Form.Control
                      type="numeric"
                      placeholder={t('Content per 100 grams')}
                      value={content100grams}
                      onChange={(e) => setContent100grams(e.target.value)}>
                    </Form.Control>
                    <br></br>
                    <Form.Label>{t('Danger Indications')}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t('Danger Indications')}
                      value={dangerindication}
                      onChange={(e) => setDangerindication(e.target.value)}>
                    </Form.Control>
                    <br></br>
                    <Form.Label>{t('Crop')}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t('Crop')}
                      value={crop}
                      onChange={(e) => setCrop(e.target.value)}>
                    </Form.Control>
                    <br></br>
                    <Form.Label>{t('Phenological Stage')}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t('Phenological Stage')}
                      value={phenological}
                      onChange={(e) => setPhenological(e.target.value)}>
                    </Form.Control>
                    <br></br>
                    <Form.Label>{t('Scientific name adversity')}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t('Scientific name adversity')}
                      value={adversityscientific}
                      onChange={(e) => setAdversityscientific(e.target.value)}>
                    </Form.Control>
                    <br></br>
                    <Form.Label>{t('Common name adversity')}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t('Common name adversity')}
                      value={adversitycommon}
                      onChange={(e) => setAdversitycommon(e.target.value)}>
                    </Form.Control>
                    <br></br>
                    <Form.Label>{t('Maximum dose')}</Form.Label>
                    <Form.Control
                      type="numeric"
                      placeholder={t('Maximum dose')}
                      value={maxdose}
                      onChange={(e) => setMaxdose(e.target.value)}>
                    </Form.Control>
                    <br></br>
                    <br></br>
                    <Form.Label>{t('Minimum dose')}</Form.Label>
                    <Form.Control
                      type="numeric"
                      placeholder={t('Minimum dose')}
                      value={mindose}
                      onChange={(e) => setMindose(e.target.value)}>
                    </Form.Control>
                    <br></br>
                    <Form.Label>{t('Unit Dose:')}</Form.Label>
                    <Form.Control
                      as="select"
                      placeholder={t('Unit Dose:')}
                      value={unitdose}
                      onChange={(e) => setUnitdose(e.target.value)}>
                      <option value={""}>

                      </option>
                      <option value={"kg_ha"}>
                        {t('Kg / ha')}
                      </option>
                      <option value={"l_ha"}>
                        {t('l / ha')}
                      </option>
                      <option value={"unita_ha"}>
                        {t('Unity / ha')}
                      </option>
                    </Form.Control>
                    <br></br>
                    <Form.Label>{t('Shortage time')}</Form.Label>
                    <Form.Control
                      type="numeric"
                      placeholder={t('Shortage time')}
                      value={temoodicarenza}
                      onChange={(e) => setTemoodicarenza(e.target.value)}>
                    </Form.Control>
                    <br></br>
                    <Form.Label>{t('Return time')}</Form.Label>
                    <Form.Control
                      type="numeric"
                      placeholder={t('Return time')}
                      value={temoodirientro}
                      onChange={(e) => setTemoodirientro(e.target.value)}>
                    </Form.Control>
                    <br></br>
                    <Form.Label>{t('Minimum interval between treatments')}</Form.Label>
                    <Form.Control
                      type="numeric"
                      placeholder={t('Minimum interval between treatments')}
                      value={intervallominfratrattamenti}
                      onChange={(e) => setIntervallominfratrattamenti(e.target.value)}>
                    </Form.Control>
                    <br></br>
                    <Form.Label>{t('Maximum interval between treatments')}</Form.Label>
                    <Form.Control
                      type="numeric"
                      placeholder={t('Maximum interval between treatments')}
                      value={intervallomaxfratrattamenti}
                      onChange={(e) => setIntervallomaxfratrattamenti(e.target.value)}>
                    </Form.Control>
                    <br></br>
                    <Form.Label>{t('Maximum number of treatments')}</Form.Label>
                    <Form.Control
                      type="numeric"
                      placeholder={t('Maximum number of treatments')}
                      value={maxtreatmentyear}
                      onChange={(e) => setMaxtreatmentyear(e.target.value)}>
                    </Form.Control>
                    <br></br>
                    <Form.Label>{t('Unit for maximum number of treatment')}</Form.Label>
                    <Form.Control
                      as="select"
                      placeholder={t('Unit for maximum number of treatment')}
                      value={intervalmassimounit}
                      onChange={(e) => setIntervalmassimounit(e.target.value)}>
                      <option value={""}>

                      </option>
                      <option value={"anno"}>
                        {t('Year')}
                      </option>
                      <option value={"ciclo"}>
                        {t('Cycle')}
                      </option>
                      <option value={"stagione"}>
                        {t('Season')}
                      </option>
                    </Form.Control>
                    <br></br>
                    <Form.Label>{t('Max Volume of water')}</Form.Label>
                    <Form.Control
                      type="numeric"
                      placeholder={t('Max Volume of water')}
                      value={volumeacquamax}
                      onChange={(e) => setVolumeacquamax(e.target.value)}>
                    </Form.Control>
                    <br></br>
                    <Form.Label>{t('Min Volume of water')}</Form.Label>
                    <Form.Control
                      type="numeric"
                      placeholder={t('Min Volume of water')}
                      value={volumeacquamin}
                      onChange={(e) => setVolumeacquamin(e.target.value)}>
                    </Form.Control>
                    {error &&
                      <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                        <Alert color="danger" fade={false}>{message}</Alert>
                      </Col>
                    }
                  </CardBody>
                  <CardFooter>
                    <Row>
                      {loadingsubmit ? (
                        <>
                          <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-2">
                            <Button color="success" onClick={submit}>
                              {t('Create Product')} <i className="fas fa-plus"></i>
                            </Button>
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-2">
                            <Button color="success" disabled>
                              <Spinner size="sm">
                                {t('Creating the Product...')}
                              </Spinner>
                              <span>
                                {' '}{t('Creating the Product...')}
                              </span>
                            </Button>
                          </Col>
                        </>
                      )}
                      <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-2">
                        <Link to="/pesticide/">
                          <Button color="dark">
                            {t('Go back')} <i className="fas fa-rotate-left"></i>
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>   
        </>
      )}
    </div>
  )
}

export default DuplicatePesticide
