import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { MapContainer, TileLayer, GeoJSON, LayersControl } from 'react-leaflet';
import { Button, Row, Col, Spinner, Container, CardBody, CardHeader } from 'reactstrap';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Header from 'components/Headers/Header';
import { useSelector } from 'react-redux';
import axios from 'axios';

function Field() {
    const [geojsonData, setGeojsonData] = useState(null);
    const [centroidData, setCentroidData] = useState([0, 0]);
    const [loading, setLoading] = useState(true);
    const [selectedFarmerName, setSelectedFarmerName] = useState("");
    const mapRef = useRef(null);

    const token = useSelector((state) => state.userLogin.userInfo.token);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const storedLanguage = localStorage.getItem('selectedLanguageAFS');
        if (storedLanguage) {
            i18n.changeLanguage(storedLanguage);
        }
    }, [i18n]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const config = {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${token}`
                    }
                };

                const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/`, config);
                setGeojsonData(response.data);
                console.log(response.data)
                const centroid = response.data.features[0].properties.centroid;
                setCentroidData(centroid);
            } catch (error) {
                console.error('Error fetching GeoJSON data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [token]);

    const calculateCenter = (feature) => {
        if (feature.properties.centroid) {
            return [feature.properties.centroid[1], feature.properties.centroid[0]];
        }
        return [0, 0];
    };

    const style = {
        color: '#0066cc',
        weight: 2,
        opacity: 1,
    };

    const farmerNames = geojsonData ? [...new Set(geojsonData.features.map(feature => feature.properties.FarmerName))] : [];

    const handleFarmerNameChange = (event) => {
        setSelectedFarmerName(event.target.value);
    };

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.leafletElement.invalidateSize();
        }
    }, [selectedFarmerName]);

    const key = `${selectedFarmerName}-${centroidData ? centroidData.join(',') : 'no-data'}`;

    return (
        <div>
            <Header />
            <Container className="mt--15" fluid>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-right mt-5">
                        <Link to="/create-field">
                            <Button color="success">
                                {t('Create Field')} <i className='fa fa-plus'></i>
                            </Button>
                        </Link>
                    </Col>
                    {loading ? (
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                            <Spinner animation="border" role="status">
                                <span className="sr-only">{t('Loading...')}</span>
                            </Spinner>
                        </Col>
                    ) : (
                        <>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                                <Card>
                                    <CardHeader>
                                        <h3>{t('List of the field')}</h3>
                                    </CardHeader>
                                    <CardBody>
                                        <select
                                            id="farmerName"
                                            value={selectedFarmerName}
                                            onChange={handleFarmerNameChange}
                                            className="form-control"
                                        >
                                            <option value=""></option>
                                            {farmerNames.map((name, index) => (
                                                <option key={index} value={name}>{name}</option>
                                            ))}
                                        </select>
                                    </CardBody>
                                </Card>
                            </Col>

                            {geojsonData && geojsonData.features
                                .filter(feature => selectedFarmerName === "" || feature.properties.FarmerName === selectedFarmerName)
                                .map((feature, index) => (
                                    <Col key={index} xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-5">
                                        <Card bg="white" style={{ marginBottom: '20px' }}>
                                            <Card.Header><h3>{t('Field Name:')}{` ${feature.properties.FieldName}`}</h3></Card.Header>
                                            <Card.Body>
                                                <MapContainer ref={mapRef} key={key} center={calculateCenter(feature)} zoom={14} style={{ height: '300px', width: '100%', margin: 0, padding: 0 }}>
                                                    <LayersControl position="topright">
                                                        <LayersControl.BaseLayer checked name="OpenStreetMap">
                                                            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' />
                                                        </LayersControl.BaseLayer>
                                                        <LayersControl.BaseLayer checked name="Satellite">
                                                            <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
                                                        </LayersControl.BaseLayer>
                                                    </LayersControl>
                                                    <GeoJSON
                                                        data={feature}
                                                        style={style}
                                                        onEachFeature={(feature, layer) => {
                                                            layer.bindPopup(
                                                                `<PopupContent><p>${feature.properties.FieldName}</p></PopupContent>`
                                                            );
                                                        }}
                                                    />
                                                </MapContainer>
                                                <br></br>
                                                <Card.Title>{t('Field Detail')}</Card.Title>
                                                <Card.Text>
                                                    {t('Farmer company name:')} {feature.properties.FarmerName}
                                                    <br></br>
                                                    {t('Crop Rotation:')} {t(`translation:${feature.properties.CropRotation}`)}
                                                    <br></br>
                                                    {t('Crop:')} {t(`translation:${feature.properties.ActualCrop}`)}
                                                    <br></br>
                                                    {t('Agronomic Management:')} {t(`translation:${feature.properties.OrganicVSConventional}`)}
                                                </Card.Text>
                                                <br></br>
                                                <Row>
                                                    <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                        <Link to={`/update-field/${feature.id}`}>
                                                            <Button color="warning">
                                                                {t('Edit')} <i className="fas fa-edit"></i>
                                                            </Button>
                                                        </Link>
                                                    </Col>
                                                    <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                        <Link to={`/delete-field/${feature.id}`}>
                                                            <Button color="danger">
                                                                {t('Delete')} <i className="fas fa-trash"></i>
                                                            </Button>
                                                        </Link>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}
                        </>
                    )}
                </Row>
            </Container>
        </div>
    )
}

export default Field;
