import React, { useEffect, useState } from 'react';
import {Form} from 'react-bootstrap'
import { Container, Row, Col, Card, CardHeader, CardFooter, Button, Spinner, CardBody, Alert } from 'reactstrap';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { MapContainer, TileLayer, Marker, LayersControl, Popup } from 'react-leaflet';
import {useNavigate, Link} from 'react-router-dom'

import Header from 'components/Headers/Header';

import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerIconShadow from 'leaflet/dist/images/marker-shadow.png';
import L from 'leaflet'; // Import leaflet library

function CreateAllert() {

  const [loading, setloading] = useState(true)
  const [loadingsubmitting, setloadingsubmitting] = useState(true);
  const [error, seterror] = useState('');
  const [message, setmessage] = useState('');

  const [cabina, setcabina] = useState('');
  const [cabinoption, setccabinoption] = useState([]);

  const [variabile, setvariabile] = useState('Temperatura');
  const [maggioreminore, setmaggioreminore] = useState('');
  const [valore, setvalore] = useState('');

  const [iotposition, setiotposition] = useState([]);
  
  const token = useSelector((state) => state.userLogin.userInfo.token);
  const userId = useSelector((state) => state.userLogin.userInfo.id);

  const navigate = useNavigate();

  ////////////
  const { t, i18n } = useTranslation();  // Destructure i18n from useTranslation

  useEffect(() => {
      const storedLanguage = localStorage.getItem('selectedLanguageAFS');
      if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);  // Use i18n to change language
      }
  }, [i18n]);
  ////////////

  const customMarkerIcon = new L.Icon({
    iconUrl: markerIcon,
    shadowUrl: markerIconShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  useEffect(() => {

    const fetchData = async () => {

      try {

        const config = {
          headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${token}`
          }
        }
        
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/iot/exist-iot/`, config);
        
        setccabinoption(response.data)

      } catch (error) {
        console.error('Errore durante la richiesta GET:', error);
      } finally {
        setloading(false)
      }
      
    };

    fetchData();
  }, [token]);

  useEffect(() => {

    const fetchData = async () => {

      try {

        const config = {
          headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${token}`
          }
        }

        setiotposition('')
        
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/iot/iot-position?cabina=${cabina}`, config);
        setiotposition(response.data)

      } catch (error) {
        console.error('Errore durante la richiesta GET:', error);
      } finally {
        setloading(false)
      }
      
    };

    fetchData();
  }, [token, cabina]);


  const Submitting = async () => {

    setloadingsubmitting(false)

    if (cabina==='') {
      setmessage(t('Please select the IoT Cabin'))
      seterror(true)
      setTimeout(function() {
        seterror(false)
      }, 5000);
      setloadingsubmitting(true);
      return;
    } 

    if (variabile==='') {
      setmessage(t('Please select the variable'))
      seterror(true)
      setTimeout(function() {
        seterror(false)
      }, 5000);
      setloadingsubmitting(true);
      return;
    } 

    if (maggioreminore==='') {
      setmessage(t('Please select the logic to apply'))
      seterror(true)
      setTimeout(function() {
        seterror(false)
      }, 5000);
      setloadingsubmitting(true);
      return;
    } 

    if (valore==='') {
      setmessage(t('Please set the threshold value'))
      seterror(true)
      setTimeout(function() {
        seterror(false)
      }, 5000);
      setloadingsubmitting(true);
      return;
    } 

    try {

      const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
        }
      }
      
      const payload = {
        User: userId,
        Cabina: cabina,
        Variabile: variabile,
        Maggiore_Minore: maggioreminore,
        Valore: valore
      };

      axios.post(`${process.env.REACT_APP_BACKEND_API}/iot/create-allert/`, payload, config);
      
    } catch (error) {
      console.error('Errore durante la richiesta GET:', error);
    } finally {
      setloadingsubmitting(true)
      navigate("/allert/")
    }
  };

  const handleRestart = () => {
    window.location.reload();
  };

  return (
    <div>
      {loading ? (
        <>
          <Header/> 
          <Container className="mt--15" fluid>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                <Spinner>
                {t('Loading')}
                </Spinner>
              </Col>
            </Row>
          </Container>
        </>
      ):(
        <>
          <Header/> 
          <Container className="mt--15" fluid>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                <Card>
                  <CardHeader>
                    <h3>{t('Create Allert')}</h3>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <Form.Label>{t('Select the IoT Cabin')}</Form.Label>
                      <Form.Control 
                        as="select" 
                        onChange={(e) => setcabina(e.target.value)}
                        value={cabina}>
                          <option value={''}>

                          </option>
                        {cabinoption.map((option, index) => (
                            <option key={index} value={option.Cabina}>
                              {option.Cabina}
                            </option>
                        ))}
                      </Form.Control>
                      <br></br>
                      {iotposition.length > 0 ? (
                        <>
                          <h3>{t('IoT Position')}</h3>
                          <MapContainer center={[iotposition[0].lat, iotposition[0].longi]} zoom={15} style={{ height: '200px', width: '100%' }}>
                            <LayersControl position="topright">
                                <LayersControl.BaseLayer checked name="OpenStreetMap">
                                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'/>
                                </LayersControl.BaseLayer>
                                <LayersControl.BaseLayer checked name="Satellite">
                                <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"/>        
                                </LayersControl.BaseLayer>
                            </LayersControl>
                            <Marker position={[iotposition[0].lat, iotposition[0].longi]} icon={customMarkerIcon}>
                                <Popup>
                                    <h3>{t('IoT Number')} {0 + 1}</h3>
                                </Popup>
                            </Marker>
                          </MapContainer>
                        </>
                      ) : (
                        <>
                        </>
                      )}
                      <br></br>
                      <Form.Label>{t('Select the variable')}</Form.Label>
                      <Form.Control 
                        as="select" 
                        onChange={(e) => setvariabile(e.target.value)}
                        value={variabile}>
                        <option value={'Temperatura'}>
                            {t('Air Temperature')} (°C)
                        </option>
                        <option value={'Umidità_aria'}>
                            {t('Air Humidity')} (%)                                      
                        </option>
                        <option value={'Precipitazione'}>
                            {t('Rainfall')} (mm)                      
                        </option>
                        <option value={'Pressione_atmosferica'}>
                            {t('Atmospheric Pressure')} (hPa)                                    
                        </option>
                        <option value={'Vento'}>
                            {t('Wind')} (km/h)                                        
                        </option>
                        <option value={'Raffica'}>
                            {t('Gust')} (km/h)                                      
                        </option>
                        <option value={'Gradi_Giorno'}>
                            {t('Degree Days')}                                  
                        </option>
                        <option value={'TWH_Index'}>
                            {t('TWH Index')}                                      
                        </option>
                        <option value={'Punto_rugiada'}>
                            {t('Dew Point')} (°C Td)                                      
                        </option>
                        <option value={'Evapotraspirazione'}>
                            {t('Evapotraspiration')} (mm)                                      
                        </option>
                        <option value={'Bagnatura_Fogliare'}>
                            {t('Leaf Wetness')} (h)                                      
                        </option>
                        <option value={'Umidità_Suolo'}>
                            {t('Soil Humidity')} (%)                                      
                        </option>
                      </Form.Control>
                      <br></br>
                      <Form.Label>{t('Select the logic')}</Form.Label>
                      <Form.Control 
                        as="select" 
                        onChange={(e) => setmaggioreminore(e.target.value)}
                        value={maggioreminore}>
                          <option value={''}>

                          </option>
                          <option value={'Maggiore'}>
                              {t('Higher than')}
                          </option>
                          <option value={'Minore'}>
                              {t('Lower than')}                                      
                          </option>
                      </Form.Control>
                      <br></br>
                      <Form.Label>{t('Set the threshold value')}</Form.Label>
                      <Form.Control 
                          type="number"
                          onChange={(e) => setvalore(e.target.value)}
                          value={valore}>
                      </Form.Control>
                    </Form>
                    {error && <Alert color="danger" fade={false}>{message}</Alert>}
                  </CardBody>
                  <CardFooter>
                    <Row>
                      {loadingsubmitting ? (
                        <>
                          <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                            <Button color='success' onClick={Submitting}>{t('Add Allert')} <i className='fa fa-plus'></i></Button>
                          </Col>
                          <br></br>                        
                        </>
                      ):(
                        <>
                          <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                            <Button color="success" disabled>
                              <Spinner size="sm">
                                {t('Adding the allert...')}
                              </Spinner>
                              <span>
                                {' '}{t('Adding the allert...')}
                              </span>
                            </Button>
                          </Col>
                          <br></br>
                        </>
                      )}
                      <br></br>
                      <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                        <Link to="/allert">
                          <Button color="dark">
                              {t('Go back')} <i className="fas fa-rotate-left"></i>
                          </Button>
                        </Link>
                        <br></br>
                      </Col>
                      <br></br>
                      <br></br>
                      <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                        <Button color="primary" onClick={handleRestart}>
                                {t('Restart Creation')} <i className="fas fa-rotate-left"></i>
                        </Button>
                      </Col>
                    </Row>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </>

      )}
    </div>
  )
}

export default CreateAllert
