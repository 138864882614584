import React, {useEffect, useState} from 'react'
import Header from 'components/Headers/Header';
import {Row, Col, Container, Spinner, Button, Card, CardBody, CardHeader, Table} from 'reactstrap'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {Link} from 'react-router-dom'
import axios from 'axios'
import { MapContainer, TileLayer, LayersControl, FeatureGroup, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

function FieldNote() {
    
    const [loading, setLoading] = useState(true)
    const [checkfield, setCheckfield] = useState([])
    const [fieldnote, setFieldnote] = useState([])
    const [operator, setOperator] = useState([])

    const token = useSelector((state) => state.userLogin.userInfo.token);
    
    ////////////
    const { t, i18n } = useTranslation(); 

    useEffect(() => {
        const storedLanguage = localStorage.getItem('selectedLanguageAFS');
        if (storedLanguage) {
        i18n.changeLanguage(storedLanguage); 
        }
    }, [i18n]);
    ////////////

    useEffect(() => {

        const fetchData = async () => {
  
          try {
    
            const config = {
              headers: {
                  'Content-type': 'application/json',
                  Authorization: `Bearer ${token}`
              }
            }
  
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/fieldname/`, config);
            
            setCheckfield(response.data)    
            
            setLoading(false)
  
          } catch (error) {
            console.error('Errore durante la richiesta GET:', error);
          }
          
        };
    
        fetchData();
    }, [token]);

    useEffect(() => {

        const fetchData = async () => {
  
          try {
    
            const config = {
              headers: {
                  'Content-type': 'application/json',
                  Authorization: `Bearer ${token}`
              }
            }
  
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/company-management/operator/`, config);
                        
            setOperator(response.data)     
            
          } catch (error) {
            console.error('Errore durante la richiesta GET:', error);
          }
          
        };
    
        fetchData();
    }, [token]);

    useEffect(() => {

        const fetchData = async () => {
  
          try {
    
            const config = {
              headers: {
                  'Content-type': 'application/json',
                  Authorization: `Bearer ${token}`
              }
            }
  
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/company-management/field-note/`, config);
            
            setFieldnote(response.data)     
            
            setLoading(false)
  
          } catch (error) {
            console.error('Errore durante la richiesta GET:', error);
          }
          
        };
    
        fetchData();
    }, [token]);

    const redIcon = new L.Icon({
        iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
    });

    return (
        <div>
            <Header/>
            {loading ? (
                <>
                    <Container className="mt--15" fluid>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                                <Spinner>
                                    {t('Loading')}
                                </Spinner>
                            </Col>
                        </Row>
                    </Container>
                </>
            ):(
                <>
                    <Container className="mt--15" fluid>
                        <Row>
                            {checkfield && checkfield.length >0 ? (
                                <>
                                    {operator && operator.length >0 ? (
                                        <>
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-right mt-5">
                                                <Link to="/create-field-note">
                                                    <Button color="success">
                                                        {t('Create Field Note')} <i className='fa fa-plus'></i>
                                                    </Button>
                                                </Link>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                                                <Card className="shadow">
                                                    <CardHeader className="border-0">
                                                        <h3 className="mb-0">{t('Field Note Registred')}</h3>
                                                    </CardHeader>
                                                    <CardBody>
                                                        {fieldnote && fieldnote.length>0 ? (
                                                            <>
                                                                <Table
                                                                    className="align-items-center table-flush"
                                                                    responsive
                                                                    >
                                                                    <thead>
                                                                    <tr>
                                                                        <th>{t('Date')}</th>
                                                                        <th>{t('Farm')}</th>
                                                                        <th>{t('Field')}</th>
                                                                        <th>{t('Operator')}</th>
                                                                        <th>{t('type')}</th>
                                                                        <th>{t('Title')}</th>
                                                                        <th>{t('Description')}</th>
                                                                        <th>{t('Edit')}</th>
                                                                        <th>{t('Delete')}</th>                  
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {fieldnote.map((farmer, index) => (
                                                                        <tr key={index}>
                                                                            <td>{farmer.NoteDate}</td>
                                                                            <td>{farmer.FarmerName}</td>
                                                                            <td>{farmer.FieldName}</td>
                                                                            <td>{farmer.OperatorName}</td>
                                                                            <td>{t(`translation:${farmer.Type}`)}</td>
                                                                            <td>{farmer.Title}</td>                                                                            
                                                                            <td>{farmer.Description}</td>
                                                                        <td>
                                                                            <Link to={`/update-field-note/${farmer.id}`}>
                                                                                <Button color="warning">{t('Edit')} <i className="fas fa-edit"></i></Button>
                                                                            </Link>
                                                                        </td>
                                                                        <td>
                                                                            <Link to={`/delete-field-note/${farmer.id}`}>
                                                                                <Button color="danger">
                                                                                    {t('Delete')} <i className="fas fa-trash"></i>
                                                                                </Button>
                                                                            </Link>
                                                                        </td>
                                                                        </tr>
                                                                    ))}
                                                                    </tbody>
                                                                </Table>
                                                                <br></br>
                                                                <h3 className="mb-0">{t('Field Note Map')}</h3>
                                                                <MapContainer center={[fieldnote[0].Longitudine, fieldnote[0].Latitudine]} zoom={14} style={{ height: '500px', width: '100%', margin: 0, padding: 0 }}>
                                                                    <LayersControl position="topright">
                                                                    <LayersControl.BaseLayer checked name="OpenStreetMap">
                                                                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' />
                                                                    </LayersControl.BaseLayer>
                                                                    <LayersControl.BaseLayer checked name="Satellite">
                                                                        <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" attribution='&copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community' />
                                                                    </LayersControl.BaseLayer>
                                                                    </LayersControl>
                                                                    {fieldnote.map((farmer, index) => (
                                                                        <FeatureGroup key={index}>
                                                                            <Marker position={[farmer.Longitudine, farmer.Latitudine]} icon={redIcon}>
                                                                            <Popup>
                                                                                <p>{t('Field Note Title:')} {farmer.Title}</p>
                                                                            </Popup>
                                                                            </Marker>
                                                                        </FeatureGroup>
                                                                    ))}
                                                                </MapContainer>
                                                            </>
                                                        ):(
                                                            <>
                                                                <h3 className="mb-0">{t('No Field Note Are Registred')}</h3>
                                                            </>
                                                        )}
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </>
                                    ):(
                                        <>
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                                                <h3>{t('No Field exist for your account')}</h3>
                                                <h3>{t('If you want to register an Field Note you should create first an Operator')}</h3>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">                                                
                                                <Link to="/create-operator">
                                                    <Button color="success">
                                                        {t('Create Operator')}
                                                    </Button>
                                                </Link>
                                            </Col>   
                                        </>
                                    )}
                                </>
                            ):(
                                <>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                                        <h3>{t('No Field exist for your account')}</h3>
                                        <h3>{t('If you want to register an Field Note you should create first a Field')}</h3>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">                                                
                                        <Link to="/create-field">
                                            <Button color="success">
                                                {t('Create Field')}
                                            </Button>
                                        </Link>
                                    </Col>       
                                </>
                            )}
                        </Row>
                    </Container>
                </>
            )}
        </div>
    )
}

export default FieldNote
