import React, {useEffect, useState} from 'react'
import {Row, Col, Container, Spinner, Button, Alert, Card, CardBody, CardHeader, CardFooter, FormText} from 'reactstrap'
import {Form} from 'react-bootstrap'
import Header from 'components/Headers/Header';
import { useTranslation } from 'react-i18next';
import axios from 'axios'
import {useSelector} from 'react-redux';
import {useNavigate, Link} from 'react-router-dom'
import validator from 'validator';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';

function CreateOperator() {

    const [loading, setLoading] = useState(true)
    const [loadingsubmitting, setLoadingsubmitting] = useState(true)
    const [emailError, setEmailError] = useState('');

    const [name, setName] = useState('')
    const [lastname, setLastname] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')

    const [optionsfarm, setOptionsfarm] = useState([])
    const [selectedfarm, setSelectedfarm] = useState('')

    const [message, setMessage] = useState("");
    const [error, setError] = useState("");

    const token = useSelector((state) => state.userLogin.userInfo.token);
    const userId = useSelector((state) => state.userLogin.userInfo.id);

    const navigate = useNavigate();

    ////////////
    const { t, i18n } = useTranslation();  // Destructure i18n from useTranslation

    useEffect(() => {
        const storedLanguage = localStorage.getItem('selectedLanguageAFS');
        if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);  // Use i18n to change language
        }
    }, [i18n]);
    ////////////

    const validateEmail = (value) => {
        if (validator.isEmail(value)) {
          setEmailError('');
        } else {
          setEmailError(t('Invalid email address'))
        }
      };

    useEffect(() => {

        const fetchData = async () => {
  
          try {
    
            const config = {
              headers: {
                  'Content-type': 'application/json',
                  Authorization: `Bearer ${token}`
              }
            }
  
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/farmavailable/`, config);
            
            const extractedNames = response.data.map(item => item.NameFarmer);
            setOptionsfarm(extractedNames);       
            
            setLoading(false)
  
          } catch (error) {
            console.error('Errore durante la richiesta GET:', error);
          }
          
        };
    
        fetchData();
    }, [token]);

    const getFarmerId = async (selectedfarm) => {
        try {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
              }   
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/farm/${selectedfarm}`, config);
            return response.data;
        } catch (error) {
            console.error("Error fetching farmer ID:", error);
            throw error;
        }
    };

    const handleSubmit = async () => {

        setLoadingsubmitting(false);

        if (name==='') {
            setMessage(t('The name is empty'))
            setError(true)
            setTimeout(function() {
              setError(false)
            }, 5000);
            setLoadingsubmitting(true);
            return;
          } 

        if (lastname==='') {
            setMessage(t('The last name is empty'))
            setError(true)
            setTimeout(function() {
                setError(false)
            }, 5000);
            setLoadingsubmitting(true);
                return;
        } 

        
        if (email==='') {
            setMessage(t('The email is empty'))
            setError(true)
            setTimeout(function() {
                setError(false)
            }, 5000);
            setLoadingsubmitting(true);
                return;
        } 

        if (phone==='') {
            setMessage(t('The phone is empty'))
            setError(true)
            setTimeout(function() {
                setError(false)
            }, 5000);
            setLoadingsubmitting(true);
                return;
        } 

        if (selectedfarm==='') {
            setMessage(t('You must select a farm to associate to the operators'))
            setError(true)
            setTimeout(function() {
                setError(false)
            }, 5000);
            setLoadingsubmitting(true);
                return;
        } 

        const formattedPhone = '+' + phone;
        
        try {

            const farmerId = await getFarmerId(selectedfarm);

            const payload = {
                Name: name,
                Cognome: lastname,
                Email: email,
                Phone: formattedPhone,
                Farm: farmerId,
                User: userId
            };
        
            const config = {
              headers: {
                  'Content-type': 'application/json',
                  Authorization: `Bearer ${token}`
              }
            }   

            const response = await axios.post(`${process.env.REACT_APP_BACKEND_API}/company-management/create-operator/`, payload, config)

            setLoadingsubmitting(true);
            navigate('/operator/');

        } catch (error) {
            console.log(error)
            setLoadingsubmitting(true);
            setMessage(t("The phone number isn't correct"))
            setError(true)
            setTimeout(function() {
                setError(false)
            }, 5000);

        }
        
    };

    return (
        <div>
            <Header/>
            {loading ? (
                <>
                    <Container className="mt--15" fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                        <Spinner>
                            {t('Loading')}
                        </Spinner>
                        </Col>
                    </Row>
                    </Container>
                </>
            ):(
                <>
                    <Container className="mt--15" fluid>
                        <Row>
                            <Col  xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                                <Card>
                                    <CardHeader>
                                        <h3>{t('Create operator')}</h3>
                                    </CardHeader>
                                    <CardBody>
                                        <Form.Label>{t('Farmer Company Name:')}</Form.Label>
                                        <Form.Control
                                            as="select"
                                            placeholder={t('Farmer Company Name:')}
                                            value={selectedfarm} 
                                            onChange={(e) => setSelectedfarm(e.target.value)}>
                                                <option value={""}>
                                                    
                                                </option>
                                            {optionsfarm.map((option, index) => (
                                                <option key={index} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </Form.Control>
                                        <br></br>
                                        <Form.Label>{t('Operator Name:')}</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            placeholder={t('Operator Name:')} 
                                            value={name} 
                                            onChange={(e) => setName(e.target.value)}/>
                                        <br></br>
                                        <Form.Label>{t('Operator Last Name:')}</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            placeholder={t('Operator Last Name:')} 
                                            value={lastname} 
                                            onChange={(e) => setLastname(e.target.value)}/>
                                        <br></br>
                                        <Form.Label>{t('Operator Email:')}</Form.Label>
                                        <Form.Control 
                                            type="email" 
                                            placeholder={t('Operator Email:')} 
                                            value={email} 
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                                validateEmail(e.target.value);
                                                }}/>    
                                        <FormText color="danger">{emailError}</FormText>
                                        <br></br>
                                        <Form.Label>{t('Operator Phone:')}</Form.Label>
                                        <PhoneInput
                                            value={phone}
                                            onChange={(phone) => setPhone(phone)}/>
                                        <br></br>
                                        {error && 
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                                                <Alert color="danger" fade={false}>{message}</Alert>
                                            </Col>                    
                                        }
                                    </CardBody>
                                    <CardFooter>
                                        <Row>
                                            {loadingsubmitting ? (
                                                <>
                                                    <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-2">
                                                        <Button color="success" onClick={handleSubmit}>
                                                            {t('Create Operator')} <i className="fas fa-plus"></i>
                                                        </Button>
                                                    </Col>
                                                </>
                                            ) : (
                                                <>
                                                    <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-2">
                                                        <Button color="success" disabled>
                                                            <Spinner size="sm">
                                                                {t('Creating the operator...')}
                                                            </Spinner>
                                                            <span>
                                                                {' '}{t('Creating the operator...')}
                                                            </span>
                                                        </Button> 
                                                    </Col>
                                                </>
                                            )}
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-2">
                                                <Link to="/operator/">
                                                    <Button color="dark">
                                                    {t('Go back')} <i className="fas fa-rotate-left"></i>
                                                    </Button>
                                                </Link>
                                            </Col>
                                        </Row>

                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </>
            )}
        </div>
    )
}

export default CreateOperator
