import React, {useEffect, useState} from 'react'
import Header from 'components/Headers/Header';
import {Row, Col, Container, Spinner, Card, CardBody, CardTitle, CardHeader, Table} from 'reactstrap'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import axios from 'axios'
import {Form} from 'react-bootstrap'
import { MapContainer, TileLayer, LayersControl, GeoJSON, FeatureGroup } from 'react-leaflet';
import * as echarts from 'echarts';

function Economy() {

    const [loading, setLoading] = useState(true)

    const [farm, setFarm]=useState('')
    const [farmoptions, setFarmoptions]=useState('')

    const [economyfarm, setEconomyfarm] = useState({ altri_ricavi:[], costo_operatore:[], field_activities: [], yields: [] });
    const [totalProfitfarm, setTotalProfitfarm] = useState(0);
    const [totalcostfarm, setTotalcostfarm] = useState(0);
    const [totalrevenuefarm, setTotalrevenuefarm] = useState(0);   
    const [fieldData, setFieldDataa] = useState([]);

    const [monthlyDatafarm, setMonthlyDatafarm] = useState({ costs: [], revenues: [] });

    const token = useSelector((state) => state.userLogin.userInfo.token);

        ////////////
    const { t, i18n } = useTranslation(); 

    useEffect(() => {
        const storedLanguage = localStorage.getItem('selectedLanguageAFS');
        if (storedLanguage) {
        i18n.changeLanguage(storedLanguage); 
        }
    }, [i18n]);
    ////////////

    useEffect(() => {

        const fetchData = async () => {
                
            try {
                const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
                };

                const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/farmavailable/`, config);

                const extractedNames = response.data.map(item => item.NameFarmer);

                setFarmoptions(extractedNames);
                
                setLoading(false);
            
            } catch (error) {

                console.error('Error during GET request:', error);

            }
    
        };

        fetchData();
    }, [token]);

    const getColor = (actualCrop) => {
        switch (actualCrop) {
            case 'Vineyards':
                return 'green';
            case 'Barley':
                return 'yellow';
            case 'Maize':
                return 'goldenrod'; // Maize is typically represented with a golden color
            case 'Sunflower':
                return 'red';
            case 'Alfalfa':
                return 'darkgreen'; // Alfalfa is a dark green color
            case 'Durum_wheat':
                return 'violet';
            case 'Sorghum':
                return 'lightgreen';
            case 'Frumento_tenero':
                return 'orange';
            case 'Temporary_grassland':
                return 'lightgreen'; // Temporary grassland can also be represented with light green
            case 'Clovers':
                return 'darkgreen'; // Clovers also typically have a dark green color
            case 'Olive_groves':
                return 'olive'; // Olive groves are represented with olive color
            case 'Oranges':
                return 'orange';
            case 'Apple_fruit':
                return 'lightgreen'; // Apples can be represented with light green
            case 'Cherry_fruit':
                return 'red'; // Cherries are typically represented with red color
            case 'Nuts_trees':
                return 'saddlebrown'; // Nuts trees are often represented with brown color
            case 'Common_wheat':
                return 'wheat'; // Common wheat color
            case 'Rye':
                return 'tan'; // Rye is typically represented with a tan color
            case 'Potatoes':
                return 'brown'; // Potatoes are often represented with a brown color
            case 'Triticale':
                return 'silver'; // Triticale can be represented with a silver color
            case 'Sugar_beet':
                return 'darkred'; // Sugar beet can be represented with a dark red color
            case 'Soya':
                return 'lightpink'; // Soya can be represented with light pink
            case 'Cotton':
                return 'whitesmoke'; // Cotton can be represented with white color
            case 'Tomatoes':
                return 'tomato'; // Tomatoes are typically represented with a tomato red color
            default:
                return 'blue';
        }
    };

    const getFarmerId = async (farm) => {

        try {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }   
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/farm/${farm}`, config);
            return response.data;
        } catch (error) {
            console.error("Error fetching farmer ID:", error);
            throw error;
        }
    };

    const getFieldId = async (field) => {

        try {

            const config = {

                headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`
                }
                
            }

            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/field-selected/${field}`, config);
            
            return response.data.id;

        } catch (error) {
            console.error("Error fetching farmer ID:", error);
            throw error;
        }
    };

    useEffect(() => {

        const fetchData = async () => {
                
            try {

                const config = {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${token}`
                    }
                };

                const farmerid = await getFarmerId(farm)

                const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/company-management/economy-farmer/${farmerid}`, config);

                const data = response.data;

                setEconomyfarm(data);

                // 1. Calcolare il profitto per ogni yield
                const totalYieldProfit = data.yields.reduce((acc, yieldData) => {
                    const yieldProfit = (yieldData.TotalQuantity * yieldData.Price) - yieldData.TotalCost;
                    return acc + yieldProfit;
                }, 0);

                // 2. Sommare il profitto pac
                const totalPacProfit = data.altri_ricavi.reduce((acc, activity) => acc + activity.TotalRevenue, 0);

                setTotalrevenuefarm(totalYieldProfit+totalPacProfit)

                // 3. Sommare il TotalCost di tutte le field_activities
                const totalFieldActivitiesCost = data.field_activities.reduce((acc, activity) => acc + activity.TotalCost, 0);

                // 3. Sommare il TotalCost di tutte le field_activities
                const totalOperatorCost = data.costo_operatore.reduce((acc, activity) => acc + activity.TotalCost, 0);

                setTotalcostfarm(totalFieldActivitiesCost+totalOperatorCost)

                // 3. Calcolare il profitto totale
                const totalProfit = (totalYieldProfit+totalPacProfit) - (totalFieldActivitiesCost+totalOperatorCost);

                setTotalProfitfarm(totalProfit);

            } catch (error) {

                console.error('Error during GET request:', error);

            }
    
        };

        fetchData();
    }, [token, farm]);

    useEffect(() => {

        const fetchData = async () => {
                
            try {

                const config = {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${token}`
                    }
                };

                const responsefield = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/field-by-farmer-geojson/${farm}`, config);
                
                setFieldDataa(responsefield.data.features);

            } catch (error) {

                console.error('Error during GET request:', error);

            }
    
        };

        fetchData();
    }, [token, farm]);


const [costDatafarm, setCostDatafarm] = useState([]);
const [revenueDatafarm, setRevenueDatafarm] = useState([]);

useEffect(() => {

    const fetchData = async () => {

        try {

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }

        const farmerid = await getFarmerId(farm)

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/company-management/economy-farmer/${farmerid}`, config);
        
        const data = response.data;

        // Calcola i costi delle diverse tipologie di attività di campo
        const costTillage = data.field_activities.filter(item => item.FieldActivity === 'tillage').reduce((acc, curr) => acc + curr.TotalCost, 0);
        const costSowing = data.field_activities.filter(item => item.FieldActivity === 'sowing').reduce((acc, curr) => acc + curr.TotalCost, 0);
        const costDefence = data.field_activities.filter(item => item.FieldActivity === 'defence').reduce((acc, curr) => acc + curr.TotalCost, 0);
        const costIrrigation = data.field_activities.filter(item => item.FieldActivity === 'irrigation').reduce((acc, curr) => acc + curr.TotalCost, 0);
        const costNutrition = data.field_activities.filter(item => item.FieldActivity === 'nutrition').reduce((acc, curr) => acc + curr.TotalCost, 0);
        const costOperator = data.costo_operatore.reduce((acc, curr) => acc + curr.TotalCost, 0);

        // Calcola i ricavi
        const revenueYield = data.yields.reduce((acc, curr) => acc + curr.TotalQuantity * curr.Price, 0);
        const revenuePAC = data.altri_ricavi.filter(item => item.Type === 'pac').reduce((acc, curr) => acc + curr.TotalRevenue, 0);
        const revenueIndennizzi = data.altri_ricavi.filter(item => item.Type === 'indinizzi').reduce((acc, curr) => acc + curr.TotalRevenue, 0);

        setCostDatafarm([
            { value: costTillage, name: 'Tillage' },
            { value: costSowing, name: 'Sowing' },
            { value: costDefence, name: 'Defence' },
            { value: costIrrigation, name: 'Irrigation' },
            { value: costNutrition, name: 'Nutrition' },
            { value: costOperator, name: 'Costi operatori' }
        ]);

        setRevenueDatafarm([
            { value: revenueYield, name: 'Yield' },
            { value: revenuePAC, name: 'PAC' },
            { value: revenueIndennizzi, name: 'Indennizzi' }
        ]);

        // Aggrega i costi e i ricavi per mese
        const costsByMonth = {};
        const revenuesByMonth = {};

        // Aggiungi i costi mensili
        data.field_activities.forEach(activity => {
            const month = new Date(activity.DateFieldActivity).getMonth();
            if (!costsByMonth[month]) costsByMonth[month] = 0;
            costsByMonth[month] += activity.TotalCost;
        });

        // Aggiungi i ricavi mensili
        data.yields.forEach(yieldData => {
            const month = new Date(yieldData.DateYield).getMonth();
            if (!revenuesByMonth[month]) revenuesByMonth[month] = 0;
            revenuesByMonth[month] += yieldData.TotalQuantity * yieldData.Price;
        });

        data.altri_ricavi.forEach(ricavo => {
            const month = new Date(ricavo.Date).getMonth();
            if (!revenuesByMonth[month]) revenuesByMonth[month] = 0;
            revenuesByMonth[month] += ricavo.TotalRevenue;
        });

        // Aggiungi i costi degli operatori mensili
        for (let i = 0; i < 12; i++) {
            if (!costsByMonth[i]) costsByMonth[i] = 0;
            costsByMonth[i] += costOperator/12;
        }

        // Converti in array ordinato per mese
        const costsArray = [];
        const revenuesArray = [];
        for (let i = 0; i < 12; i++) {
            costsArray.push(costsByMonth[i] || 0);
            revenuesArray.push(revenuesByMonth[i] || 0);
        }

        setMonthlyDatafarm({ costs: costsArray, revenues: revenuesArray });
        
        } catch (error) {
        console.error('Errore durante la richiesta GET:', error);
        } finally {
        setLoading(false)
        }
        
    };

    fetchData();
}, [token, farm]);

useEffect(() => {
    if (costDatafarm.length > 0) {
        const chartDom = document.getElementById('cost-chart-farm');
        const myChart = echarts.init(chartDom);
        const option = {
            title: {
                text: t('Cost allocation'),
                left: 'center'
            },
            tooltip: {
                trigger: 'item'
            },
            series: [
                {
                    name: t('Cost'),
                    type: 'pie',
                    radius: '50%',
                    data: costDatafarm,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        };
        myChart.setOption(option);
    }
}, [costDatafarm, farm]);

useEffect(() => {
    if (revenueDatafarm.length > 0) {
        const chartDom = document.getElementById('revenue-chart-farm');
        const myChart = echarts.init(chartDom);
        const option = {
            title: {
                text: t('Allocation of Revenues'),
                left: 'center'
            },
            tooltip: {
                trigger: 'item'
            },
            series: [
                {
                    name: t('Revenues'),
                    type: 'pie',
                    radius: '50%',
                    data: revenueDatafarm,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        };
        myChart.setOption(option);
    }
}, [revenueDatafarm, farm]);


useEffect(() => {
    if (monthlyDatafarm.costs.length > 0 && monthlyDatafarm.revenues.length > 0) {
        const chartDom = document.getElementById('bar-chart-farm');
        const myChart = echarts.init(chartDom);
        const option = {
            title: {
                text: t('Monthly Costs and Revenues'),
                left: 'center'
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            legend: {
                data: [t('Cost'), t('Revenue')],
                bottom: 10  // Sposta la legenda in basso
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '15%', // Margine inferiore aumentato per spostare la legenda verso l'alto
                containLabel: true
            },
            xAxis: {
                type: 'category',
                data: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic']
            },
            yAxis: {
                type: 'value'
            },
            series: [
                {
                    name: t('Cost'),
                    type: 'bar',
                    data: monthlyDatafarm.costs
                },
                {
                    name: t('Revenue'),
                    type: 'bar',
                    data: monthlyDatafarm.revenues
                }
            ]
        };
        myChart.setOption(option);
        }
    }, [monthlyDatafarm, farm]);

    return (
        <div>
            <Header/>
            {loading ? (
                <>
                    <Container className="mt--15" fluid>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                                <Spinner>
                                    {t('Loading')}
                                </Spinner>
                            </Col>
                        </Row>
                    </Container>
                </>
            ):(
                <>  
                    <Container className="mt--15" fluid>
                        <br></br>
                        <Row>
                            <Col xs={12} sm={12} md={3} lg={3} xl={3} xxl={3} className="text-center mt-5">
                                <Card>
                                    <CardHeader>
                                        <h3>{t('Farmer Economy Analysis')}</h3>
                                    </CardHeader>
                                    <CardBody>
                                        <Form.Label>{t('Farmer Company Name:')}</Form.Label>
                                        <Form.Control
                                            as="select"
                                            placeholder={t('Farmer Company Name:')}
                                            value={farm}
                                            onChange={(e) => setFarm(e.target.value)}>
                                            <option value={""}></option>
                                            {farmoptions.map((option, index) => (
                                            <option key={index} value={option}>
                                                {option}
                                            </option>
                                            ))}
                                        </Form.Control>
                                        <br></br>
                                        {fieldData && fieldData.length>0 ? (
                                            <>
                                                <MapContainer center={[fieldData[0].geometry.coordinates[0][0][1], fieldData[0].geometry.coordinates[0][0][0]]} zoom={14} style={{ height: '500px', width: '100%', margin: 0, padding: 0 }} key={JSON.stringify(fieldData)}>
                                                    <LayersControl position="topright">
                                                        <LayersControl.BaseLayer checked name="OpenStreetMap">
                                                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' />
                                                        </LayersControl.BaseLayer>
                                                        <LayersControl.BaseLayer checked name="Satellite">
                                                        <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" attribution='&copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community' />
                                                        </LayersControl.BaseLayer>
                                                    </LayersControl>
                                                    {fieldData.map((field, index) => (
                                                        <FeatureGroup key={index}>
                                                            <GeoJSON
                                                                data={{
                                                                type: 'Feature',
                                                                geometry: {
                                                                    type: 'Polygon',
                                                                    coordinates: field.geometry.coordinates,
                                                                },
                                                                properties: field.properties,
                                                                }}
                                                                style={(feature) => ({
                                                                fillColor: getColor(feature.properties.ActualCrop), // Function to get color based on ActualCrop
                                                                weight: 3,
                                                                opacity: 1,
                                                                color: 'blue',
                                                                fillOpacity: 0.5
                                                                })}
                                                                onEachFeature={(feature, layer) => {
                                                                layer.bindPopup(
                                                                    `<PopupContent> 
                                                                        <p>${field.properties.FieldName}</p>   
                                                                        <p>${field.properties.ActualCrop}</p>                             
                                                                    </PopupContent>`
                                                                );
                                                                }}
                                                            />
                                                        </FeatureGroup>
                                                    ))}
                                                </MapContainer>
                                            </>
                                        ):null}
                                    </CardBody>
                                </Card>
                            </Col>
                            {fieldData && fieldData.length>0 ? (
                                <>
                                    <Col xs={12} sm={12} md={9} lg={9} xl={9} xxl={9} className="text-center mt-5">
                                        <Card className="card-stats mb-4 mb-xl-0">
                                            <CardBody>
                                                <Row>
                                                    <div className="col">
                                                        <CardTitle
                                                            tag="h5"
                                                            className="text-uppercase text-muted mb-0"
                                                            >
                                                            {t('Farm Profit')}
                                                        </CardTitle>
                                                        <span className="h2 font-weight-bold mb-0">
                                                            {totalProfitfarm && (
                                                                <>
                                                                {totalProfitfarm}
                                                                </>
                                                            )}
                                                        </span>
                                                    </div>
                                                    <Col className="col-auto">
                                                        <div className="icon icon-shape bg-green text-white rounded-circle shadow">
                                                            <i class="fa-solid fa-money-bill-transfer"></i>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                        <Card className="card-stats mb-4 mb-xl-0">
                                            <CardBody>
                                                <Row>
                                                    <div className="col">
                                                        <CardTitle
                                                            tag="h5"
                                                            className="text-uppercase text-muted mb-0"
                                                            >
                                                            {t('Farm  Revenue')}
                                                        </CardTitle>
                                                        <span className="h2 font-weight-bold mb-0">
                                                            {totalrevenuefarm && (
                                                                <>
                                                                {totalrevenuefarm}
                                                                </>
                                                            )}
                                                        </span>
                                                    </div>
                                                    <Col className="col-auto">
                                                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                                                            <i className="fa fa-plus" />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                        <Card className="card-stats mb-4 mb-xl-0">
                                            <CardBody>
                                                <Row>
                                                    <div className="col">
                                                        <CardTitle
                                                            tag="h5"
                                                            className="text-uppercase text-muted mb-0"
                                                            >
                                                            {t('Farm Total Cost')}
                                                        </CardTitle>
                                                        <span className="h2 font-weight-bold mb-0">
                                                            {totalcostfarm && (
                                                                <>
                                                                {totalcostfarm}
                                                                </>
                                                            )}
                                                        </span>
                                                    </div>
                                                    <Col className="col-auto">
                                                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                                            <i className="fa fa-minus" />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <br></br>
                                                <br></br>
                                                <Row>
                                                    <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-5">
                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                                                            <div id="cost-chart-farm" style={{ width: 400, height: 400 }}></div>
                                                        </div>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-5">
                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                                                            <div id="revenue-chart-farm" style={{ width: 400, height: 400 }}></div>
                                                        </div>                                            
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <br></br>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                                        <Card>
                                            <CardHeader><h3>{t('Cash Flow')}</h3></CardHeader>
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <div id="bar-chart-farm" style={{ width: 800, height: 400 }}></div>
                                            </div>
                                        </Card>  
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                                        <Card>
                                            <CardHeader><h3>{t('Economy Data')}</h3></CardHeader>
                                            <CardBody>
                                                <Table responsive>
                                                    <thead>
                                                        <tr>
                                                            <th>{t('Type')}</th>
                                                            <th>{t('Date')}</th>
                                                            <th>{t('Farm')}</th>
                                                            <th>{t('Field')}</th>
                                                            <th>{t('Crop')}</th>
                                                            <th>{t('Activity')}</th>
                                                            <th>{t('Cost/Price')}</th>
                                                            <th>{t('Quantity/Hours')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {economyfarm && economyfarm.field_activities.length>0 ? (
                                                        <>
                                                            {economyfarm.field_activities.map((activity) => (
                                                                <tr key={activity.id}>
                                                                    <td>{t('Field Activity')}</td>
                                                                    <td>{activity.DateFieldActivity}</td>
                                                                    <td>{activity.FarmerName}</td>
                                                                    <td>{activity.FieldName}</td>
                                                                    <td>{t(`translation:${activity.Crop}`)}</td>
                                                                    <td>{t(`translation:${activity.FieldActivity}`)}</td>
                                                                    <td>{activity.TotalCost}</td>
                                                                    <td>{activity.HourOfWork}</td>
                                                                </tr>
                                                            ))}
                                                        </>
                                                    ):null}
                                                    {economyfarm && economyfarm.costo_operatore.length>0 ? (
                                                        <>
                                                            {economyfarm.costo_operatore.map((activity) => (
                                                                <tr key={activity.id}>
                                                                    <td>{t('Operator Cost')}</td>
                                                                    <td>{activity.Date}</td>
                                                                    <td>{activity.FarmerName}</td>
                                                                    <td>{activity.FieldName}</td>
                                                                    <td></td>
                                                                    <td>{t('Operator')} {" "} {activity.OperatorName}</td>
                                                                    <td>{activity.TotalCost}</td>
                                                                    <td>{activity.HourOfWork}</td>
                                                                </tr>
                                                            ))}
                                                        </>
                                                    ):null}
                                                    {economyfarm && economyfarm.altri_ricavi.length>0 ? (
                                                        <>
                                                            {economyfarm.altri_ricavi.map((yieldData) => (
                                                                <tr key={yieldData.id}>
                                                                    <td>{t('Other Revenue')}</td>
                                                                    <td>{yieldData.Date}</td>
                                                                    <td>{yieldData.FarmerName}</td>
                                                                    <td>{yieldData.FieldName}</td>
                                                                    <td></td>
                                                                    <td>{t(`translation:${yieldData.Type}`)}</td>
                                                                    <td>{yieldData.TotalRevenue}</td>
                                                                    <td>{yieldData.TotalQuantity}</td>
                                                                </tr>
                                                            ))}
                                                        </>
                                                    ):null}
                                                    {economyfarm && economyfarm.yields.length>0 ? (
                                                        <>
                                                            {economyfarm.yields.map((yieldData) => (
                                                                <tr key={yieldData.id}>
                                                                    <td>{t('Yield')}</td>
                                                                    <td>{yieldData.DateYield}</td>
                                                                    <td>{yieldData.FarmerName}</td>
                                                                    <td>{yieldData.FieldName}</td>
                                                                    <td>{t(`translation:${yieldData.Crop}`)}</td>
                                                                    <td>{t('Harvest')}</td>
                                                                    <td>{yieldData.Price}</td>
                                                                    <td>{yieldData.TotalQuantity}</td>
                                                                </tr>
                                                            ))}
                                                        </>
                                                    ):null}
                                                    </tbody>
                                                </Table>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </>
                            ):null}
                        </Row>
                    </Container>
                </>
            )}
        </div>
    )
}

export default Economy
