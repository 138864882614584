import React, {useEffect} from 'react'
import Header from 'components/Headers/Header';
import {Link} from 'react-router-dom'
import {Container, Row, Col , Button} from 'reactstrap'

import { useTranslation } from 'react-i18next';

export default function Confirmintegration() {

  ////////////

  const { t, i18n } = useTranslation();

  useEffect(() => {
      const storedLanguage = localStorage.getItem('selectedLanguageAFS');
      if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
      }
  }, [i18n]);

  ////////////

  return (
    <>
      <Header/>
      <Container className="mt--15" fluid>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
            <h3>{t("Integration done")}</h3>
            <br></br>
            <Link to="/">
              <Button color='success'>
                {t("Go back")}
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </>  
  )
}
