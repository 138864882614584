import React, {useState, useEffect} from 'react'
import Header from "components/Headers/Header.js";

import axios from 'axios';
import { useSelector } from 'react-redux'; 

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
    Spinner,
    Alert,
    CardFooter
  } from "reactstrap";

import {Form} from 'react-bootstrap'

import {useNavigate, Link} from 'react-router-dom'
import { useTranslation } from 'react-i18next';

function Support() {

    const [object, setobject]=useState('')
    const [message, setmessage]=useState('')

    const [loadingsubmit, setloadingsubmit]=useState(true)
    const [messageerror, setmessageerror]=useState('')
    const [error, seterror]=useState('')

    const token = useSelector((state) => state.userLogin.userInfo.token);
    const userId = useSelector((state) => state.userLogin.userInfo.id);

    const navigate =useNavigate()

    ////////////

    const { t, i18n } = useTranslation();

    useEffect(() => {
        const storedLanguage = localStorage.getItem('selectedLanguageAFS');
        if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
        }
    }, [i18n]);

    ////////////
    const submit = async () => {

        setloadingsubmit(false) 

        if (object==='') {
            setmessageerror(t('Please select the object of your request'))
            seterror(true)
            setTimeout(function() {
                seterror(false)
            }, 5000);
            setloadingsubmit(true);
            return;
          } 

          if (message==='') {
            setmessageerror(t('Please report a valid message'))
            seterror(true)
            setTimeout(function() {
                seterror(false)
            }, 5000);
            setloadingsubmit(true);
            return;
          } 

        try {

            const payload = {
                Object: object,
                Message: message,
                User: userId,
            };
        
            const config = {
              headers: {
                  'Content-type': 'application/json',
                  Authorization: `Bearer ${token}`
              }
            }   
                
            axios.post(`${process.env.REACT_APP_BACKEND_API}/support/support/`, payload, config)

        } catch (error) {

            console.log(error)

        } finally {

            setloadingsubmit(true)
            navigate("/support-message-send-success/")

        }

    }

    return (
        <div>
            <Header/>
            <Container className="mt--7" fluid>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                        <Card>
                            <CardHeader>
                                <h3>{t('Send us your message')}</h3>
                            </CardHeader>
                            <CardBody>
                                <Form>
                                    <Form.Label>{t('Object of the request:')}</Form.Label>
                                    <Form.Control
                                        as="select"
                                        placeholder={t('Object:')}
                                        value={object} 
                                        onChange={(e) => setobject(e.target.value)}>
                                            <option value={""}>

                                            </option>
                                            <option value={"Support Request"}>
                                                {t('Support Request')}
                                            </option>
                                            <option value={"Upgrade account"}>
                                                {t('Upgrade account')}
                                            </option>
                                            <option value={"Problem with the application"}>
                                                {t('Problem with the application')}
                                            </option>
                                            <option value={"Other"}>
                                                {t('Other')}
                                            </option>
                                    </Form.Control>
                                    <br></br>
                                    <Form.Label>{t('Message of the request:')}</Form.Label>
                                    <Form.Control 
                                        as="textarea" 
                                        placeholder={t('Message:')} 
                                        value={message} 
                                        onChange={(e) => setmessage(e.target.value)}/>
                                    <br></br>
                                    {error ? (
                                        <>
                                            <Alert color="danger" fade={false}>{messageerror}</Alert>
                                        </>
                                    ):null}
                                </Form>
                            </CardBody>
                            <CardFooter>
                                <Row>
                                    {loadingsubmit ? (
                                        <>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-2">
                                                <Button color='success' onClick={submit}>
                                                    {t('Send message')}
                                                </Button>
                                            </Col>
                                        </>
                                    ):(
                                        <>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-2">
                                                <Button color="success" disabled>
                                                    <Spinner size="sm">
                                                        {t('Loading...')}
                                                    </Spinner>
                                                    <span>
                                                    {' '}Loading
                                                    </span>
                                                </Button>
                                            </Col>
                                        </>
                                    )}
                                     <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-2">
                                        <Link to="/user-profile/">
                                            <Button color="dark">
                                                {t('Go back')} <i className="fas fa-rotate-left"></i>
                                            </Button>
                                        </Link>
                                     </Col>
                                </Row>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container>

        </div>
    )
}

export default Support
