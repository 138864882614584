import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Header from 'components/Headers/Header';
import { Container, Row, Col, Button, Spinner, Alert } from 'reactstrap';
import { Link } from 'react-router-dom';

function Integration() {
  const [loading, setLoading] = useState(true);
  const [integrated, setIntegrated] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [farmernameoptions, setfarmernameoptions] = useState([]);

  const token = useSelector((state) => state.userLogin.userInfo.token);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const storedLanguage = localStorage.getItem('selectedLanguageAFS');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_API}/check-integration-jhondeere`,
          config
        );

        if (response.status === 200) {
          setIntegrated(response.data.message);
          setLoading(false);

        } else {
          
          setMessage(
            t(
              'Something went wrong. If the problem persists, please send us an email at automaticfarmsolution@gmail.com'
            )
          );
          setError(true);
          setTimeout(function () {
            setError(false);
          }, 5000);

          setLoading(false);
          return;
        }
      } catch (error) {
        setMessage(
          t(
            'Something went wrong. If the problem persists, please send us an email at automaticfarmsolution@gmail.com'
          )
        );
        setError(true);
        setTimeout(function () {
          setError(false);
        }, 5000);

        setLoading(false);
        return;
      }
    };

    fetchData();
  }, [token]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_API}/field/farmavailable/`,
          config
        );

        const extractedNames = response.data.map((item) => item.NameFarmer);
        setfarmernameoptions(extractedNames);
      } catch (error) {
        console.error('Error during GET request:', error);
      }
    };

    fetchData();
  }, [token]);

  const integrationstart = async () => {
    setLoading(false);

    try {
      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/AgriculturalMachineryManufacturers/`,
        config
      );

      window.location.replace(response.data.url);
    } catch (error) {
      console.error(error);
      // console.error('Error during GET request:', error);
      // setMessage(
      //   t(
      //     'Something went wrong. If the problem persists, please send us an email at automaticfarmsolution@gmail.com'
      //   )
      // );
      // setError(true);
      // setTimeout(function () {
      //   setError(false);
      // }, 5000);
      // return;
    }

    setLoading(true);
  };

  return (
    <div>
      <Header />
      <Container className="mt--15" fluid>
        <Row>
          {loading ? (
            <>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                <Spinner>{t('Loading...')}</Spinner>
                {error && <Alert color="danger" fade={false}>{message}</Alert>}
              </Col>
            </>
          ) : integrated ? (
            <>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                <h3>{t('You have done the integration with John Deere')}</h3>
                <br></br>
                <Link to="/">
                  <Button color="success">{t('Go back')}</Button>
                </Link>
              </Col>
            </>
          ) : farmernameoptions && farmernameoptions.length > 0 ? (
            <>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                <Button color="success" onClick={integrationstart}>
                  {t('Start the integration with the Operation Center John Deere')}
                </Button>
              </Col>
            </>
          ) : (
            <>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                <h3>{t("You don't have any farm registered, first you have to set at least one farmer")}</h3>
                <br />
                <br />
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                <Link to="/create-farm">
                  <Button color="success">{t('Create Farm')}</Button>
                </Link>
              </Col>
            </>
          )}
        </Row>
      </Container>
    </div>
  );
}

export default Integration;
