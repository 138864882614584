import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import axios from 'axios';
import { useSelector } from 'react-redux'; 

import {Container, Row, Col, Card, CardBody, CardHeader, CardFooter, Spinner, Alert} from 'reactstrap'

import '@fortawesome/fontawesome-free/css/all.min.css';

import Header from 'components/Headers/Header';
import { useTranslation } from 'react-i18next';

import {Link} from 'react-router-dom'

export default function PrescriptionMap() {
    
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');

    const [farmeroptions, setFarmeroptions] = useState([]);
    const [farmerselected, setfarmerselected] = useState('');

    const [AutomaticOrNot, setAutomaticOrNot] = useState('True');
    const [Zone, setZone] = useState(2);
    const [Fertilizer, setFertilizer] = useState(160);
    const [Strategy, setStrategy] = useState('highwherehigh');
    const [Percentage, setPercentage] = useState(20);    
    const [HtmlContent, setHtmlContent] = useState('');
    
    const [integrated, setIntegrated] = useState('');
    
    const [create, setCreate] = useState(true)
    const [download, setdownload] = useState(true)
    const [jhondeere, setjhondeere] = useState(true)

    const [message, setMessage] = useState("");
    const [error, setError] = useState("");  
  
    const token = useSelector((state) => state.userLogin.userInfo.token);

    ////////////

    const { t, i18n } = useTranslation();

    useEffect(() => {
        const storedLanguage = localStorage.getItem('selectedLanguageAFS');
        if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
        }
    }, [i18n]);

    ////////////

    useEffect(() => {
      // Funzione per effettuare la richiesta GET e aggiornare lo stato delle opzioni
      const fetchData = async () => {
          try {

              const config = {
                  headers: {
                      'Content-type': 'application/json',
                      Authorization: `Bearer ${token}`
                  }
              }
              
              const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/farmavailable`, config);
              
              setFarmeroptions(response.data);

          } catch (error) {
              setMessage(t('Something went wrong. If the problem persist please send us an email at automaticfarmsolution@gmail.com'))
              setError(true)
              setTimeout(function() {
              setError(false)
              }, 5000);
              return;
          }
      };

      fetchData(); 
  }, [token]);


  const handleSelectChange1 = async (e) => {
        
    setfarmerselected(e.target.value)
    
    const farmersele = e.target.value

    try {

        const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
        }
        }
        
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/field-by-farmer/${farmersele}`, config);
                    
        setOptions(response.data);

    } catch (error) {
        setMessage(t('Something went wrong. If the problem persist please send us an email at automaticfarmsolution@gmail.com'))
        setError(true)
        setTimeout(function() {
        setError(false)
        }, 5000);
        return;
    }
  };

  useEffect(() => {

    const fetchData = async () => {
      try {
        const config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_API}/check-integration-jhondeere`,
          config
        );
        
        if (response.status === 200) {
          setIntegrated(response.data.message);

        } else {
          
          setMessage(
            t(
              'Something went wrong. If the problem persists, please send us an email at automaticfarmsolution@gmail.com'
            )
          );
          setError(true);
          setTimeout(function () {
            setError(false);
          }, 5000);
          return;
        }
      } catch (error) {
        setMessage(
          t(
            'Something went wrong. If the problem persists, please send us an email at automaticfarmsolution@gmail.com'
          )
        );
        setError(true);
        setTimeout(function () {
          setError(false);
        }, 5000);
        return;
      }
    };

    fetchData();
  }, [token]);
  
  // selectedOption

  useEffect(() => {
    if (options.length>1) {
      setSelectedOption(options[0]);
    } else {
      setSelectedOption(options[1]);
    }
    
  }, [options]);

  const handleSelectChange = async (e) => {
    setSelectedOption(e.target.value);
  };
  
    // AutomaticOrNot
  
    const handleSelectChangeAutomaticOrNot = (e) => {
      setAutomaticOrNot(e.target.value);
    };
  
    // Zone
  
    const handleSelectChangeZone = (e) => {
      setZone(e.target.value);
    };
  
    // Ferrilizer
  
    const handleSelectChangeFertilizer = (e) => {
      setFertilizer(e.target.value);
    };
  
    // Strategy
  
    const handleSelectChangeStrategy = (e) => {
      setStrategy(e.target.value);
    };
  
    // Percetange
  
    const handleSelectChangePercentage = (e) => {
      setPercentage(e.target.value);
    };
  
  
    // Submit
  
    const handleCreate = async (event) => {
      
      setCreate(false)
  
      try {
       
        const config = {
          headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${token}`
          }
        }

        console.log(selectedOption)
        console.log(options[0])

        if (typeof selectedOption === 'undefined' || selectedOption==='') {

          console.log("sono nel primo")

          const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/prescription-map?field=${options[0]}&AutomaticOrNot=${AutomaticOrNot}&Fertilizer=${Fertilizer}&Strategy=${Strategy}&Percentage=${Percentage}&Zone=${Zone}`, config);
        
          setHtmlContent(response.data);

        } else {

          console.log("sono nel secondo")

          const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/prescription-map?field=${selectedOption}&AutomaticOrNot=${AutomaticOrNot}&Fertilizer=${Fertilizer}&Strategy=${Strategy}&Percentage=${Percentage}&Zone=${Zone}`, config);
        
          setHtmlContent(response.data);

        } 

  
      } catch (error) {
        // setMessage(t('Something went wrong. If the problem persist please send us an email at automaticfarmsolution@gmail.com'))
        // setError(true)
        // setTimeout(function() {
        //   setError(false)
        // }, 5000);
        // return;
      } finally {
        setCreate(true)
      }      
    };
    
    const handleDownload = async (event) => {
      
      setdownload(false)

      try {
       
          const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            responseType: 'arraybuffer',  // Imposta il tipo di risposta come array di byte
          }

        if (typeof selectedOption === 'undefined' || selectedOption==='') {

          const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/prescription-map/download?field=${options[0]}&AutomaticOrNot=${AutomaticOrNot}&Fertilizer=${Fertilizer}&Strategy=${Strategy}&Percentage=${Percentage}&Zone=${Zone}`, config);

          // Crea un oggetto Blob con i dati binari della risposta
          const blob = new Blob([response.data], { type: 'application/zip' });
            
          // Crea un URL oggetto per il blob
          const url = window.URL.createObjectURL(blob);

          // Crea un link temporaneo
          const link = document.createElement('a');

          // Imposta l'URL del link con l'URL del blob
          link.href = url;

          // Imposta l'attributo 'download' con il nome del file
          link.setAttribute('download', 'Rx.zip');

          // Aggiunge il link al documento
          document.body.appendChild(link);

          // Simula un clic sul link per avviare il download
          link.click();

          // Rimuove il link dal documento
          document.body.removeChild(link);

          // Rilascia l'URL dell'oggetto per il blob
          window.URL.revokeObjectURL(url);

        } else {

          const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/prescription-map/download?field=${selectedOption}&AutomaticOrNot=${AutomaticOrNot}&Fertilizer=${Fertilizer}&Strategy=${Strategy}&Percentage=${Percentage}&Zone=${Zone}`, config);

          // Crea un oggetto Blob con i dati binari della risposta
          const blob = new Blob([response.data], { type: 'application/zip' });
    
          // Crea un URL oggetto per il blob
          const url = window.URL.createObjectURL(blob);

          // Crea un link temporaneo
          const link = document.createElement('a');

          // Imposta l'URL del link con l'URL del blob
          link.href = url;

          // Imposta l'attributo 'download' con il nome del file
          link.setAttribute('download', 'Rx.zip');

          // Aggiunge il link al documento
          document.body.appendChild(link);

          // Simula un clic sul link per avviare il download
          link.click();

          // Rimuove il link dal documento
          document.body.removeChild(link);

          // Rilascia l'URL dell'oggetto per il blob
          window.URL.revokeObjectURL(url);

        } 
  
      } catch (error) {
        console.error('Errore nel download del file:', error);
        setMessage(t('Something went wrong. If the problem persist please send us an email at automaticfarmsolution@gmail.com'))
        setError(true)
        setTimeout(function() {
          setError(false)
        }, 5000);
        return;
      }

      setdownload(true)
    };

    const sendtojhondeere = async () => {

      setjhondeere(false)

      try {

        const config = {
          headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${token}`
          },
          responseType: 'arraybuffer',
        }

        if (selectedOption==='') {

          const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/prescription-map/deere?field=${options[0]}&AutomaticOrNot=${AutomaticOrNot}&Fertilizer=${Fertilizer}&Strategy=${Strategy}&Percentage=${Percentage}&Zone=${Zone}`, config);
  
          setjhondeere(true)

        } else {
          const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/prescription-map/deere?field=${selectedOption}&AutomaticOrNot=${AutomaticOrNot}&Fertilizer=${Fertilizer}&Strategy=${Strategy}&Percentage=${Percentage}&Zone=${Zone}`, config);
  
          setjhondeere(true)
        }
  


      } catch(error) {

        setMessage(t('Something went wrong. If the problem persist please send us an email at automaticfarmsolution@gmail.com'))
        setError(true)
        setTimeout(function() {
          setError(false)
        }, 5000);
        return;

      }
    }    

    return (
        <>
          <Header/>
          <Container className="mt--15" fluid>
            <Row>
              <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-5">
                <Card className="shadow">
                  <CardHeader>
                    <h3>{t('Set the prescription map')}</h3>
                  </CardHeader>
                  <br></br>
                  <CardBody>
                    <Form>
                      <Form.Group controlId="exampleForm.SelectCustom">
                        <Form.Label>{t('Select the Farm')}</Form.Label>
                        <Form.Control 
                          as="select" 
                          onChange={handleSelectChange1} 
                          value={farmerselected}>
                              <option key={""} value={""}>
                                  
                              </option>
                              {farmeroptions.map((farmer, index) => (
                                  <option key={index} value={farmer.NameFarmer}>
                                      {farmer.NameFarmer}
                                  </option>
                              ))}
                        </Form.Control>
                        <br></br>
                        <Form.Label>{t('Select the field to analyze')}</Form.Label>
                        <Form.Control 
                            as="select" 
                            onChange={handleSelectChange} 
                            value={selectedOption}
                            >
                            {options.map((option, index) => (
                            <option key={index} value={option}>
                                {option}
                            </option>
                            ))}
                        </Form.Control>
                      </Form.Group>
                      <br></br>
                      <Form.Group controlId="AutomaticOrNot">
                        <Form.Label>{t('Do you want to define the number of zone in Automatically way or Manual?')}</Form.Label>
                        <Form.Control as="select"
                            onChange={handleSelectChangeAutomaticOrNot}
                            value={AutomaticOrNot || 'True'}
                            >
                            <option value="True">{t('Automatic')}</option>
                            <option value="False">{t('Manual')}</option>
                        </Form.Control>
                      </Form.Group>
                      <br></br>
                      <Form.Group controlId="Zone">
                        <Form.Label>{t('If is set Manual, how many zones do you want to create?')}</Form.Label>
                        <Form.Control as="select"
                            onChange={handleSelectChangeZone}
                            value={Zone || '2'}
                            >
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                        </Form.Control>
                      </Form.Group>
                      <br></br>
                      <Form.Group controlId="Fertilizer">
                      <Form.Label>{t('Max fertilizer that you want to apply?')}</Form.Label>
                      <Form.Control 
                          type="number" 
                          onChange={handleSelectChangeFertilizer} 
                          value={Fertilizer || '160'}
                      />
                      </Form.Group>
                      <br></br>
                      <Form.Group controlId="Strategy">
                        <Form.Label>{t('Which strategy do you want to use?')}</Form.Label>
                        <Form.Control as="select"
                            onChange={handleSelectChangeStrategy}
                            value={Strategy || 'highwherehigh'}
                            >
                            <option value="highwherehigh">{t('Provide higher amout of fertilizer where the crop is stronger')}</option>
                            <option value="highwherelow">{t('Provide lower amout of fertilizer where the crop is stronger')}</option>
                        </Form.Control>
                      </Form.Group>
                      <br></br>
                      <Form.Group controlId="Percentage">
                        <Form.Label>{t('Percentage of difference of fertilizer between zones')}</Form.Label>
                        <Form.Control type="range" onChange={handleSelectChangePercentage} value={Percentage || 20} min={0} max={100}>
                        </Form.Control>
                        <p>{t('Selected Percentage:')} {Percentage}</p>
                      </Form.Group>
                      <br></br>
                    </Form>
                  </CardBody>
                  <CardFooter>
                  {error && <Alert color="danger" fade={false}>{message}</Alert>}
                    {create ? (
                      <>
                        <Button variant='success' onClick={handleCreate}> {t('Create')} <i className="fas fa-map"></i></Button>
                      </>
                    ):(
                      <>
                        <Button variant="success" disabled>
                          <Spinner size="sm">
                          {t('Loading...')}
                          </Spinner>
                          <span>
                            {' '}{t('Loading')}
                          </span>
                        </Button>
                      </>
                    )}
                  </CardFooter>
                </Card>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-5">
                <br></br>
                {HtmlContent ? (
                  <Card className="shadow">
                    <CardHeader>
                      <h3>{t('Prescription Map')}</h3>
                    </CardHeader>
                    <CardBody>
                      <iframe id="iframe" width="100%" height="600" srcDoc={HtmlContent}></iframe>
                    </CardBody>
                    <CardFooter>
                      {download ? (
                        <>
                          <Button variant='primary' onClick={handleDownload}> {t('Download')} <i className="fas fa-download"></i></Button>
                        </>
                      ):(
                        <>
                          <Button variant="primary" disabled>
                            <Spinner size="sm">
                            {t('Downloading...')}
                            </Spinner>
                            <span>
                              {' '}{t('Downloading')}
                            </span>
                          </Button>
                        </>
                      )}
                      {jhondeere ? (
                        <>
                          {integrated ? (
                            <>
                              <Button variant='success' onClick={sendtojhondeere}> {t('Send to Jhon Deere Operation Center')} <i className="fas fa-cloud"></i></Button>
                            </>
                          ):(
                            <>
                              <hr></hr>
                              <p>{t('If you want to send the prescription map to the operation center, you can connect this platform directly to your Operation Center')}</p>
                              <Link to="/integration/">
                                <Button color='success'>
                                  {t('Connect to Operation Center Jhon Deere')}
                                </Button>                              
                              </Link>
                            </>
                          )}
                        </>
                      ):(
                        <>
                          <Button variant="success" disabled>
                            <Spinner size="sm">
                              {t('Sending...')}
                            </Spinner>
                            <span>
                              {' '}{t('Sending')}
                            </span>
                          </Button>
                        </>
                      )}                      
                    </CardFooter>
                  </Card>  
                ) : (
                    <>

                    </>
                )}
              </Col>
            </Row>
          </Container>
        </>
    )
}
