import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link  } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import Header from 'components/Headers/Header';
import { Form, Col, Row } from 'react-bootstrap';
import {Container, Alert, Button, Spinner, Card, CardBody, CardHeader, CardFooter} from 'reactstrap'
import axios from 'axios'

function UpdateOperatorCost() {

  const [loading, setLoading] = useState(true)
  const [loadingsubmit, setLoadingsubmit] = useState(true)
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const [farm, setFarm] = useState('')
  const [farmoptions, setFarmoptions] = useState([])

  const [operator, setOperator] = useState('')
  const [operatoroptions, setOperatoroptions] = useState([])

  const [cost, setCost] = useState('')

  const [date, setDate]=useState('')

  const { fieldId } = useParams()
  const navigate = useNavigate()

  const token = useSelector((state) => state.userLogin.userInfo.token);

    // i18n
  const { t, i18n } = useTranslation(); 

  useEffect(() => {
      const storedLanguage = localStorage.getItem('selectedLanguageAFS');
      if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
      }
  }, [i18n]);

  
  useEffect(() => {

    const fetchData = async () => {

      try {

        const config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        };

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/company-management/operator-cost/${fieldId}`, config);

        setFarm(response.data.FarmerName)
        setOperator(response.data.OperatorName)
        setCost(response.data.TotalCost)
        setDate(response.data.Date)
        
      } catch (error) {

        console.error('Error during GET request:', error);

      } finally {
        setLoading(false)
      }
    };

    fetchData();
  }, [token]);

  useEffect(() => {

    const fetchData = async () => {

      try {

        const config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        };

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/farmavailable/`, config);

        const extractedNames = response.data.map(item => item.NameFarmer);

        setFarmoptions(extractedNames);
        
      } catch (error) {

        console.error('Error during GET request:', error);

      }
    };

    fetchData();
  }, [token]);

  useEffect(() => {

    const fetchData = async () => {
        
      try {

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        };

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/company-management/operator/`, config);

        setOperatoroptions(response.data)
          
      } catch (error) {
          console.error('Error during GET request:', error);
      }
    };

    fetchData();
  }, [token]);

  const getFarmerId = async (farm) => {
    try {
        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`
            }
          }   
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/farm/${farm}`, config);
        return response.data;
    } catch (error) {
        console.error("Error fetching farmer ID:", error);
        throw error;
    }
  };

  const getOperatorId = async (operator) => {

    try {

      const config = {

        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${token}`
        }
        
      }

      const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/company-management/operator/operatorname/${operator}`, config);
      
      return response.data.id;

    } catch (error) {
      console.error("Error fetching farmer ID:", error);
      throw error;
    }
  };

  const submit = async () => {

    setLoadingsubmit(false);
       
    if (farm==='') {
      setMessage(t('Please select the farm'));
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 5000);
      setLoadingsubmit(true);
      return;
    }

    if (operator==='') {
      setMessage(t('Please select the operator'));
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 5000);
      setLoadingsubmit(true);
      return;
    }

    if (cost==='') {
      setMessage(t('Please set the year operotor cost'));
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 5000);
      setLoadingsubmit(true);
      return;
    }
    
    if (date==='') {
      setMessage(t('Please enter the start date of the operator cost'));
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 5000);
      setLoadingsubmit(true);
      return;
    }

    try {
        
        const farmerId = await getFarmerId(farm)    
        const operatorID = await getOperatorId(operator)
      
        const payload = {
          Farm: farmerId,
          Operator: operatorID,
          TotalCost: parseFloat(cost),
          Date:date
        };
    
        const config = {
          headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${token}`
          }
        }   
            
        const response= await axios.put(`${process.env.REACT_APP_BACKEND_API}/company-management/update-operator-cost/${fieldId}/`, payload, config)

    } catch (error) {
      console.log(error)
      setMessage(t('An error occured while creating the field. If the problem persiste please send us an email automaticfarmsolution@gmail.com'))
      setError(true)
      setTimeout(function() {
          setError(false)
      }, 5000);
      setLoadingsubmit(true);
      return;

    } finally {
      setLoadingsubmit(true);
      navigate('/operator-cost/');
    }
    
  };


  return (
    <div>
      <Header/>
      {loading ? (
        <>
          <Container className="mt--15" fluid>
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                <Spinner>
                    {t('Loading')}
                </Spinner>
                </Col>
            </Row>
          </Container>
        </>
      ):(
        <>
          <Container className="mt--15" fluid>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                <Card>
                  <CardHeader>
                    <h3>{t('Edit a Operator Cost')}</h3>
                  </CardHeader>
                  <CardBody>
                    <Form.Label>{t('Farmer Company Name:')}</Form.Label>
                    <Form.Control
                      as="select"
                      placeholder={t('Farmer Company Name:')}
                      value={farm}
                      onChange={(e) => setFarm(e.target.value)}>
                      <option value={""}></option>
                      {farmoptions.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </Form.Control>
                    <br></br>
                    <Form.Label>{t('Operator')}</Form.Label>
                    <Form.Control
                      as="select"
                      placeholder={t('Operator')}
                      value={operator}
                      onChange={(e) => setOperator(e.target.value)}>
                      <option value={""}></option>
                      {operatoroptions.map((option, index) => (
                        <option key={index} value={option.Name}>
                          {option.Name}
                        </option>
                      ))}
                    </Form.Control>
                    <br></br>
                    <Form.Label>{t('Operator year salary')}</Form.Label>
                    <Form.Control
                      type="numeric"
                      placeholder={t('Operator year salary')}
                      value={cost}
                      onChange={(e) => setCost(e.target.value)}>
                    </Form.Control>
                    <br></br>
                    <Form.Label>{t('Date of Hire')}</Form.Label>
                    <Form.Control
                        type="date"
                        placeholder={t('Date of Hire')}
                        value={date}
                        onChange={(e) => setDate(e.target.value)}>
                    </Form.Control>
                    {error &&
                      <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                          <Alert color="danger" fade={false}>{message}</Alert>
                      </Col>
                    }
                  </CardBody>
                  <CardFooter>
                    <Row>
                      {loadingsubmit ? (
                        <>
                          <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-2">
                            <Button color="success" onClick={submit}>
                              {t('Edit Operator Cost')} <i className="fas fa-plus"></i>
                            </Button>
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-2">
                            <Button color="success" disabled>
                              <Spinner size="sm">
                                {t('Editing the Operator Cost...')}
                              </Spinner>
                              <span>
                                {' '}{t('Editing the Operator Cost...')}
                              </span>
                            </Button>
                          </Col>
                        </>
                      )}
                      <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-2">
                          <Link to="/operator-cost/">
                              <Button color="dark">
                                  {t('Go back')} <i className="fas fa-rotate-left"></i>
                              </Button>
                          </Link>
                      </Col>
                    </Row>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </div>
  )
}

export default UpdateOperatorCost
