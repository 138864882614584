// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Stili personalizzati per CustomFlagsSelect */
.custom-flags-select {
    background-color: #fff; /* Sfondo bianco */
  }
  `, "",{"version":3,"sources":["webpack://./src/components/LanguageSelector.css"],"names":[],"mappings":"AAAA,+CAA+C;AAC/C;IACI,sBAAsB,EAAE,kBAAkB;EAC5C","sourcesContent":["/* Stili personalizzati per CustomFlagsSelect */\r\n.custom-flags-select {\r\n    background-color: #fff; /* Sfondo bianco */\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
