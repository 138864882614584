import React, {useEffect, useState} from 'react'
import Header from 'components/Headers/Header';
import {Row, Col, Container, Spinner, Button, Card, CardBody, CardHeader, Alert, CardFooter} from 'reactstrap'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {Link, useNavigate} from 'react-router-dom'
import axios from 'axios'
import {Form} from 'react-bootstrap'
import { MapContainer, TileLayer, LayersControl, FeatureGroup, GeoJSON } from 'react-leaflet';

function CreateFieldActivites() {

    const [loadingsubmit, setLoadingsubmit] = useState(true)
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const [farm, setFarm]=useState('')
    const [farmoptions, setFarmoptions]=useState([])

    const [field, setField]=useState('')
    const [fieldoptions, setFieldoptions]=useState([])

    const [fieldData, setfieldData] = useState({
        Field: '',
        Centroid: '',
        Crop: '',
        CropRotation: '',
        OrganicVsConventional: ''
    });

    const [mapCenter, setMapCenter] = useState([45.1657, 10.4515]);
    const [mapZoom, setMapZoom] = useState(4);

    const [datefieldnote, setDatefieldnote]=useState('')

    const [fieldactivitytype, setFieldactivitytype]=useState('')

    const [organic, setOrganic]=useState(false)
    
    const [done, setDone]=useState(false)

    const [cropphenology, setCropphenology]=useState('')

    const [hourofwork, setHourofwork]=useState('')

    const [crop, setCrop]=useState('')

    const [variety, setVariety] = useState('')

    const [tillagetype, setTillagetype] = useState('')
    const [tillagedepth, setTillagedepth]=useState(0)

    const [seed, setSeed]=useState('')
    const [seedoptions, setSeedoptions]=useState([])
    const [sowingdose, setSowingdose] = useState(0)

    const [irrigationvolume, setIrrigationvolume] = useState(0)

    const [fertilizer, setFertilizer] = useState('')
    const [fertilizeroptions, setFertilizeroptions] = useState([])

    const [fertilizationdose, setFertilizationdose] = useState(0)

    const [defenceproduct, setDefenceproduct]=useState('')
    const [defenceproductoptions, setDefenceproductoptions] = useState([])

    const [defencedoseused, setDefencedoseused]=useState(0)
    const [defencevolumewaterused, setDefencevolumewaterused]=useState(0)
    const [defencelevelinfenstaction, setDefencelevelinfenstaction]=useState('')
    const [defenceauthorizationtechnician, setDefenceauthorizationtechnician]=useState('')
    const [defencefasciadirispetto, setDefencefasciadirispetto]=useState('')
    const [defencesuperamentosogliaintervento, setDefencesuperamentosogliaintervento]=useState('')

    const [subcontractors,setSubcontractors]=useState(false)

    const [operator, setOperator]=useState('')
    const [operatoroptions, setOperatoroptions] = useState([])

    const [cost,setCost]=useState('')

    const [wheater, setWheater]=useState('')
    const [temperature, setTemperature]=useState('')
    const [wind, setWind]=useState('')
    const [humidity, setHumidity]=useState('')
    const [rainfall, setRainfall]=useState('')

    const token = useSelector((state) => state.userLogin.userInfo.token);

    const navigate = useNavigate()
    
    ////////////
    const { t, i18n } = useTranslation(); 

    useEffect(() => {
        const storedLanguage = localStorage.getItem('selectedLanguageAFS');
        if (storedLanguage) {
        i18n.changeLanguage(storedLanguage); 
        }
    }, [i18n]);
    ////////////

    useEffect(() => {

        const fetchData = async () => {

            try {
                const config = {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${token}`
                    }
                };

                const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/farmavailable/`, config);

                const extractedNames = response.data.map(item => item.NameFarmer);

                setFarmoptions(extractedNames);
                
            } catch (error) {
                console.error('Error during GET request:', error);
            }
        };

        fetchData();
    }, [token]);

    useEffect(() => {

        const fetchData = async () => {

            try {
                const config = {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${token}`
                    }
                };

                const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/field-by-farmer/${farm}`, config);
                
                setFieldoptions(response.data);
                
            } catch (error) {
                console.error('Error during GET request:', error);
            }
        };

        fetchData();
    }, [token, farm]);

    useEffect(() => {

        const fetchData = async () => {

            try {

                const config = {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${token}`
                    }
                }

                const responsefield = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/field-selected/${field}`, config);

                    setfieldData({
                        Field: responsefield.data,
                        Centroid: responsefield.data.properties.centroid,
                        Crop: responsefield.data.properties.ActualCrop,
                        CropRotation: responsefield.data.properties.CropRotation,
                        OrganicVSConventional: responsefield.data.properties.OrganicVSConventional
                    });

                    setMapCenter([responsefield.data.properties.centroid[1], responsefield.data.properties.centroid[0]]);

                    setMapZoom(15);
                
                } catch (error) {
                    console.error('Error during GET request:', error);
                }
        };

        fetchData();

    }, [token, field]);

    useEffect(() => {

        const fetchData = async () => {

            try {
                
                if (fieldactivitytype==="tillage") {

                } else if (fieldactivitytype==="irrigation") {

                } else if (fieldactivitytype==="sowing") {

                    const config = {
                        headers: {
                            'Content-type': 'application/json',
                            Authorization: `Bearer ${token}`
                        }
                    }

                    const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/company-management/seed/`, config);

                    const extractedNames = response.data.map(item => item.Name);
                    
                    setSeedoptions(extractedNames)                    

                } else if (fieldactivitytype==="defence") {
                    
                    const config = {
                        headers: {
                            'Content-type': 'application/json',
                            Authorization: `Bearer ${token}`
                        }
                    }

                    const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/company-management/pesticide/`, config);
 
                    const extractedNames = response.data.map(item => item.CommercialProductName);

                    setDefenceproductoptions(extractedNames)

                } else if (fieldactivitytype==="nutrition") {

                    const config = {
                        headers: {
                            'Content-type': 'application/json',
                            Authorization: `Bearer ${token}`
                        }
                    }

                    const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/company-management/fertilizer/`, config);

                    const extractedNames = response.data.map(item => item.Name);

                    setFertilizeroptions(extractedNames)
                }

                } catch (error) {
                    console.error('Error during GET request:', error);
                }
        };

        fetchData();

    }, [token, fieldactivitytype]);

    useEffect(() => {

        const fetchData = async () => {

            try {
                    
                    const config = {
                        headers: {
                            'Content-type': 'application/json',
                            Authorization: `Bearer ${token}`
                        }
                    }

                    const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/company-management/operator/`, config);
 
                    const extractedNames = response.data.map(item => item.Name);

                    setOperatoroptions(extractedNames)
                

                } catch (error) {
                    console.error('Error during GET request:', error);
                }
        };

        fetchData();

    }, [token, fieldactivitytype]);

    const getFarmerId = async (farm) => {
        try {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }   
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/farm/${farm}`, config);
            return response.data;
        } catch (error) {
            console.error("Error fetching farmer ID:", error);
            throw error;
        }
    };

    const getFieldId = async (field) => {

        try {

        const config = {

            headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
            }
            
        }

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/field-selected/${field}`, config);
        
        return response.data.id;

        } catch (error) {
        console.error("Error fetching farmer ID:", error);
        throw error;
        }
    };

    const getOperatorId = async (operator) => {

        try {

        const config = {

            headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
            }
            
        }

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/company-management/operator/operatorname/${operator}`, config);
        
        return response.data.id;

        } catch (error) {
        console.error("Error fetching farmer ID:", error);
        throw error;
        }
    };

    const getDefenceId = async (defenceproduct) => {

        try {

        const config = {

            headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
            }
            
        }

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/company-management/pesticide-id/${defenceproduct}`, config);
        
        return response.data.id;

        } catch (error) {
        console.error("Error fetching farmer ID:", error);
        throw error;
        }
    };

    const getSeedId = async (seed) => {

        try {

        const config = {

            headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
            }
            
        }

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/company-management/seed-id/${seed}`, config);
        
        return response.data.id;

        } catch (error) {
        console.error("Error fetching farmer ID:", error);
        throw error;
        }
    };

    const getFertilizerId = async (fertilizer) => {

        try {

        const config = {

            headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
            }
            
        }

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/company-management/fertilizer-id/${fertilizer}`, config);
        
        return response.data.id;

        } catch (error) {
        console.error("Error fetching farmer ID:", error);
        throw error;
        }
    };

    const submit = async () => {

        setLoadingsubmit(false);

        const farmerId = await getFarmerId(farm)    
        const fieldid = await getFieldId(field)
        const operatorID = await getOperatorId(operator)

        let defenceproductID = '';
        let seedID = '';
        let fertilizerID = '';

        if (defenceproduct === '') {
            defenceproductID = defenceproduct;
        } else {
            defenceproductID = await getDefenceId(defenceproduct);
        }

        if (seed === '') {
            seedID = seed;
        } else {
            seedID = await getSeedId(seed);
        }

        if (fertilizer === '') {
            fertilizerID = fertilizer;
        } else {
            fertilizerID = await getFertilizerId(fertilizer);
        }

        try {

            const payload = {
                Farm: farmerId,
                Field:fieldid,
                DateFieldActivity:datefieldnote,
                FieldActivity: fieldactivitytype,
                OrganicOperation:organic,
                ToDo: done,
                CropPhenology:cropphenology,
                HourOfWork:hourofwork,
                Crop:crop,
                Variety:variety,
                ProductDefense:defenceproductID,
                ProductSemente:seedID,
                ProductNutrizione: fertilizerID,
                DefenceDoseUsed:defencedoseused,
                DefenceWaterVolumeUsed:defencevolumewaterused,
                DefenceLevelInfestaction:defencelevelinfenstaction,
                DefenceAuthorizationTechnician:defenceauthorizationtechnician,
                DefenceFasciaDiRispetto:defencefasciadirispetto,
                DefenceSuperamentoSogliaIntervento:defencesuperamentosogliaintervento,
                SementeDoseUsed:sowingdose,
                NutritionDoseUsed: fertilizationdose,
                IrrigationVolumeWater:irrigationvolume,
                TillageType:tillagetype,
                TillageDepth:tillagedepth,
                Terzisti:subcontractors,
                Operator:operatorID,
                TotalCost:cost,
                WheaterClass:wheater,
                Temperature:temperature,
                WindSpeed:wind,
                Humidity:humidity,
                Rainfall:rainfall
            };

            console.log(payload)
        
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }   
                
            await axios.post(`${process.env.REACT_APP_BACKEND_API}/company-management/create-field-activity/`, payload, config)
            setLoadingsubmit(true);
            navigate('/field-activity/');

        } catch (error) {
            console.log(error)
            setMessage(t('Please fill all the form to create a field activities'))
            setError(true)
            setTimeout(function() {
                setError(false)
            }, 5000);
            setLoadingsubmit(true);
            return;    
        } 
    };

    return (
        <div>
            <Header/>
            <Container className="mt--15" fluid>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                        <Card>
                            <CardHeader>
                                <h3>{t('Create Field Activities')}</h3>
                            </CardHeader>
                            <CardBody>
                                <Form.Label>{t('Farmer Company Name:')}</Form.Label>
                                <Form.Control
                                    as="select"
                                    placeholder={t('Farmer Company Name:')}
                                    value={farm}
                                    onChange={(e) => setFarm(e.target.value)}>
                                    <option value={""}></option>
                                    {farmoptions.map((option, index) => (
                                        <option key={index} value={option}>
                                            {option}
                                        </option>
                                ))}
                                </Form.Control>
                                <br></br>
                                <Form.Label>{t('Field')}</Form.Label>
                                <Form.Control
                                    as="select"
                                    placeholder={t('Field')}
                                    value={field}
                                    onChange={(e) => setField(e.target.value)}>
                                    <option value={""}></option>
                                    {fieldoptions.map((option, index) => (
                                        <option key={index} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </Form.Control>
                                <br></br>
                                <MapContainer key={JSON.stringify(fieldData)} center={mapCenter} zoom={mapZoom} style={{ height: '500px', width: '100%', margin: 0, padding: 0 }}>
                                    <LayersControl position="topright">
                                        <LayersControl.BaseLayer checked name="OpenStreetMap">
                                            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' />
                                        </LayersControl.BaseLayer>
                                        <LayersControl.BaseLayer checked name="Satellite">
                                            <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" attribution='&copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community' />
                                        </LayersControl.BaseLayer>
                                    </LayersControl>
                                    {fieldData && fieldData.Field && fieldData.Field.geometry ? (
                                        <FeatureGroup>
                                            <GeoJSON      
                                                data={{
                                                    type: 'Feature',
                                                    geometry: {
                                                        type: 'Polygon',
                                                        coordinates: fieldData.Field.geometry.coordinates,
                                                    },
                                                    properties: fieldData.Field.properties,
                                                }}
                                                style={{ fillColor: 'blue', weight: 1, opacity: 1, color: 'blue', fillOpacity: 0.15 }}
                                            />
                                        </FeatureGroup>
                                    ) : null}
                                </MapContainer>
                                <br></br>
                                <Form.Label>{t('Date')}</Form.Label>
                                <Form.Control
                                    type="date"
                                    placeholder={t('Date')}
                                    value={datefieldnote}
                                    onChange={(e) => setDatefieldnote(e.target.value)}>
                                </Form.Control>
                                <br></br>
                                <Form.Label>{t('Type of Field Activity')}</Form.Label>
                                <Form.Control
                                    as="select"
                                    placeholder={t('Type of Field Activity')}
                                    value={fieldactivitytype}
                                    onChange={(e) => setFieldactivitytype(e.target.value)}>
                                    <option value={""}>

                                    </option>
                                    <option value={"tillage"}>
                                        {t('Tillage')}
                                    </option>
                                    <option value={"sowing"}>
                                        {t('Sowing / Planting')}
                                    </option>
                                    <option value={"defence"}>
                                        {t('Defence')}
                                    </option>
                                    <option value={"irrigation"}>
                                        {t('Irrigation')}
                                    </option>        
                                    <option value={"nutrition"}>
                                        {t('Nutrition')}
                                    </option>                                
                                </Form.Control>
                                {fieldactivitytype === 'tillage' && (
                                    <>
                                        <br></br>
                                        <Form.Label>{t('Tillage Type')}</Form.Label>
                                        <Form.Control
                                            as="select"
                                            placeholder={t('Tillage Type')}
                                            value={tillagetype}
                                            onChange={(e) => setTillagetype(e.target.value)}>
                                            <option value={""}>

                                            </option>
                                            <option value={"aratura"}>
                                                {t('Plowing')}
                                            </option>
                                            <option value={"erpicatura"}>
                                                {t('Harrowing')}
                                            </option>
                                            <option value={"zappatura"}>
                                                {t('Hoeing')}
                                            </option>
                                            <option value={"sarchiatura"}>
                                                {t('Weeding')}
                                            </option>        
                                            <option value={"frangizollatura"}>
                                                {t('Clod breaking')}
                                            </option>        
                                            <option value={"fresatura"}>
                                                {t('Milling')}
                                            </option>        
                                            <option value={"rippatura"}>
                                                {t('Ripping')}
                                            </option>        
                                            <option value={"rullatura"}>
                                                {t('Rolling')}
                                            </option>        
                                            <option value={"compattazione"}>
                                                {t('Compaction')}
                                            </option>        
                                            <option value={"pacciamatura"}>
                                                {t('Mulching')}
                                            </option>        
                                            <option value={"trinciatura"}>
                                                {t('Shredding')}
                                            </option>                                
                                        </Form.Control>
                                        <br></br>
                                        <Form.Label>{t('Tillage Depht cm')}</Form.Label>
                                        <Form.Control
                                            type="numeric"
                                            placeholder={t('Tillage Depht cm')}
                                            value={tillagedepth}
                                            onChange={(e) => setTillagedepth(e.target.value)}></Form.Control>
                                            
                                    </>
                                )}
                                {fieldactivitytype === 'sowing' && (
                                    <>
                                        {seedoptions.length === 0 ? (
                                            <>
                                                <br></br>
                                                <p>{t("You didn't create a seed object")}</p>
                                                <Link to="/create-seed/">
                                                    <Button color="success">
                                                        {t('Create Seed')} <i className="fas fa-plus"></i>
                                                    </Button>
                                                </Link>
                                                <br></br>
                                            </>
                                        ) : (
                                            <>
                                                <br></br>
                                                <Form.Label>{t('Seed / Planting')}</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    placeholder={t('Seed / Planting')}
                                                    value={seed}
                                                    onChange={(e) => setSeed(e.target.value)}>
                                                    <option value={""}></option>
                                                    {seedoptions.map((option, index) => (
                                                        <option key={index} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}                      
                                                </Form.Control>
                                                <br></br>
                                                <Form.Label>{t('Sowing Dose kg / ha')}</Form.Label>
                                                <Form.Control
                                                    type="numeric"
                                                    placeholder={t('Sowing Dose kg / ha')}
                                                    value={sowingdose}
                                                    onChange={(e) => setSowingdose(e.target.value)}></Form.Control>
                                            </>
                                        )}
                                    </>
                                )}
                                {fieldactivitytype === 'irrigation' && (
                                    <>
                                        <br></br>
                                        <Form.Label>{t('Irrigation Volume m3/ha')}</Form.Label>
                                        <Form.Control
                                            type="numeric"
                                            placeholder={t('Irrigation Volume m3/ha')}
                                            value={irrigationvolume}
                                            onChange={(e) => setIrrigationvolume(e.target.value)}></Form.Control>
                                    </>
                                )}
                                {fieldactivitytype === 'nutrition' && (
                                    <>
                                        {fertilizeroptions.length === 0 ? (
                                            <>
                                                <br></br>
                                                <p>{t("You didn't create a Fertilizer object")}</p>
                                                <Link to="/create-fertilizer/">
                                                    <Button color="success">
                                                        {t('Create a fertilizer')} <i className="fas fa-plus"></i>
                                                    </Button>
                                                </Link>
                                                <br></br>
                                            </>
                                        ) : (
                                            <>
                                                <br></br>
                                                <Form.Label>{t('Fertilization Product')}</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    placeholder={t('Fertilization Product')}
                                                    value={fertilizer}
                                                    onChange={(e) => setFertilizer(e.target.value)}>
                                                    <option value={""}></option>
                                                    {fertilizeroptions.map((option, index) => (
                                                        <option key={index} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}                      
                                                </Form.Control>
                                                <br></br>
                                                <Form.Label>{t('Fertilization Dose kg/ha')}</Form.Label>
                                                <Form.Control
                                                    type="numeric"
                                                    placeholder={t('Fertilization Dose kg/ha')}
                                                    value={fertilizationdose}
                                                    onChange={(e) => setFertilizationdose(e.target.value)}></Form.Control>
                                                <br></br>
                                            </>
                                        )}
                                    </>
                                )}
                                {fieldactivitytype === 'defence' && (
                                    <>
                                        {fertilizeroptions.length === 0 ? (
                                            <>
                                                <br></br>
                                                <p>{t("You didn't create a Defence object")}</p>
                                                <Link to="/create-pesticide/">
                                                    <Button color="success">
                                                        {t('Create a Defence Product')} <i className="fas fa-plus"></i>
                                                    </Button>
                                                </Link>
                                                <br></br>
                                            </>
                                        ) : (
                                            <>
                                                <br></br>
                                                <Form.Label>{t('Defence Product')}</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    placeholder={t('Defence Type')}
                                                    value={defenceproduct}
                                                    onChange={(e) => setDefenceproduct(e.target.value)}>
                                                    <option value={""}></option>
                                                    {defenceproductoptions.map((option, index) => (
                                                        <option key={index} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}                      
                                                </Form.Control>
                                                <br></br>
                                                <Form.Label>{t('Dose Used')}</Form.Label>
                                                <Form.Control
                                                    type="numeric"
                                                    placeholder={t('Dose Used')}
                                                    value={defencedoseused}
                                                    onChange={(e) => setDefencedoseused(e.target.value)}></Form.Control>
                                                <br></br>
                                                <Form.Label>{t('Volume Water Used')}</Form.Label>
                                                <Form.Control
                                                    type="numeric"
                                                    placeholder={t('Volume Water Used')}
                                                    value={defencevolumewaterused}
                                                    onChange={(e) => setDefencevolumewaterused(e.target.value)}></Form.Control>
                                                <br></br>
                                                <Form.Label>{t('Level of infestaction')}</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    placeholder={t('Level of infestaction')}
                                                    value={defencelevelinfenstaction}
                                                    onChange={(e) => setDefencelevelinfenstaction(e.target.value)}>
                                                    <option value={""}></option>
                                                    <option value={"basso"}>
                                                        {t('Low')}
                                                    </option> 
                                                    <option value={"medio"}>
                                                        {t('Medium')}
                                                    </option> 
                                                    <option value={"alto"}>
                                                        {t('High')}
                                                    </option>                     
                                                </Form.Control>
                                                <br></br>
                                                <Form.Label>{t('Authorization Technichian?')}</Form.Label>
                                                <Form.Check
                                                    type={"checkbox"}
                                                    value={defenceauthorizationtechnician}
                                                    onClick={(e) => {
                                                        setDefenceauthorizationtechnician(e.target.checked);
                                                    }}/>
                                                <br></br>
                                                <Form.Label>{t('Respect strip')}</Form.Label>
                                                <Form.Check
                                                    type={"checkbox"}
                                                    value={defencefasciadirispetto}
                                                    onClick={(e) => {
                                                        setDefencefasciadirispetto(e.target.checked);
                                                    }}/>
                                                <br></br>
                                                <Form.Label>{t('Exceeding the intervention threshold')}</Form.Label>
                                                <Form.Check
                                                    type={"checkbox"}
                                                    value={defencesuperamentosogliaintervento}
                                                    onClick={(e) => {
                                                        setDefencesuperamentosogliaintervento(e.target.checked);
                                                    }}/>
                                            </>
                                        )}
                                    </>
                                )}
                                <br></br>
                                <Form.Label>{t('Can be used in organic farming production?')}</Form.Label>
                                <Form.Check
                                    type={"checkbox"}
                                    value={organic}
                                    onClick={(e) => {
                                        setOrganic(e.target.checked);
                                    }}/>
                                <br></br>
                                <br></br>
                                <Form.Label>{t('It is done?')}</Form.Label>
                                <Form.Check
                                    type={"checkbox"}
                                    value={done}
                                    onClick={(e) => {
                                        setDone(e.target.checked);
                                    }}/>
                                <br></br>
                                <br></br>
                                <Form.Label>{t('Crop Phenology')}</Form.Label>
                                <Form.Control
                                    as="select"
                                    placeholder={t('Crop Phenology')}
                                    value={cropphenology}
                                    onChange={(e) => setCropphenology(e.target.value)}>
                                    <option value={""}>

                                    </option>
                                    <option value={"erbacee_botticella"}>
                                        {t('erbacee_botticella')}
                                    </option>
                                    <option value={"erbacee_fioritura"}>
                                        {t('erbacee_fioritura')}
                                    </option>
                                    <option value={"erbacee_invaiatura"}>
                                        {t('erbacee_invaiatura')}
                                    </option>
                                    <option value={"erbacee_allegagione"}>
                                        {t('erbacee_allegagione')}
                                    </option>        
                                    <option value={"erbacee_ingrossamento"}>
                                        {t('erbacee_ingrossamento')}
                                    </option>        
                                    <option value={"erbacee_germinazione"}>
                                        {t('erbacee_germinazione')}
                                    </option>        
                                    <option value={"erbacee_emergenza_infioriscenza"}>
                                        {t('erbacee_emergenza_infioriscenza')}
                                    </option>        
                                    <option value={"erbacee_sviluppo_fogliare"}>
                                        {t('erbacee_sviluppo_fogliare')}
                                    </option>        
                                    <option value={"erbacee_maturazione"}>
                                        {t('erbacee_maturazione')}
                                    </option>        
                                    <option value={"erbacee_senescenza"}>
                                        {t('erbacee_senescenza')}
                                    </option>        
                                    <option value={"erbacee_levata"}>
                                        {t('erbacee_levata')}
                                    </option>        
                                    <option value={"erbacee_accestimento"}>
                                        {t('erbacee_accestimento')}
                                    </option>        
                                    <option value={"arboree_gemme_inverno"}>
                                        {t('arboree_gemme_inverno')}
                                    </option>        
                                    <option value={"arboree_apertura_gemme"}>
                                        {t('arboree_apertura_gemme')}
                                    </option>        
                                    <option value={"arboree_ripresa_vegetativa"}>
                                        {t('arboree_ripresa_vegetativa')}
                                    </option>        
                                    <option value={"arboree_sviluppo_germogli"}>
                                        {t('arboree_sviluppo_germogli')}
                                    </option>        
                                    <option value={"arboree_foglie_distese"}>
                                        {t('arboree_foglie_distese')}
                                    </option>        
                                    <option value={"arboree_grappoli_visibili"}>
                                        {t('arboree_grappoli_visibili')}
                                    </option>        
                                    <option value={"arboree_grappoli_separati"}>
                                        {t('arboree_grappoli_separati')}
                                    </option>        
                                    <option value={"arboree_ingrossamento_frutti"}>
                                        {t('arboree_ingrossamento_frutti')}
                                    </option>        
                                    <option value={"arboree_pre_chiusura_grappolo"}>
                                        {t('arboree_pre_chiusura_grappolo')}
                                    </option>        
                                    <option value={"arboree_chiusura_grappolo"}>
                                        {t('arboree_chiusura_grappolo')}
                                    </option>        
                                    <option value={"arboree_indurimento_nocciolo"}>
                                        {t('arboree_indurimento_nocciolo')}
                                    </option>        
                                    <option value={"arboree_invaitura"}>
                                        {t('arboree_invaitura')}
                                    </option>        
                                    <option value={"arboree_riposo_vegetativo"}>
                                        {t('arboree_riposo_vegetativo')}
                                    </option>        
                                    <option value={"arboree_fioritura"}>
                                        {t('arboree_fioritura')}
                                    </option>        
                                    <option value={"arboree_allegagione"}>
                                        {t('arboree_allegagione')}
                                    </option>        
                                    <option value={"arboree_pianta_ferma"}>
                                        {t('arboree_pianta_ferma')}
                                    </option>        
                                    <option value={"arboree_germogliamento"}>
                                        {t('arboree_germogliamento')}
                                    </option>        
                                    <option value={"arboree_germogli_5_10_cm"}>
                                        {t('arboree_germogli_5_10_cm')}
                                    </option>        
                                    <option value={"arboree_comparsa_boccioli_floreali"}>
                                        {t('arboree_comparsa_boccioli_floreali')}
                                    </option>        
                                    <option value={"arboree_bottone_bianco"}>
                                        {t('arboree_bottone_bianco')}
                                    </option>        
                                    <option value={"arboree_crescita"}>
                                        {t('arboree_crescita')}
                                    </option>        
                                    <option value={"arboree_semi_neri"}>
                                        {t('arboree_semi_neri')}
                                    </option>        
                                    <option value={"arboree_pre_raccolta"}>
                                        {t('arboree_pre_raccolta')}
                                    </option>        
                                    <option value={"arboree_raccolta"}>
                                        {t('arboree_raccolta')}
                                    </option>                    
                                </Form.Control>
                                <br></br>
                                <Form.Label>{t('Hour of work')}</Form.Label>
                                <Form.Control
                                    type="numeric"
                                    placeholder={t('Hour of work')}
                                    value={hourofwork}
                                    onChange={(e) => setHourofwork(e.target.value)}>
                                </Form.Control>
                                <br></br>
                                <Form.Label>{t('Crop')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t('Crop')}
                                    value={crop}
                                    onChange={(e) => setCrop(e.target.value)}>
                                </Form.Control>
                                <br></br>
                                <Form.Label>{t('Variety')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t('Variety')}
                                    value={variety}
                                    onChange={(e) => setVariety(e.target.value)}>
                                </Form.Control>
                                <br></br>                                
                                <Form.Label>{t('Subcontractors?')}</Form.Label>
                                <Form.Check
                                    type={"checkbox"}
                                    value={subcontractors}
                                    onClick={(e) => {
                                        setSubcontractors(e.target.checked);
                                    }}/>
                                <br></br>
                                <br></br>
                                <Form.Label>{t('Operator')}</Form.Label>
                                <Form.Control
                                    as="select"
                                    placeholder={t('Operator')}
                                    value={operator}
                                    onChange={(e) => setOperator(e.target.value)}>
                                    <option value={""}></option>
                                    {operatoroptions.map((option, index) => (
                                        <option key={index} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </Form.Control>
                                <br></br>
                                <Form.Label>{t('Total Cost')}</Form.Label>
                                <Form.Control
                                    type="numeric"
                                    placeholder={t('Total Cost')}
                                    value={cost}
                                    onChange={(e) => setCost(e.target.value)}>
                                </Form.Control>
                                <br></br>
                                <Form.Label>{t('Wheater Class')}</Form.Label>
                                <Form.Control
                                    as="select"
                                    placeholder={t('Wheater Class')}
                                    value={wheater}
                                    onChange={(e) => setWheater(e.target.value)}>
                                    <option value={""}>

                                    </option>
                                    <option value={"sereno"}>
                                        {t('sereno')}
                                    </option>
                                    <option value={"nuvoloso"}>
                                        {t('nuvoloso')}
                                    </option>
                                    <option value={"molto_nuvoloso"}>
                                        {t('molto_nuvoloso')}
                                    </option>        
                                    <option value={"coperto"}>
                                        {t('coperto')}
                                    </option>        
                                    <option value={"nebbia"}>
                                        {t('nebbia')}
                                    </option>        
                                    <option value={"temporale"}>
                                        {t('temporale')}
                                    </option>          
                                    <option value={"coperto_con_neve"}>
                                        {t('coperto_con_neve')}
                                    </option>                               
                                </Form.Control>
                                <br></br>
                                <Form.Label>{t('Temperature °C')}</Form.Label>
                                <Form.Control
                                    type="numeric"
                                    placeholder={t('Temperature °C')}
                                    value={temperature}
                                    onChange={(e) => setTemperature(e.target.value)}></Form.Control>
                                <br></br>
                                <Form.Label>{t('Wind Speed km / h')}</Form.Label>
                                <Form.Control
                                    type="numeric"
                                    placeholder={t('Wind Speed km / h')}
                                    value={wind}
                                    onChange={(e) => setWind(e.target.value)}></Form.Control>
                                <br></br>
                                <Form.Label>{t('Humidity %')}</Form.Label>
                                <Form.Control
                                    type="numeric"
                                    placeholder={t('Humidity %')}
                                    value={humidity}
                                    onChange={(e) => setHumidity(e.target.value)}></Form.Control>
                                <br></br>
                                <Form.Label>{t('Rainfall mm')}</Form.Label>
                                <Form.Control
                                    type="numeric"
                                    placeholder={t('Rainfall mm')}
                                    value={rainfall}
                                    onChange={(e) => setRainfall(e.target.value)}></Form.Control>
                                {error &&
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                                        <Alert color="danger" fade={false}>{message}</Alert>
                                    </Col>
                                }
                            </CardBody>
                            <CardFooter>
                                <Row>
                                    {loadingsubmit ? (
                                        <>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-2">
                                                <Button color="success" onClick={submit}>
                                                    {t('Create Field Activity')} <i className="fas fa-plus"></i>
                                                </Button>
                                            </Col>
                                        </>
                                    ) : (
                                        <>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-2">
                                                <Button color="success" disabled>
                                                    <Spinner size="sm">
                                                    {t('Creating the Field Activity...')}
                                                    </Spinner>
                                                    <span>
                                                    {' '}{t('Creating the Field Activity...')}
                                                    </span>
                                                </Button>
                                            </Col>
                                        </>
                                    )}
                                    <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-2">
                                        <Link to="/field-activity/">
                                            <Button color="dark">
                                                {t('Go back')} <i className="fas fa-rotate-left"></i>
                                            </Button>
                                        </Link>
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default CreateFieldActivites
