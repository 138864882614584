import React, { useEffect, useState } from 'react';
import Header from 'components/Headers/Header';
import { Row, Col, Container, Spinner, Button, Card, CardBody, CardHeader, CardFooter, Alert } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { MapContainer, TileLayer, LayersControl, FeatureGroup, GeoJSON } from 'react-leaflet';

function CreateYield() {

  const [loading, setLoading] = useState(true);
  const [loadingsubmit, setLoadingsubmit] = useState(true)
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const [farm, setFarm] = useState('')
  const [farmoptions, setFarmoptions] = useState([])

  const [field, setField] = useState('')
  const [fieldoptions, setFieldoptions] = useState([])

  const [fieldData, setfieldData] = useState({
    Field: '',
    Centroid: '',
    Crop: '',
    CropRotation: '',
    OrganicVsConventional: ''
  });

  const [mapCenter, setMapCenter] = useState([45.1657, 10.4515]);
  const [mapZoom, setMapZoom] = useState(4);

  const [notedate, setNotedate] = useState('')

  const [crop, setCrop] = useState('')

  const [totalyield, setTotalyield] = useState('')

  const [price, setPrice] = useState('')

  const [subcontractors, setSubcontractors] = useState(false)
  const [subcontractorname, setSubcontractorname] = useState('')

  const [operator, setOperator] = useState('')
  const [operatoroptions, setOperatoroptions] = useState([])

  const [cost, setCost] = useState('')

  const token = useSelector((state) => state.userLogin.userInfo.token);
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  useEffect(() => {
      const storedLanguage = localStorage.getItem('selectedLanguageAFS');
      if (storedLanguage) {
          i18n.changeLanguage(storedLanguage);
      }
  }, [i18n]);

  useEffect(() => {

    const fetchData = async () => {

      try {

        const config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        };

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/farmavailable/`, config);

        const extractedNames = response.data.map(item => item.NameFarmer);

        setFarmoptions(extractedNames);
        
        setLoading(false);
          
      } catch (error) {

        console.error('Error during GET request:', error);

      }
    };

    fetchData();
  }, [token]);

  useEffect(() => {

    const fetchData = async () => {

      try {

        const config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        };

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/field-by-farmer/${farm}`, config);
        
        setFieldoptions(response.data);
        
      } catch (error) {

        console.error('Error during GET request:', error);

      }
    };

    fetchData();
  }, [token, farm]);

  useEffect(() => {

    const fetchData = async () => {

      try {

        const config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        }
    
        const responsefield = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/field-selected/${field}`, config);
    
        setCrop(responsefield.data.properties.ActualCrop)

        setfieldData({
          Field: responsefield.data,
          Centroid: responsefield.data.properties.centroid,
          Crop: responsefield.data.properties.ActualCrop,
          CropRotation: responsefield.data.properties.CropRotation,
          OrganicVSConventional: responsefield.data.properties.OrganicVSConventional
        });

        setMapCenter([responsefield.data.properties.centroid[1], responsefield.data.properties.centroid[0]]);

        setMapZoom(15);
        
      } catch (error) {

        console.error('Error during GET request:', error);

      }

    };

    fetchData();
  }, [token, field]);

  useEffect(() => {

    const fetchData = async () => {
        
      try {

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        };

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/company-management/operator/`, config);

        setOperatoroptions(response.data)
          
      } catch (error) {
          console.error('Error during GET request:', error);
      }
    };

    fetchData();
  }, [token]);

  const getFarmerId = async (farm) => {
    try {
        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`
            }
          }   
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/farm/${farm}`, config);
        return response.data;
    } catch (error) {
        console.error("Error fetching farmer ID:", error);
        throw error;
    }
  };

  const getFieldId = async (field) => {

    try {

      const config = {

        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${token}`
        }
        
      }

      const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/field-selected/${field}`, config);
      
      return response.data.id;

    } catch (error) {
      console.error("Error fetching farmer ID:", error);
      throw error;
    }
  };

  const getOperatorId = async (operator) => {

    try {

      const config = {

        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${token}`
        }
        
      }

      const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/company-management/operator/operatorname/${operator}`, config);
      
      return response.data.id;

    } catch (error) {
      console.error("Error fetching farmer ID:", error);
      throw error;
    }
  };

  const submit = async () => {

    setLoadingsubmit(false);
       
    if (farm==='') {
      setMessage(t('Please select the farm'))
      setError(true)
      setTimeout(function() {
        setError(false)
      }, 5000);
      setLoadingsubmit(true);
      return;
    }

    if (field==='') {
      setMessage(t('Please select the field'))
      setError(true)
      setTimeout(function() {
        setError(false)
      }, 5000);
      setLoadingsubmit(true);
      return;
    }

    if (operator==='') {
      setMessage(t('Please select the operator'))
      setError(true)
      setTimeout(function() {
        setError(false)
      }, 5000);
      setLoadingsubmit(true);
      return;
    }

    if (notedate==='') {
      setMessage(t('Please set the harvest date'))
      setError(true)
      setTimeout(function() {
        setError(false)
      }, 5000);
      setLoadingsubmit(true);
      return;
    }

    if (totalyield==='') {
      setMessage(t('Please set the total yield'))
      setError(true)
      setTimeout(function() {
        setError(false)
      }, 5000);
      setLoadingsubmit(true);
      return;
    }

    if (price==='') {
      setMessage(t('Please set the price of the yield'))
      setError(true)
      setTimeout(function() {
        setError(false)
      }, 5000);
      setLoadingsubmit(true);
      return;
    }

    if (cost==='') {
      setMessage(t('Please set the cost of the operation'))
      setError(true)
      setTimeout(function() {
        setError(false)
      }, 5000);
      setLoadingsubmit(true);
      return;
    }

    if (!price) {
      setMessage(t('Please set the price of the yield'));
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 5000);
      setLoadingsubmit(true);
      return;
    }
    
    try {
        
        const farmerId = await getFarmerId(farm)    
        const fieldid = await getFieldId(field)
        const operatorID = await getOperatorId(operator)
      
        const payload = {
          Farm: farmerId,
          Field: fieldid,
          DateYield: notedate,
          Crop: crop,
          TotalQuantity: parseFloat(totalyield),
          Price: parseFloat(price),
          Terzisti: subcontractors,
          TerzistiName: subcontractorname,
          Operator: operatorID,
          TotalCost: parseFloat(cost)
        };
    
        const config = {
          headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${token}`
          }
        }   
            
        const response= await axios.post(`${process.env.REACT_APP_BACKEND_API}/company-management/create-yield/`, payload, config)

    } catch (error) {
      console.log(error)
      setMessage(t('An error occured while creating the field. If the problem persiste please send us an email automaticfarmsolution@gmail.com'))
      setError(true)
      setTimeout(function() {
          setError(false)
      }, 5000);
      setLoadingsubmit(true);
      return;

    } finally {
      setLoadingsubmit(true);
      navigate('/yield/');
    }
    
  };
  
  return (
    <div>
      <Header />
      {loading ? (
        <Container className="mt--15" fluid>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
              <Spinner>{t('Loading')}</Spinner>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container className="mt--15" fluid>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
              <Card>
                <CardHeader>
                  <h3>{t('Register a Yield')}</h3>
                </CardHeader>
                <CardBody>
                  <Form.Label>{t('Farmer Company Name:')}</Form.Label>
                  <Form.Control
                    as="select"
                    placeholder={t('Farmer Company Name:')}
                    value={farm}
                    onChange={(e) => setFarm(e.target.value)}>
                    <option value={""}></option>
                    {farmoptions.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </Form.Control>
                  <br></br>
                  <Form.Label>{t('Field')}</Form.Label>
                  <Form.Control
                    as="select"
                    placeholder={t('Field')}
                    value={field}
                    onChange={(e) => setField(e.target.value)}>
                    <option value={""}></option>
                    {fieldoptions.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </Form.Control>
                  <br></br>
                  <MapContainer key={JSON.stringify(fieldData)} center={mapCenter} zoom={mapZoom} style={{ height: '500px', width: '100%', margin: 0, padding: 0 }}>
                      <LayersControl position="topright">
                        <LayersControl.BaseLayer checked name="OpenStreetMap">
                          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' />
                        </LayersControl.BaseLayer>
                        <LayersControl.BaseLayer checked name="Satellite">
                          <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" attribution='&copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community' />
                        </LayersControl.BaseLayer>
                      </LayersControl>
                      {fieldData && fieldData.Field && fieldData.Field.geometry ? (
                        <FeatureGroup>
                            <GeoJSON      
                                data={{
                                    type: 'Feature',
                                    geometry: {
                                        type: 'Polygon',
                                        coordinates: fieldData.Field.geometry.coordinates,
                                    },
                                    properties: fieldData.Field.properties,
                                }}
                                style={{ fillColor: 'blue', weight: 1, opacity: 1, color: 'blue', fillOpacity: 0.15 }}
                            />
                        </FeatureGroup>
                      ) : null}
                    </MapContainer>
                  <br></br>
                  <Form.Label>{t('Harvest Date')}</Form.Label>
                  <Form.Control
                      type="date"
                      placeholder={t('Harvest Date')}
                      value={notedate}
                      onChange={(e) => setNotedate(e.target.value)}>
                  </Form.Control>
                  <br></br>
                  {crop ? (
                    <>
                      <h3>{t('The crop is: ')} {t(`translation:${crop}`)}</h3>
                    </>
                  ):null}  
                  <br></br>
                  <Form.Label>{t('Total Yield quintals')}</Form.Label>
                  <Form.Control
                      type="numeric"
                      placeholder={t('Total Yield quintals')}
                      value={totalyield}
                      onChange={(e) => setTotalyield(e.target.value)}>
                  </Form.Control>
                  <br></br>
                  <Form.Label>{t('Price selled euro per quintal')}</Form.Label>
                  <Form.Control
                      type="numeric"
                      placeholder={t('Price selled euro per quintal')}
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}>
                  </Form.Control>
                  <br></br>   
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id="customCheckRegister1"
                      type="checkbox"
                      onChange={(e) => setSubcontractors(e.target.checked)} 
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheckRegister1"
                    >
                      <span className="text-muted">
                        {t('Did you use a subcontractors?')}
                      </span>
                    </label>
                  </div>
                  {subcontractors && (
                    <>
                      <br></br>
                      <Form.Label>{t('Subcontractor Name')}</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t('Subcontractor Name')}
                        value={subcontractorname}
                        onChange={(e) => setSubcontractorname(e.target.value)}>
                      </Form.Control>
                    </>
                  )}
                  <br></br>
                  <Form.Label>{t('Operator')}</Form.Label>
                  <Form.Control
                    as="select"
                    placeholder={t('Operator')}
                    value={operator}
                    onChange={(e) => setOperator(e.target.value)}>
                    <option value={""}></option>
                    {operatoroptions.map((option, index) => (
                      <option key={index} value={option.Name}>
                        {option.Name}
                      </option>
                    ))}
                  </Form.Control>
                  <br></br>
                  <Form.Label>{t('Total cost of the harvest operation')}</Form.Label>
                  <Form.Control
                    type="numeric"
                    placeholder={t('Total cost of the harvest operation')}
                    value={cost}
                    onChange={(e) => setCost(e.target.value)}>
                  </Form.Control>
                  {error &&
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                        <Alert color="danger" fade={false}>{message}</Alert>
                    </Col>
                  }
                </CardBody>
                <CardFooter>
                  <Row>
                    {loadingsubmit ? (
                      <>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-2">
                          <Button color="success" onClick={submit}>
                            {t('Create Yield')} <i className="fas fa-plus"></i>
                          </Button>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-2">
                          <Button color="success" disabled>
                            <Spinner size="sm">
                              {t('Creating the Yield...')}
                            </Spinner>
                            <span>
                              {' '}{t('Creating the Yield...')}
                            </span>
                          </Button>
                        </Col>
                      </>
                    )}
                    <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="text-center mt-2">
                        <Link to="/yield/">
                            <Button color="dark">
                                {t('Go back')} <i className="fas fa-rotate-left"></i>
                            </Button>
                        </Link>
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  )
}

export default CreateYield
