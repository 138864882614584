import { useState, useEffect } from "react";
import { Form} from 'react-bootstrap';
import {Button, Progress,  Container, Row, Col, Alert, Card, CardBody, CardHeader, Spinner, CardTitle} from 'reactstrap'
import axios from 'axios'
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const MultiStepForm = () => {

  const [error, seterror] = useState('')
  const [message, setmessage] = useState('')

  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});

  const [fieldoption, setfieldoption] = useState([])
  const [varietyoption, setvarietyoption] = useState()

  const [loading, setloading] = useState(true)

  const [html, sethtml] = useState('')
  const [max, setMax] = useState('')

  const token = useSelector((state) => state.userLogin.userInfo.token);

  ////////////
  const { t, i18n } = useTranslation();

  useEffect(() => {
      const storedLanguage = localStorage.getItem('selectedLanguageAFS');
      if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
      }
  }, [i18n]);
  ////////////

  useEffect(() => {
    
  const fetchData = async () => {
      try {

          const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`
            }
          }
          
          const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/field/fieldname/`, config);
          
          setfieldoption(response.data);

      } catch (error) {
          console.log(error)
      }
    };

    fetchData(); 
  }, [token]);

  const handleNext = (event) => {
    event.preventDefault(); // Add this line to prevent default form submission behavior
    setStep(step + 1);
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };

  // const handleInputChange = async (event) => {
  //   event.preventDefault();
  //   const { name, value } = event.target;

  //   if (value==='') {
  //       setmessage(t('Please fill all the requests'))
  //       seterror(true)
  //       setTimeout(function() {
  //           seterror(false)
  //       }, 5000);
  //       return;
  //   } 

  //   setFormData({ ...formData, [name]: value });

  //   try {
  //       const config = {
  //           headers: {
  //               'Content-type': 'application/json',
  //               Authorization: `Bearer ${token}`
  //           }
  //           }
    
  //       const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/crop-model/get-cultivars/${formData.crop}`, config);
       
  //       setvarietyoption(response.data.data);        

  //   } catch(error) {
  //       console.log(error)
  //   }

  // };

  const handleInputChange = async (event) => {
    event.preventDefault();
    const { name, value } = event.target;
  
    if (value === '') {
      setmessage(t('Please fill all the requests'))
      seterror(true)
      setTimeout(function () {
        seterror(false)
      }, 5000);
      return;
    }
  
    // Aggiorna formData con il nuovo valore
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  
    try {
      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
  
      if (name === 'crop') {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/crop-model/get-cultivars/${value}`, config);
        setvarietyoption(response.data.data);
      } else if (name !== 'variety') {
        setvarietyoption(null);
      }
        
    } catch (error) {
      console.log(error)
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const payload = formData

    setloading(false)

    try {

      const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
        }
      }

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_API}/crop-model/run-simulation/`, 
                                        payload, 
                                        config);

      sethtml(response.data.data)

      const responsemax = await axios.post(`${process.env.REACT_APP_BACKEND_API}/crop-model/max-value/`, 
                                        payload, 
                                        config);
      setMax(responsemax.data.data)
      console.log(responsemax.data)

    } catch(error) {
      console.log(error)
    } finally {
      setloading(true)
    }

  };

  return (
    <>
      <Container className="mt--15" fluid>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
            <Card className="shadow">
              <CardHeader>
                <h3>{t('Set the analysis parameter')}</h3>
              </CardHeader>
              <CardBody>
                <Form>
                  <Progress value={(step / 6) * 100} />
                  {step === 1 && (
                    <Form.Group controlId="formStep1">
                        <Form.Label>{t('Select the field to analyze')}</Form.Label>
                        <Form.Control
                            as="select"
                            name="fieldselected"
                            value={formData.fieldselected}
                            onChange={handleInputChange}
                            >
                            <option value={""}> 

                            </option>
                            {fieldoption.map((option, index) => (
                                <option key={index} value={option}>
                                    {option}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                  )}
                  {step === 2 && (
                    <Form.Group controlId="formStep2">
                        <Form.Label>{t('Date of sowing')}</Form.Label>
                        <Form.Control
                            type="date"
                            name="datesowing"
                            value={formData.datesowing}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                  )}
                  {step === 3 && (
                    <Form.Group controlId="formStep3">
                      <Form.Label>{t('Select the soil type')}</Form.Label>
                        <Form.Control
                          as="select"
                          rows={3}
                          name="soil"
                          value={formData.soil}
                          onChange={handleInputChange}
                          >
                          <option value=""></option>
                          <option value="S">{t('Sand')}</option>
                          <option value="LS">{t('Loamy Sand')}</option>
                          <option value="SL">{t('Sandy Loam')}</option>
                          <option value="L">{t('Loam')}</option>
                          <option value="SIL">{t('Silty Loam')}</option>
                          <option value="SI">{t('Silt')}</option>
                          <option value="SCL">{t('Sandy Clay Loam')}</option>
                          <option value="CL">{t('Clay Loam')}</option>
                          <option value="SICL">{t('Silty Clay Loam')}</option>
                          <option value="SC">{t('Sandy Clay')}</option>
                          <option value="C">{t('Clay')}</option>
                        </Form.Control>
                    </Form.Group>
                  )}
                  {step === 4 && (
                    <Form.Group controlId="formStep4">
                      <Form.Label>{t('Select the Crop')}</Form.Label>
                      <Form.Control
                        as="select"
                        rows={3}
                        name="crop"
                        value={formData.crop}
                        onChange={handleInputChange}>
                        <option value=""></option>
                        <option value="Maize">{t('Maize')}</option>
                        <option value="Sunflower">{t('Sunflower')}</option>
                        <option value="Millet">{t('Millet')}</option>
                        <option value="Rice">{t('Rice')}</option>
                        <option value="Sugarbeet">{t('Sugarbeet')}</option>
                        <option value="Sorghum">{t('Sorghum')}</option>
                        <option value="Alfalfa">{t('Alfalfa')}</option>
                        <option value="Soybean">{t('Soybean')}</option>
                        <option value="Canola">{t('Canola')}</option>
                        <option value="Tomato">{t('Tomato')}</option>
                        <option value="Cabbage">{t('Cabbage')}</option>
                        <option value="Potato">{t('Potato')}</option>
                        <option value="Sugarcane">{t('Sugarcane')}</option>
                      </Form.Control>
                      {varietyoption ? (
                        <>
                          <Form.Label>{t('Select the Variety')}</Form.Label>
                          <Form.Control
                              as="select"
                              name="variety"
                              value={formData.variety}
                              onChange={handleInputChange}>                    
                              <option value=""></option>
                              {varietyoption.map((option, index) => (
                                  <option key={index} value={option}>
                                      {option}
                                  </option>
                              ))}
                          </Form.Control>
                        </>
                      ):null}
                    </Form.Group>
                  )}
                  {step === 5 && (
                    <Form.Group controlId="formStep5">
                      <Form.Label>{t('Do you want to provide a fertilization?')}</Form.Label>
                      <Form.Control
                          as="select"
                          name="fertilizationyesnot"
                          value={formData.fertilizationyesnot}
                          onChange={handleInputChange}>
                          <option value=""></option>
                          <option value="No">{t('No')}</option>
                          <option value="Yes">{t('Yes')}</option>
                      </Form.Control>
                      <br></br>
                      {formData.fertilizationyesnot==="Yes" ? (
                        <>
                          <Form.Label>{t('Date of fertilization')}</Form.Label>
                          <Form.Control
                              type="date"
                              name="fertilizationdate"
                              value={formData.fertilizationdate}
                              onChange={handleInputChange}/>
                          <br></br>
                          <Form.Label>{t('Define the amount of Nitrogen provided (kg N / ha)')}</Form.Label>
                          <Form.Control
                              type="numeric"
                              name="fertilizationN"
                              value={formData.fertilizationN}
                              onChange={handleInputChange}>
                          </Form.Control>
                          <br></br>
                          <Form.Label>{t('Define the amount of Phosphorus provided (kg P / ha)')}</Form.Label>
                          <Form.Control
                              type="numeric"
                              name="fertilizationP"
                              value={formData.fertilizationP}
                              onChange={handleInputChange}>
                          </Form.Control>
                          <br></br>
                          <Form.Label>{t('Define the amount of Potassium provided (kg K / ha)')}</Form.Label>
                          <Form.Control
                              type="numeric"
                              name="fertilizationK"
                              value={formData.fertilizationK}
                              onChange={handleInputChange}>
                          </Form.Control>
                        </>
                      ) : null}
                    </Form.Group>
                  )}
                  {step === 6 && (
                    <Form.Group controlId="formStep6">
                        <Form.Label>{t('Do you want to provide a irrigation')}</Form.Label>
                        <Form.Control
                            as="select"
                            name="irrigationyesnot"
                            value={formData.irrigationyesnot}
                            onChange={handleInputChange}>
                            <option value=""></option>
                            <option value="No">{t('No')}</option>
                            <option value="Yes">{t('Yes')}</option>
                        </Form.Control>
                        <br></br>
                        {formData.irrigationyesnot==="Yes" ? (
                            <>
                                <Form.Label>{t('Date of irrigation')}</Form.Label>
                                <Form.Control
                                    type="date"
                                    name="irrigationdate"
                                    value={formData.irrigationdate}
                                    onChange={handleInputChange}/>
                                <br></br>
                                <Form.Label>{t('Define the amount of water mm')}</Form.Label>
                                <Form.Control
                                    type="numeric"
                                    name="irrigationamount"
                                    value={formData.irrigationamount}
                                    onChange={handleInputChange}>
                                </Form.Control>
                            </>
                        ) : null}
                    </Form.Group>
                  )}
                  <br></br>
                  <div className="d-flex justify-content-between">
                    {step > 1 && (
                      <Button color="primary" onClick={handlePrevious}>
                        {t('Go back')}
                      </Button>
                    )}
                    {step < 6 ? (
                      <Button color="success" onClick={handleNext}>
                        {t('Next')}
                      </Button>
                    ) : (
                      loading ? (
                          <Button color="success" type="submit" onClick={handleSubmit}>
                            {t('Submit')}
                          </Button>
                      ) : (
                        <>
                          <Button color="success" disabled>
                            <Spinner size="sm">
                                Loading...
                            </Spinner>
                            <span>
                            {' '}Loading
                            </span>
                          </Button>
                        </>
                      )
                    )}
                            
                  </div>
                </Form>
                {error && <Alert color="danger" fade={false}>{message}</Alert>}
              </CardBody>
            </Card>
          </Col>
          <br></br>
          <br></br>
          <br></br>
          {html ? (
            <>
              <Col xs={12} sm={12} md={9} lg={9} xl={9} xxl={9} className="text-center mt-5">
                <br></br>
                <Card>
                  <CardHeader>
                    <h3>{t('Temporal creation of the yield')}</h3>
                  </CardHeader>
                  <CardBody>
                    <iframe id="iframe2ascrv" width="100%" height="500" srcDoc={html}></iframe>
                  </CardBody>
                </Card>
              </Col>
            </>
          ):null}
          {max ? (
            <>
              <Col xs={12} sm={12} md={3} lg={3} xl={3} xxl={3} className="text-center mt-5">
                <br></br>
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          {t('Maximum Yield Reachable')}
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">                        
                          {max && (
                              <>
                                {max} {"kg / ha"}
                              </>
                            )}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i className="fa fa-map" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </>
          ):null}
        </Row>
      </Container>
    </>
  );
};

export default MultiStepForm;