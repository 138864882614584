import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import Header from 'components/Headers/Header';
import { useSelector } from 'react-redux'; 
import axios from 'axios'
import {Spinner, Container, Row, Col, Button, Card, CardHeader, CardBody} from 'reactstrap'
import { MapContainer, TileLayer, Marker, LayersControl, Popup } from 'react-leaflet';
import {Form} from 'react-bootstrap'

function IoTAnalysis() {

    const [loadingiot, setloadingiot] = useState(true)

    const [loadingsubmit, setloadingsubmit] = useState(true)

    const [variable, setvariable] = useState('Temperatura')

    const [selectediot, setselectediot] = useState('');
    const [optionsiot, setoptionsiot] = useState([]);

    const [iotposition, setIotposition] = useState([])
    const [realhistoric, setRealhistoric] = useState('');

    const [htmlContent, setHtmlContent] = useState('');

    const token = useSelector((state) => state.userLogin.userInfo.token);

    useEffect(() => {
      
        const fetchData = async () => {
          try {
    
            const config = {
              headers: {
                  'Content-type': 'application/json',
                  Authorization: `Bearer ${token}`
              }
            }
                
            const responseposition = await axios.get(`${process.env.REACT_APP_BACKEND_API}/iot/positioniot/`, config);
            
            setoptionsiot(responseposition.data);
            setloadingiot(false)

          } catch (error) {
            // console.error('Errore durante la richiesta GET:', error);
            // setMessage(t('Something went wrong. If the problem persist please send us an email at automaticfarmsolution@gmail.com'))
            // setError(true)
            // setTimeout(function() {
            //   setError(false)
            // }, 5000);
            // return;
          }
        };
    
        fetchData();
    }, [token]);

    useEffect(() => {
      
        const fetchData = async () => {
          try {
    
            const config = {
              headers: {
                  'Content-type': 'application/json',
                  Authorization: `Bearer ${token}`
              }
            }
                
            const responseposition = await axios.get(`${process.env.REACT_APP_BACKEND_API}/iot/positioniot/${selectediot}`, config);
            console.log(responseposition)
            setIotposition(responseposition.data);

          } catch (error) {
            // console.error('Errore durante la richiesta GET:', error);
            // setMessage(t('Something went wrong. If the problem persist please send us an email at automaticfarmsolution@gmail.com'))
            // setError(true)
            // setTimeout(function() {
            //   setError(false)
            // }, 5000);
            // return;
          }
        };
    
        fetchData();
    }, [token, selectediot]);

    ////////////
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const storedLanguage = localStorage.getItem('selectedLanguageAFS');
        if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
        }
    }, [i18n]);
    ////////////

    const submit = async () => {

        setloadingsubmit(false);

        try {            
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }
            
            if (realhistoric==="historic") {

                if (selectediot==='') {
                
                    const response= await axios.get(`${process.env.REACT_APP_BACKEND_API}/iot/iotviewdata/${optionsiot[0].Cabina}/${variable}/`, config);
                    setHtmlContent(response.data);

                } else {
                    
                    const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/iot/iotviewdata/${selectediot}/${variable}/`, config);
                    setHtmlContent(response.data);

                }

            } else {
                
                if (selectediot==='') {
                
                    const response= await axios.get(`${process.env.REACT_APP_BACKEND_API}/iot/iotviewsdata-last-24-hour/${optionsiot[0].Cabina}/${variable}/`, config);
                    setHtmlContent(response.data);

                } else {
                    
                    const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/iot/iotviewsdata-last-24-hour/${selectediot}/${variable}/`, config);
                    setHtmlContent(response.data);

                }

            }

          
        } catch (error) {  
            // console.error('Errore durante la richiesta GET:', error);
            // setMessage(t('Something went wrong. If the problem persist please send us an email at automaticfarmsolution@gmail.com'))
            // setError(true)
            // setTimeout(function() {
            //   setError(false)
            // }, 5000);
            // return;
        } finally {
            setloadingsubmit(true);
        }

       
    };

    return (
        <div>
            <Header/>
            {loadingiot ? (
                <Container className="mt--15" fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                            <Spinner>{t('Loading')}</Spinner>
                        </Col>
                    </Row>
                </Container>
                ) : (
                <>
                    <Container className="mt--15" fluid>
                        <Row>
                            <Col xs={12} sm={12} md={3} lg={3} xl={3} xxl={3} className="text-center mt-5">
                                <Card>
                                    <CardHeader>
                                        <h3>{t('Analysis')}</h3>
                                    </CardHeader>
                                    <CardBody>
                                        <Form>
                                            <Form.Label>{t('Select the IoT Cabin')}</Form.Label>
                                            <Form.Control 
                                                as="select" 
                                                onChange={(e) => setselectediot(e.target.value)}
                                                value={selectediot}>
                                                    {optionsiot.map((option, index) => (
                                                        <option key={index} value={option.Cabina}>
                                                            {option.Cabina}
                                                        </option>
                                                    ))}
                                            </Form.Control>
                                            <br></br>
                                            {iotposition && iotposition.length>0 ? (
                                                <MapContainer center={[iotposition[0].lat, iotposition[0].longi]} zoom={17} style={{ height: '200px', width: '100%' }}>
                                                    <LayersControl position="topright">
                                                        <LayersControl.BaseLayer checked name="OpenStreetMap">
                                                            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                                                        </LayersControl.BaseLayer>
                                                        <LayersControl.BaseLayer checked name="Satellite">
                                                            <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"/>        
                                                        </LayersControl.BaseLayer>
                                                    </LayersControl>
                                                    <Marker position={[iotposition[0].lat, iotposition[0].longi]}>
                                                        <Popup>
                                                            <h3>{t('IoT Number')} {iotposition[0].Cabina}</h3>
                                                        </Popup>
                                                    </Marker>
                                                </MapContainer>
                                            ):null}
                                            <br></br>
                                            <Form.Label>{t('Real time data or Historic data?')}</Form.Label>
                                            <Form.Control 
                                                as="select" 
                                                onChange={(e) => setRealhistoric(e.target.value)}
                                                value={realhistoric}>
                                                <option value={"realtime"}>
                                                    {t('Last 24 Hours Data')}
                                                </option>
                                                <option value={"historic"}>
                                                    {t('Historic Data')}
                                                </option>
                                            </Form.Control>
                                            <br></br>
                                            <Form.Label>{t('Select the variable')}</Form.Label>
                                            <Form.Control 
                                                as="select" 
                                                onChange={(e) => setvariable(e.target.value)}
                                                value={variable}>
                                                    <option value={'Temperatura'}>
                                                        {t('Air Temperature')} (°C)
                                                    </option>
                                                    <option value={'Umidità_aria'}>
                                                        {t('Air Humidity')} (%)                                      
                                                    </option>
                                                    <option value={'Precipitazione'}>
                                                        {t('Rainfall')} (mm)                      
                                                    </option>
                                                    <option value={'Pressione_atmosferica'}>
                                                        {t('Atmospheric Pressure')} (hPa)                                    
                                                    </option>
                                                    <option value={'Vento'}>
                                                        {t('Wind')} (km/h)                                        
                                                    </option>
                                                    <option value={'Raffica'}>
                                                        {t('Gust')} (km/h)                                      
                                                    </option>
                                                    <option value={'Gradi_Giorno'}>
                                                        {t('Degree Days')}                                  
                                                    </option>
                                                    <option value={'TWH_Index'}>
                                                        {t('TWH Index')}                                      
                                                    </option>
                                                    <option value={'Punto_rugiada'}>
                                                        {t('Dew Point')} (°C Td)                                      
                                                    </option>
                                                    <option value={'Evapotraspirazione'}>
                                                        {t('Evapotraspiration')} (mm)                                      
                                                    </option>
                                                    <option value={'Bagnatura_Fogliare'}>
                                                        {t('Leaf Wetness')} (h)                                      
                                                    </option>
                                                    <option value={'Umidità_Suolo'}>
                                                        {t('Soil Humidity')} (%)                                      
                                                    </option>
                                            </Form.Control>
                                            <br></br>
                                            {loadingsubmit ? (
                                                <>
                                                    <Button color='success' onClick={submit}>{t('View data')}</Button>
                                                </>
                                            ):(
                                                <>
                                                    <Button color="success" disabled>
                                                        <Spinner size="sm">
                                                            Loading...
                                                        </Spinner>
                                                        <span>
                                                        {' '}Loading
                                                        </span>
                                                    </Button>

                                                </>
                                            )}
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xs={12} sm={12} md={9} lg={9} xl={9} xxl={9} className="text-center mt-5">
                                {htmlContent && (
                                    <>
                                        <Card>
                                            <CardHeader>
                                                <h3>{t('Time series Graph')}</h3>
                                            </CardHeader>
                                            <CardBody>
                                                <iframe id="iframe11" width="100%" height="500" srcDoc={htmlContent}></iframe>
                                            </CardBody>
                                        </Card>    
                                    </>
                                )}                            
                            </Col>                            
                        </Row>
                    </Container>
                </>
            )}
        </div>
    )
}

export default IoTAnalysis
