import Header from 'components/Headers/Header';
import {Row, Col, Container,Card, CardBody, CardHeader, CardFooter, Button} from 'reactstrap'

import React, {useEffect} from 'react'
import {Form} from 'react-bootstrap'
import {Link, useNavigate} from 'react-router-dom'

import { useTranslation } from 'react-i18next';

function PaymentProblem() {

    ////////////
    const { t, i18n } = useTranslation();  // Destructure i18n from useTranslation

    useEffect(() => {
        const storedLanguage = localStorage.getItem('selectedLanguageAFS');
        if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);  // Use i18n to change language
        }
    }, [i18n]);
    ////////////

    return (
        <div>
            <Header/>
            <Container className="mt--15" fluid>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                        <Card>
                            <CardHeader>
                                <h3>{t('Something Went Wrong During the Payment')}</h3>
                            </CardHeader>
                            <CardBody>
                                <p>{t('Something Went Wrong During the Payment')}</p>
                                <p>{t('Please send us an email to info@automaticfarmsolution.com')}</p>
                                <p>{t('An operator will answear you as soon as possible')}</p>
                            </CardBody>
                            <CardFooter>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                                    <Link to="/">
                                        <Button color="success">
                                            {t('Go back')} <i className="fas fa-rotate-left"></i>
                                        </Button>
                                    </Link>
                                </Col>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container>        
        </div>
    )
    }

export default PaymentProblem
