import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button, Col, Row, Container, Card, CardHeader, Spinner } from 'reactstrap';

import axios from 'axios';
import Header from 'components/Headers/Header'
import { useSelector } from 'react-redux'

import { useTranslation } from 'react-i18next';

function FarmDelete() {
  const { fieldId } = useParams();

  const navigate = useNavigate();

  const token = useSelector((state) => state.userLogin.userInfo.token);
  
  const [deletenew, setdelete] = useState(true);

  ////////////
  const { t, i18n } = useTranslation();  // Destructure i18n from useTranslation

  useEffect(() => {
      const storedLanguage = localStorage.getItem('selectedLanguageAFS');
      if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);  // Use i18n to change language
      }
  }, [i18n]);
  ////////////

  const [farmerData, setFarmerData] = useState({
    NameFarmer: '',
    Name: '',
    Surname: '',
    Country: '',
    City: '',            
    Adress: '',
    Email: '',
    PhoneNumber: ''
  });

  useEffect(() => {
    const fetchData = async () => {
        try {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }  
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/farm/${fieldId}/`, config);
            
            setFarmerData({
                NameFarmer: response.data.NameFarmer,
                Name: response.data.Name,
                Surname: response.data.Surname,
                Country: response.data.Country,
                City: response.data.City,
                Adress: response.data.Adress,
                Email: response.data.Email,
                PhoneNumber: response.data.PhoneNumber
            });
            
        } catch (error) {
            console.error('Error fetching field data:', error);
        }
    };

    fetchData();
  }, [fieldId, token]);

  const deleteFarm = async () => {
    setdelete(false)
    try {

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }  

        await axios.delete(`${process.env.REACT_APP_BACKEND_API}/farm/delete/${fieldId}/`,config);
        setdelete(true)
        navigate('/farm/');
    } catch (error) {
        console.error(`Error deleting field with ID ${fieldId}:`, error);
    }
    
  };

  return (
    <div>
      <Header />
      <br></br>
      <br></br>
      <Container className="mt--15" fluid>
        <Row>
          <Col xs={12} sm={12} md={9} lg={9} xl={9} xxl={9}>
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">{t('Are you sure to remove the following farmer?')}</h3>
              </CardHeader>
              <ul>
                <li>{t('Farmer company name:')} <strong>{farmerData.NameFarmer}</strong></li>
                <li>{t('Name of the farmer:')} <strong>{farmerData.Name}</strong></li>
                <li>{t('Surname of the farmer:')} <strong>{farmerData.Surname}</strong></li>
                <li>{t('Country of the farmer:')} <strong>{farmerData.Country}</strong></li>
                <li>{t('City of the farmer:')} <strong>{farmerData.City}</strong></li>
                <li>{t('Adress of the farmer:')} <strong>{farmerData.Adress}</strong></li>
                <li>{t('Email of the farmer:')} <strong>{farmerData.Email}</strong></li>
                <li>{t('Phone Number of the farmer:')} <strong>{farmerData.PhoneNumber}</strong></li>
              </ul>
            </Card>
            <br></br>
          </Col>          
          <Col xs={12} sm={12} md={3} lg={3} xl={3} xxl={3} className="d-flex justify-content-center">
            {deletenew ? (
              <>
                <Button color="danger" onClick={deleteFarm}>
                  {t('Delete Farmer')} <i className="fas fa-trash"></i>
                </Button>
              </>
            ):(
              <>
                <Button color="danger" disabled>
                  <Spinner size="sm">
                  {t('Loading...')}
                  </Spinner>
                  <span>
                    {' '}{t('Loading')}
                  </span>
                </Button>
              </>
            )}

            <br></br>
            <Link to="/farm/">
                <Button color="primary">
                  {t('Go back')}<i className="fas fa-rotate-left"></i>
                </Button>
            </Link>                        
          </Col>
        </Row>
      </Container>     
    </div>
  )
}

export default FarmDelete
